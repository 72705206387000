import React, { useState } from 'react';
import { alpha, Box, FormLabel, styled, useTheme } from '@mui/material';
import Image from '@tiptap/extension-image';
import Link from '@tiptap/extension-link';
import Table from '@tiptap/extension-table';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';
import Underline from '@tiptap/extension-underline';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';

import { getRichTextStyling } from 'components/shared/RichTextStyling';
import RichTextToolbar from 'components/shared/RichTextToolbar';

const toolbarHeight = 49;

const StyledWrapper = styled(Box)(({ theme }) => ({
  '& .ProseMirror': {
    '&:hover:not(.ProseMirror-focused)': {
      boxShadow: `0 0 0 0.5px inset ${theme.palette.grey[500]}`,
    },
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    maxHeight: `calc(
      80vh - ${toolbarHeight}px
      - ${theme.spacing(4)})`,
    minHeight: 150,
    overflowY: 'auto',
    padding: `0 ${theme.spacing(2)} ${theme.spacing(2)}`,
    transition: 'box-shadow 150ms ease-in-out',
  },
  '& .ProseMirror-focused': {
    outlineColor: theme.palette.primary.main,
  },
  '.ProseMirror': {
    table: {
      '.column-resize-handle': {
        backgroundColor: theme.palette.info.main,
        bottom: '-2px',
        pointerEvents: 'none',
        position: 'absolute',
        right: '-2px',
        top: '0',
        width: '4px',
      },
      '.selectedCell:after': {
        background: alpha(theme.palette.primary.light, 0.5),
        bottom: '0',
        content: "''",
        left: '0',
        pointerEvents: 'none',
        position: 'absolute',
        right: '0',
        top: '0',
        zIndex: '2',
      },
      borderCollapse: 'collapse',
      margin: '0',
      overflow: 'hidden',
      p: {
        margin: '0',
      },
      tableLayout: 'fixed',
      'td, th': {
        '> *': {
          marginBottom: '0',
        },
        border: '2px solid #ced4da',
        boxSizing: 'border-box',
        minWidth: '1em',
        padding: '3px 5px',
        position: 'relative',
        verticalAlign: 'top',
      },
      th: {
        backgroundColor: '#f1f3f5',
        fontWeight: 'bold',
        textAlign: 'left',
      },
      width: '100%',
    },
  },
  '.resize-cursor': {
    cursor: ['ew-resize', 'col-resize'],
  },
  '.tableWrapper': {
    overflowX: 'auto',
    padding: '1rem 0',
  },
  border: `1px solid ${theme.palette.grey[400]}`,
  borderRadius: 6,
  fontFamily: 'sans-serif',
  maxHeight: '80vh',
  overflow: 'hidden',
}));

interface Props {
  isDisabled?: boolean;
  isRequired?: boolean;
  isSlackCompatible?: boolean;
  label?: string;
  onChange: (val: string) => void;
  value: any;
}

const RichTextEditor = ({
  isDisabled = false,
  isRequired = false,
  isSlackCompatible = false,
  label,
  onChange,
  value,
}: Props) => {
  const [hasFocus, setHasFocus] = useState(false);
  const theme = useTheme();

  const editor = useEditor({
    autofocus: true,
    content: value,
    editable: !isDisabled,
    extensions: [
      StarterKit,
      Link.configure({
        openOnClick: false,
      }),
      ...(isSlackCompatible
        ? [
            Image.configure({
              HTMLAttributes: { height: 20, width: 20 },
              inline: true,
            }),
          ]
        : [
            Underline,
            Image.configure({
              HTMLAttributes: { height: 20, width: 20 },
              inline: true,
            }),
            Table.configure({
              resizable: true,
            }),
            TableRow,
            TableHeader,
            TableCell,
          ]),
    ],
    onBlur: () => setHasFocus(false),
    onFocus: () => setHasFocus(true),
    onUpdate: ({ editor }) => {
      const html = editor.getHTML();
      const formattedHtml = isSlackCompatible
        ? html.replace(
            /<img.*?alt="(.*?)".*?data-slack-emoji="(.*?)".*?>/g,
            ':$2:'
          )
        : html
            .replace(/(<(li|td|th)>)<p>/g, '$1')
            .replace(/<\/p>(<\/(li|td|th)>)/g, '$1')
            .replace(/<(p|li)>\s*<\/\1>/g, '');
      onChange && onChange(formattedHtml);
    },
  });

  return (
    <Box>
      {label ? (
        <FormLabel
          color='primary'
          focused={hasFocus}
          required={isRequired}
          sx={{ display: 'block', pb: 0.75 }}
        >
          {label}
        </FormLabel>
      ) : null}
      <StyledWrapper
        sx={{
          ...(hasFocus
            ? { outline: `${theme.palette.primary.main} auto 1px` }
            : {}),
          '.ProseMirror': getRichTextStyling(theme),
        }}
      >
        <RichTextToolbar
          editor={editor}
          isSlackCompatible={isSlackCompatible}
        />
        <EditorContent editor={editor} />
      </StyledWrapper>
    </Box>
  );
};

export default RichTextEditor;
