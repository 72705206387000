import React from 'react';
import { Route, Switch } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import Head from 'components/Core/Head';
import EventCreate from 'components/Event/EventCreate';
import EventLoader from 'components/Event/EventLoader';
import EditorWrapper from 'components/Events/Editor/EditorWrapper';
import NotFound from 'components/shared/views/NotFound';

const SentryRoute = Sentry.withSentryRouting(Route);

const EventCreateRoutes = () => (
    <EditorWrapper>
      <Switch data-testid='TODO:DATA-SWITCH-45164'>
        <SentryRoute path='/event/new/create'>
          <EventCreate />
        </SentryRoute>
        <SentryRoute path='/event/:id/create'>
          <EventLoader />
        </SentryRoute>
        <SentryRoute path='*'>
          <Head title='Page Not Found | Five to Nine' />
          <NotFound />
        </SentryRoute>
      </Switch>
    </EditorWrapper>
  );

export default EventCreateRoutes;
