import React, { useContext, useEffect, useState } from 'react';
import { isBrowser } from 'react-device-detect';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { isEqual } from 'lodash';
import { useSnackbar } from 'notistack';

import { FullscreenLoader } from 'components/Core/Loader';
import GcalEventForm from 'components/Public/PublicGcalEvent/GcalEventForm';
import ICSDownloader from 'components/Public/PublicGcalEvent/ICSDownloader';
import { AuthContext } from 'contexts/AuthContext';
import { PublicOrgContext } from 'contexts/PublicOrgContext';
import { axiosAuthenticated } from 'utils/axios';
import { transformEvent } from 'utils/transform';
import { useDelayedLoading } from 'utils/utils';

const EventRedirector = () => {
  const { endTask, isWorking, startTask } = useDelayedLoading();
  const { id } = useParams();
  const [publicOrg, isLoading] = useContext(PublicOrgContext);
  const {
    token: { isAuthenticated },
  } = useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar();

  const [savedEvent, setSavedEvent] = useState({});
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    if (!isWorking && isEqual(savedEvent, {}) && !hasError) {
      startTask();
      isAuthenticated
        ? axiosAuthenticated(
            {
              method: 'get',
              url: `/api/events/${id}`,
            },
            (res) => {
              if (res.data && res.data.error) {
                setHasError(true);
                enqueueSnackbar(
                  'An error occurred loading the event. ' + res.data.error,
                  { variant: 'error' }
                );
              } else {
                setSavedEvent(transformEvent(res.data));
              }
              endTask();
            },
            () => {
              endTask();
            }
          )
        : axios
            .get(`${import.meta.env.VITE_BE_URL}/api/events/${id}/public`)
            .then((res) => {
              setSavedEvent(transformEvent(res.data, true));
              endTask();
            })
            .catch((error) => {
              if (error.response.status === 401) {
                setHasError(true);
                const pathname = window.location.href;
                sessionStorage.setItem('fromPageUrl', JSON.stringify(pathname));
                window.location.replace('/login');
              }
              endTask();
            });
    }
  });

  return isLoading || isWorking || hasError ? (
    <div className='loader-wrapper'>
      <FullscreenLoader
        message='Loading event...'
        show={isLoading || isWorking}
      />
    </div>
  ) : isBrowser ? (
    <GcalEventForm id={id} subdomain={publicOrg?.subdomain} />
  ) : (
    <ICSDownloader id={id} name={savedEvent?.name} />
  );
};

export default EventRedirector;
