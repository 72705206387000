import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router';
import { capitalize } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import * as _ from 'lodash';
import { useSnackbar } from 'notistack';

import { EmployeesContext } from 'contexts/EmployeesContext';
import { GroupsContext } from 'contexts/GroupsContext';
import { OrganizationContext } from 'contexts/OrganizationContext';
import { axiosAuthenticated as axios } from 'utils/axios';
import { fullName } from 'utils/employee';
import { SurveysInitialState } from 'utils/survey';
import { transformEvent } from 'utils/transform';

export const usePendingEvent = (savedEvent, pendingData) =>
  useMemo(
    () => _.assign({}, savedEvent, pendingData?.event),
    [savedEvent, pendingData]
  );

export const usePendingSurveys = (savedEvent, pendingData) =>
  useMemo(
    () => ({
      default_survey: _.assign(
        {
          survey_questions: [],
          survey_responses: [],
        },
        savedEvent?.default_survey,
        pendingData?.surveys?.default_survey
      ),
      post_event_survey: _.assign(
        {
          style: 'post',
          survey_options: [],
          survey_options_responses: [],
          survey_questions: [],
          survey_responses: [],
          survey_template_id: null,
        },
        savedEvent?.post_event_survey,
        pendingData?.surveys?.post_event_survey
      ),
      pre_event_survey: _.assign(
        {
          style: 'pre',
          survey_options: [],
          survey_options_responses: [],
          survey_questions: [],
          survey_responses: [],
          survey_template_id: null,
        },
        savedEvent?.pre_event_survey,
        pendingData?.surveys?.pre_event_survey
      ),
    }),
    [savedEvent, pendingData]
  );

export const useSurveyResponses = (pendingData, pendingSurveys) =>
  useMemo(
    () => ({
      post: _.assign(
        {},
        pendingData?.surveys?.post_event_survey?.responses,
        pendingSurveys?.post_event_survey?.responses
      ),
      pre: _.assign(
        {},
        pendingData?.surveys?.pre_event_survey?.responses,
        pendingSurveys?.pre_event_survey?.responses,
        pendingData?.surveys?.default_survey?.responses,
        pendingSurveys?.default_survey?.responses
      ),
    }),
    [
      pendingData?.surveys?.post_event_survey?.responses,
      pendingData?.surveys?.pre_event_survey?.responses,
      pendingData?.surveys?.default_survey?.responses,
      pendingSurveys?.post_event_survey?.responses,
      pendingSurveys?.pre_event_survey?.responses,
      pendingSurveys?.default_survey?.responses,
    ]
  );

export const useLoadEvent = (
  startTask,
  endTask,
  loadingPredicate,
  setSavedEvent,
  clearPending
) => {
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  useEffect(() => {
    const id = parseInt(location?.pathname?.split('/')[2]);

    if (id && loadingPredicate()) {
      startTask("We're gathering the details about your event!");
      axios(
        {
          method: 'get',
          timeout: 300000,
          url: `/api/events/${id}${
            location?.pathname?.indexOf('view') > -1 ? '/page' : ''
          }`,
        },
        (res) => {
          if (res.data && res.data.error) {
            enqueueSnackbar(
              'An error occurred loading the event. ' + res.data.error,
              { variant: 'error' }
            );
          } else {
            setSavedEvent(transformEvent(res.data));
            queryClient.setQueryData(['event', id], res.data); // temp, will remove when manager context is finally removed
            clearPending(true, false, false, false);
          }
          endTask();
        },
        () => {
          endTask();
        }
      );
    }
  }, [
    location?.pathname,
    enqueueSnackbar,
    clearPending,
    endTask,
    startTask,
    loadingPredicate,
    setSavedEvent,
    queryClient,
  ]);
};

export const useReloadEvent = (
  startTask,
  endTask,
  setSavedEvent,
  clearPending
) => {
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const reloadEvent = useCallback(() => {
    const id = parseInt(location?.pathname?.split('/')[2]);

    if (id) {
      startTask("We're gathering the details about your event!");
      axios(
        {
          method: 'get',
          timeout: 30000,
          url: `/api/events/${id}`,
        },
        (res) => {
          if (res.data && res.data.error) {
            enqueueSnackbar(
              'An error occurred loading the event. ' + res.data.error,
              { variant: 'error' }
            );
          } else {
            setSavedEvent(transformEvent(res.data));
            queryClient.setQueryData(['event', id], res.data); // temp, will remove when manager context is finally removed
            clearPending(true, false, false, false);
          }
          endTask();
        },
        () => {
          endTask();
        }
      );
    }
  }, [
    location?.pathname,
    enqueueSnackbar,
    clearPending,
    endTask,
    startTask,
    setSavedEvent,
    queryClient,
  ]);

  return { reloadEvent };
};

export const useUnsavedSurveyChanges = (pendingSurveys, savedEvent) =>
  useMemo(() => {
    // if survey doesn't have id, we compare pendingSurveys against an initial static state that is a copy of pending surveys
    // if survey has an id, we compare the changes in the pending surveys against what's saved on the event.
    // both comparison will omit the fields that we're not saving onto the event
    const pendingPre = pendingSurveys?.pre_event_survey;

    const preSurveyA = pendingPre?.id
      ? pendingPre
      : SurveysInitialState.pre_event_survey;

    const preSurveyB = pendingPre?.id
      ? savedEvent?.pre_event_survey
      : pendingPre;

    const pendingPost = pendingSurveys?.post_event_survey;

    const postSurveyA = pendingPost?.id
      ? pendingPost
      : SurveysInitialState.post_event_survey;

    const postSurveyB = pendingPost?.id
      ? savedEvent?.post_event_survey
      : pendingPost;

    const hasSurveyChanged = !_.isEqual(pendingSurveys, SurveysInitialState)
      ? ((pendingPre?.enabled || pendingPre?.editing) &&
          !_.isEqual(
            _.omit(_.cloneDeep(preSurveyA), ['editing', 'enabled']),
            _.omit(_.cloneDeep(preSurveyB), ['editing', 'enabled'])
          )) ||
        ((pendingPost?.enabled || pendingPost?.editing) &&
          !_.isEqual(
            _.omit(_.cloneDeep(postSurveyA), ['editing', 'enabled']),
            _.omit(_.cloneDeep(postSurveyB), ['editing', 'enabled'])
          ))
      : false;

    return hasSurveyChanged;
  }, [savedEvent, pendingSurveys]);

export const useUnsavedMessageChanges = (pendingMessage, savedEvent) => {
  const savedEventMessage = savedEvent?.event_messages?.find(
    (message) => message?.id === pendingMessage?.id
  );

  const hasBodyChanged =
    (!savedEventMessage && pendingMessage?.message_body?.length > 0) ||
    (savedEventMessage &&
      pendingMessage?.message_body !== savedEventMessage?.message_body);

  const hasScheduledForChanged =
    pendingMessage?.scheduled_for?.length > 0 &&
    pendingMessage?.scheduled_for !== savedEventMessage?.scheduled_for;

  const slackChannelsIdsChanged =
    pendingMessage?.slack_channel_ids?.length > 0 &&
    !_.isEqual(
      savedEventMessage?.slack_channel_ids,
      pendingMessage?.slack_channel_ids
    );

  const showRsvpButtonsChanged =
    (savedEventMessage &&
      pendingMessage?.show_rsvp_buttons !==
        savedEventMessage?.show_rsvp_buttons) ||
    (!savedEventMessage && pendingMessage?.show_rsvp_buttons !== false);

  const showEventDetailsButtonChanged =
    (savedEventMessage &&
      pendingMessage?.show_event_details_button !==
        savedEventMessage?.show_event_details_button) ||
    (!savedEventMessage && pendingMessage?.show_event_details_button !== false);

  const rsvpStatusesChanged =
    pendingMessage?.message_type === 'email'
      ? pendingMessage?.invitee_statuses?.length > 0 &&
        !_.isEqual(
          savedEventMessage?.invitee_statuses,
          pendingMessage?.invitee_statuses
        )
      : false;

  return (
    hasBodyChanged ||
    hasScheduledForChanged ||
    slackChannelsIdsChanged ||
    rsvpStatusesChanged ||
    showRsvpButtonsChanged ||
    showEventDetailsButtonChanged
  );
};

export const useDisplayedHostSelections = (
  // TODO: Deprecate this version
  event,
  inPublicEventContext = false
) => {
  const { employees } = useContext(EmployeesContext);
  const [groups] = useContext(GroupsContext);
  const [organization] = useContext(OrganizationContext);

  return useMemo(() => {
    const mapOptions = (options, optionDisplayableType) => {
      const officeType = optionDisplayableType === 'Offices';

      return options?.map((option) => {
        const officeName =
          officeType &&
          (!option.name.toLowerCase().includes('office') ||
            !option.name.toLowerCase().includes('headquarter'))
            ? option.name + ' Office'
            : option.name;

        return {
          label: officeType ? officeName : option.name,
          type: optionDisplayableType,
          value: option.id,
        };
      });
    };
    const employeeOptions =
      employees
        ?.filter((e) => e.user_id && (e.is_admin || e.is_organizer))
        ?.map((o) => ({
          additional: o.initials,
          id: o.id,
          label: fullName(o),
          type: 'Organizers',
          value: o.id,
          withAvatar: true,
        }))
        ?.sort((a, b) =>
          `${a.label} ${a.secondary}`?.localeCompare(
            `${b.label} ${b.secondary}`
          )
        ) || [];

    const mappedGroups = mapOptions(groups, 'Groups') || [];

    const mappedOffices = mapOptions(organization?.offices, 'Offices') || [];

    const allOptionsToSelect = [
      ...mappedGroups,
      ...mappedOffices,
      ...employeeOptions,
    ];

    const displaySelections = inPublicEventContext
      ? event?.displayable_hosts?.flatMap(({ names }) => names)
      : allOptionsToSelect.filter((option) =>
          event?.displayable_hosts?.some(
            (displayable) =>
              displayable.ids.includes(option.value) &&
              displayable.type === option.type
          )
        ) || [];

    return {
      allDisplayedHostOptions: allOptionsToSelect,
      allDisplayedHostSelections: displaySelections,
      employeeOptions,
    };
  }, [
    employees,
    groups,
    organization?.offices,
    inPublicEventContext,
    event?.displayable_hosts,
  ]);
};

export const useEventLocationType = (event) => {
  const id = useMemo(() => {
    if (event?.meeting?.service) {
      if (event?.location?.length > 0) {
        return 'hybrid';
      } else {
        return 'virtual';
      }
    } else if (event?.location?.length > 0) {
      return 'inperson';
    } else {
      return undefined;
    }
  }, [event?.location?.length, event?.meeting?.service]);

  const displayName = useMemo(
    () =>
      id?.length > 0
        ? id === 'inperson'
          ? 'In-person'
          : capitalize(id)
        : null,
    [id]
  );

  return { displayName, id };
};

export const useDateTimeErrors = (errorArray) =>
  useMemo(() => errorArray?.some((e) => e?.length > 0), [errorArray]);
