import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import OnboardFrame from 'components/Onboard/OnboardFrame';
import { AuthContext } from 'contexts/AuthContext';

const AllowedTargetOrigin = import.meta.env.VITE_BE_URL;

const TokenAccept = () => {
  const history = useHistory();
  const {
    token: { setToken },
  } = useContext(AuthContext);

  useEffect(() => {
    const handler = (event) => {
      if (event?.data?.token) {
        setToken(event.data.token);
        history.push('/events');
      }
    };

    window.addEventListener('message', handler);
    window?.opener?.postMessage('readyForToken', AllowedTargetOrigin);

    return () => window.removeEventListener('message', handler);
  }, [history, setToken]);

  return <OnboardFrame title='Waiting to receive token...' />;
};
export default TokenAccept;
