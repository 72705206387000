import React, { useRef, useState } from 'react';
import Cropper, { ReactCropperElement } from 'react-cropper';
import { Controller } from 'react-hook-form';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormLabel,
  Stack,
} from '@mui/material';
import { Image, NotePencil, Trash } from '@phosphor-icons/react';

import FileUploader from 'components/shared/FileUploader';
import ImageFieldButton from 'components/shared/ImageFieldButton';

import 'cropperjs/dist/cropper.css';

export interface ImageFieldValue {
  cropDataUrl?: string;
  deleteImage?: boolean;
  dimensions?: {
    height: number;
    width: number;
    x: number;
    y: number;
  };
  file: File;
  fullDataUrl?: string;
}

interface ImageFieldProps {
  control: any;
  id: string;
  isDisabled: boolean;
  label: string;
  name: string;
  savedImageUrl?: string;
  uploadSubtext?: string;
  uploadText?: string;
}

const ImageField = ({
  control,
  id,
  isDisabled = false,
  label,
  name,
  savedImageUrl,
  uploadSubtext = 'Recommended aspect ratio is 16:9 (700 ⨉ 394 pixels)',
  uploadText = 'Drag & drop or click to browse your files',
}: ImageFieldProps) => {
  const cropperRef = useRef<ReactCropperElement>(null);

  const [tempDimensions, setTempDimensions] = useState({});
  const [tempUrl, setTempUrl] = useState('');
  const [isEditing, setIsEditing] = useState(false);

  const handleCloseCrop = () => {
    setIsEditing(false);
  };

  const handleCropChange = () => {
    const cropper = cropperRef?.current?.cropper;
    if (cropper) {
      const data: Cropper.Data = cropper?.getData(true);
      const url: string = cropper?.getCroppedCanvas().toDataURL();

      setTempDimensions(data);
      setTempUrl(url);
    }
  };

  const handleRemove = (field: any) => {
    if (field?.value?.fullDataUrl) {
      URL.revokeObjectURL(field?.value?.fullDataUrl);
    }
    if (field?.value?.cropDataUrl) {
      URL.revokeObjectURL(field?.value?.cropDataUrl);
    }

    field.onChange({
      deleteImage: true,
    });
  };

  const handleSave = (field: any) => {
    field.onChange({
      ...field.value,
      cropDataUrl: tempUrl,
      dimensions: tempDimensions,
    });

    handleCloseCrop();
  };

  const handleDrop = (files: File[], field: any) => {
    // release previous image url
    if (field?.value?.fullDataUrl) {
      URL.revokeObjectURL(field?.value?.fullDataUrl);
    }
    if (field?.value?.cropDataUrl) {
      URL.revokeObjectURL(field?.value?.cropDataUrl);
    }
    // create url for new image
    const file: File = files[0];
    const fullDataUrl = URL.createObjectURL(file);

    const newFieldValue: ImageFieldValue = {
      file,
      fullDataUrl,
    };

    // save the image
    field.onChange(newFieldValue);

    // open the cropper dialog
    setIsEditing(true);
  };

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => {
        const value: ImageFieldValue = field?.value;
        return (
          <FormControl fullWidth>
            <FormLabel htmlFor={id}>{label} </FormLabel>
            {value?.cropDataUrl || (savedImageUrl && !value.deleteImage) ? (
              <Box position='relative'>
                <img
                  alt='event banner'
                  src={value?.cropDataUrl || savedImageUrl}
                  style={{
                    aspectRatio: '16 / 9',
                    borderRadius: 6,
                    maxWidth: '100%',
                    objectFit: 'contain',
                    width: '100%',
                  }}
                />
                {!isDisabled && (
                  <Stack
                    bottom={16}
                    direction='row'
                    gap={1}
                    justifyContent='flex-end'
                    position='absolute'
                    right={12}
                  >
                    <ImageFieldButton
                      data-testid='edit-image-btn'
                      disabled={isDisabled}
                      onClick={() => setIsEditing(true)}
                      startIcon={<NotePencil />}
                    >
                      Edit
                    </ImageFieldButton>
                    <ImageFieldButton
                      data-testid='remove-img-btn'
                      disabled={isDisabled}
                      onClick={() => handleRemove(field)}
                      startIcon={<Trash />}
                    >
                      Delete
                    </ImageFieldButton>
                  </Stack>
                )}
              </Box>
            ) : (
              <FileUploader
                customSubtext={uploadSubtext}
                customText={uploadText}
                Icon={Image}
                id={id}
                isDisabled={isDisabled}
                noun='image'
                onDrop={(files: File[]) => handleDrop(files, field)}
                sx={{ aspectRatio: '16 / 9' }}
              />
            )}
            <Dialog
              aria-labelledby='crop-image-dialog-title'
              fullWidth
              maxWidth='md'
              onClose={handleCloseCrop}
              open={isEditing}
            >
              <DialogTitle id='crop-image-dialog-title'>Edit image</DialogTitle>
              <DialogContent>
                <DialogContentText>Crop/resize image</DialogContentText>
                <Box bgcolor='grey.700' width='100%'>
                  <Cropper
                    aspectRatio={16 / 9}
                    autoCropArea={
                      // INFO: Newly uploaded image
                      value?.fullDataUrl && !value?.cropDataUrl
                        ? 1 // INFO: Expand initial crop area to full width or height
                        : undefined
                    }
                    background={false}
                    crop={() => handleCropChange()}
                    data={value?.dimensions || undefined}
                    guides
                    minCropBoxHeight={30}
                    ref={cropperRef}
                    src={value?.fullDataUrl || undefined}
                    style={{ maxHeight: '70vh', maxWidth: '100%' }}
                    viewMode={2}
                  />
                </Box>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseCrop}>Cancel</Button>
                <Button onClick={() => handleSave(field)} variant='contained'>
                  Save
                </Button>
              </DialogActions>
            </Dialog>
          </FormControl>
        );
      }}
    />
  );
};

export default ImageField;
