import React from 'react';
import { ListItem, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import { ConditionalLoader } from 'components/Core/Loader';
import { InvitedCheckIcon } from 'components/Events/Controls/Invites/Invite.constants';
import SlackChannelLabel from 'components/shared/SlackChannelLabel';
import { useSyncedSlackChannels } from 'hooks/useSyncedSlackChannels';
import { flexColumn } from 'utils/styles';

const SlackHistory = ({ message, methods }) => {
  const { data: syncedChannels, isLoading } = useSyncedSlackChannels();

  return (
    <ConditionalLoader conditions={[isLoading]}>
      <ListItem
        dense
        disableGutters
        disablePadding
        sx={{ ...flexColumn, alignItems: 'stretch', gap: 1 }}
      >
        {message?.invite_body?.length > 0 ? (
          <Stack alignItems='center' direction='row' gap={1}>
            <InvitedCheckIcon />
            <Typography>Personalized message</Typography>
          </Stack>
        ) : null}
        <Stack alignItems='center' direction='row' flexWrap='wrap' gap={1}>
          <InvitedCheckIcon />
          <Typography display='inline'>Selected channels:</Typography>
          {methods?.invitation
            .filter(
              (method) =>
                method?.recipients?.find(
                  (recipient) => recipient?.type === 'SlackChannels'
                )?.ids !== null
            )
            .map((method) => {
              const invitedChannelsIds = (method?.recipients || [])
                .filter((recipient) => recipient?.type === 'SlackChannels')
                .map((recipient) => recipient?.ids)
                .flat();
              const channels = invitedChannelsIds.map((id) =>
                syncedChannels?.find((channel) => channel?.slack_id === id)
              );
              return channels.map((channel) => (
                <SlackChannelLabel
                  isChip
                  isPrivate={channel?.private}
                  key={method?.id + channel?.slack_id}
                  name={channel?.name}
                />
              ));
            })}
        </Stack>
      </ListItem>
    </ConditionalLoader>
  );
};

SlackHistory.propTypes = {
  message: PropTypes.object,
  methods: PropTypes.object,
};

export default SlackHistory;
