import React from 'react';
import PropTypes from 'prop-types';

const TabPanel = ({ children, value, index, ...other }) => {
  return (
    (<div
      aria-labelledby={`tab-${index}`}
      hidden={value !== index}
      id={`tabpanel-${index}`}
      role='tabpanel'
      {...other}
    >
      {value === index && <>{children}</>}
    </div>)
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default TabPanel;
