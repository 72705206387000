import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

import { MessageFormData } from 'components/Events/Controls/Communications/communication.types';
import RichTextEditor from 'components/shared/RichTextEditor';

interface CommunicationMessageFieldProps {
  hookForm: UseFormReturn<MessageFormData>;
  isSlack: boolean;
}

const CommunicationMessageField = ({
  hookForm: { control },
  isSlack,
}: CommunicationMessageFieldProps) => (
  <Controller
    control={control}
    name='message_body'
    render={({ field }) => (
      <RichTextEditor
        isRequired
        isSlackCompatible={isSlack}
        label='Message'
        onChange={(v) => field.onChange(v.trim())}
        value={field.value}
      />
    )}
    rules={{ required: true }}
  />
);

export default CommunicationMessageField;
