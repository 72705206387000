import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Fade, ListSubheader, Menu } from '@mui/material';

import { communicationMethods } from 'components/Events/Controls/Communications/communication.constants';
import {
  CommunicationMethod,
  CommunicationType,
} from 'components/Events/Controls/Communications/communication.types';
import CommunicationMethodMenuItem from 'components/Events/Controls/Communications/CommunicationMethodMenuItem';
import { EventInvitation, Recipient } from 'gql/graphql';
import { useEventInvitations } from 'hooks/useEventInvitations';
import useSelectCommunicationToSend from 'hooks/useSelectCommunicationToSend';

interface Props {
  buttonLabel?: string;
  fullWidth?: boolean;
  type: CommunicationType;
}

const CommunicationMethodMenu = ({
  buttonLabel,
  fullWidth = false,
  type,
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);
  const open = Boolean(anchorEl);
  const TypeIcon = type?.Icon;

  const { id } = useParams<any>();

  const { data } = useEventInvitations({
    eventId: Number(id),
  });

  const hasChannelsToMessage = useMemo(() => {
    if (type?.id === 'message') {
      return data && data?.eventInvitations?.length > 0
        ? data?.eventInvitations.some(
            (h: EventInvitation) =>
              h?.inviteType === 'slack' &&
              h?.isSent &&
              h?.recipients?.some(
                (r: Recipient) => r.recipientType === 'SlackChannel'
              )
          )
        : false;
    } else {
      return false;
    }
  }, [data, type?.id]);

  const { open: openInvitation, select } = useSelectCommunicationToSend();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        aria-controls={open ? 'communication-method-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup='true'
        fullWidth={fullWidth}
        id='communication-method-menu-button'
        onClick={handleClick}
        startIcon={<TypeIcon size={20} />}
        variant='contained'
      >
        {buttonLabel}
      </Button>
      <Menu
        anchorEl={anchorEl}
        id='communication-method-menu'
        MenuListProps={{
          'aria-labelledby': 'communication-method-menu-button',
          disablePadding: true,
          sx: {
            minWidth: anchorEl && anchorEl?.offsetWidth,
          },
        }}
        onClose={handleClose}
        open={open}
        TransitionComponent={Fade}
      >
        <ListSubheader
          sx={{
            bgcolor: 'grey.100',
            lineHeight: '32px',
            py: 1,
            textTransform: 'uppercase',
            typography: 'caption',
          }}
        >
          Select delivery method
        </ListSubheader>
        {communicationMethods
          ?.filter((m) => type?.id === 'invitation' || m.id !== 'calendar')
          ?.map((method: CommunicationMethod) => (
            <CommunicationMethodMenuItem
              handleClick={() =>
                type?.id === 'invitation'
                  ? openInvitation({ method: method?.id })
                  : select(method?.id, type?.id)
              }
              hasChannelsToMessage={hasChannelsToMessage}
              key={method?.id}
              method={method}
              tooltip={method?.tooltip}
              type={type}
            />
          ))}
      </Menu>
    </>
  );
};

export default CommunicationMethodMenu;
