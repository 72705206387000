import React from 'react';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import 'core-js/actual/array';
import 'regenerator-runtime/runtime';
import '@fontsource/inter';
import { Box } from '@mui/system';
import * as Sentry from '@sentry/react';
import { FlagProvider } from '@unleash/proxy-client-react';
import App from 'app';
import { SnackbarProvider } from 'notistack';
import reportWebVitals from 'reportWebVitals';

import Amplitude from 'components/shared/Amplitude';
import Pendo from 'components/shared/Pendo';
import FtnThemeProvider from 'contexts/FtnThemeProvider';
import { LayoutProvider } from 'contexts/LayoutContext';

let traceRate = 0.2;
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  traceRate = 1.0;
}
const environName = process.env.NODE_ENV;
const dsn = import.meta.env.VITE_SENTRY_DSN;

Sentry.init({
  dsn,
  enabled: environName !== 'development',
  environment: environName,
  integrations: [Sentry.browserTracingIntegration()],
  release: import.meta.env.VITE_APP_VERSION,
  replaysOnErrorSampleRate: 0.1,
  replaysSessionSampleRate: 1.0,
  tracesSampleRate: traceRate,
});

const unleash_config = {
  appName: 'fivetonine',
  clientKey: import.meta.env.VITE_FLAGKEY,
  environment: environName === 'production' ? environName : 'development',
  refreshInterval: 15,
  url: `${import.meta.env.VITE_FLAGHOST}/proxy`,
};

const container = document.getElementById('root');

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

root.render(
  <FlagProvider config={unleash_config}>
    <HelmetProvider>
      <FtnThemeProvider>
        <LayoutProvider>
          <Box
            display='flex'
            flexDirection='column'
            height='100vh'
            maxHeight='100vh'
            minWidth='100vw'
            width='100vw'
          >
            <Pendo />
            <Amplitude />
            <SnackbarProvider
              style={{ maxWidth: 'calc(100vw - 200px)' }}
              transitionDuration={{ enter: 300, exit: 200 }}
            >
              <App />
            </SnackbarProvider>
          </Box>
        </LayoutProvider>
      </FtnThemeProvider>
    </HelmetProvider>
  </FlagProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
