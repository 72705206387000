import React, { useCallback, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
} from '@mui/material';
import { Check, Gear } from '@phosphor-icons/react';
import { useSnackbar } from 'notistack';

import BudgetConfirmationDialog from 'components/Event/Budget/BudgetConfirmationDialog';
import EventGroupsAutocomplete from 'components/Event/EventGroupsAutocomplete';
import CurrencySelect from 'components/shared/CurrencySelect';
import { useEventBudget } from 'hooks/useEventBudget';
import { useEventBudgetSettingsForm } from 'hooks/useEventBudgetSettingsForm';
import { EventBudgetSettingsFormData } from 'types/EventBudget';

const EventBudgetSettings = () => {
  const { id: eventId } = useParams<{ id: string }>();
  const { enqueueSnackbar } = useSnackbar();
  const [confirmingAction, setConfirmingAction] = useState<
    'remove-groups' | 'discard-changes' | 'change-currency' | undefined
  >(undefined);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [removedGroups, setRemovedGroups] = useState<any[]>([]);

  const {
    retrieve: { data: budgetData, refetch },
  } = useEventBudget({ eventId: Number(eventId) });

  const hasBudgetOrExpenses = React.useMemo(
    () =>
      !!(
        budgetData?.amount ||
        (budgetData?.expense_items && budgetData.expense_items.length > 0)
      ),
    [budgetData]
  );

  const { data, form, resetForm, update } = useEventBudgetSettingsForm({
    eventId: Number(eventId),
  });
  const { control, formState, handleSubmit, watch } = form;

  const groupsWatch = watch('groups');
  const currencyIdWatch = watch('currencyId');

  const openDialog = useCallback(() => setIsOpen(true), []);
  const closeDialog = useCallback(() => {
    formState.isDirty
      ? setConfirmingAction('discard-changes')
      : setIsOpen(false);
  }, [formState]);

  const saveChanges = useCallback(
    async (params: EventBudgetSettingsFormData) => {
      try {
        const groupIds = params.groups.map((group: { id: any }) => group.id);
        await update.mutateAsync({
          amount: params.amount !== undefined ? params.amount : undefined,
          currency_id: params.currencyId
            ? JSON.stringify(params.currencyId)
            : undefined,
          group_ids: JSON.stringify(groupIds),
        });
        resetForm();
        setIsOpen(false);
        refetch();
      } catch (error) {
        enqueueSnackbar('Failed to update event budget settings', {
          variant: 'error',
        });
      }
    },
    [enqueueSnackbar, refetch, resetForm, update]
  );

  const handleMutation = async (formData: EventBudgetSettingsFormData) => {
    if (
      hasBudgetOrExpenses &&
      data?.currency_id &&
      data?.currency_id !== formData.currencyId
    ) {
      setConfirmingAction('change-currency');
      return;
    }

    const initialGroupIds = formData.groupIds;
    const removedGroupIds = initialGroupIds.filter(
      (id: any) =>
        !formData.groups.some((group: { id: any }) => group.id === id)
    );

    const removedGroupsFromInitial =
      data?.linked_groups?.filter((group: { id: number }) =>
        removedGroupIds.includes(group.id)
      ) || [];

    if (removedGroupsFromInitial.length > 0) {
      setConfirmingAction('remove-groups');
      setRemovedGroups(removedGroupsFromInitial);
    } else {
      await saveChanges(form.getValues());
    }
  };

  const handleConfirm = async () => {
    await saveChanges(form.getValues());
    setConfirmingAction(undefined);
  };

  const handleGroupsChange = (selectedGroups: any[]) => {
    if (selectedGroups.length > 0) {
      form.setValue('currencyId', selectedGroups[0].currency_id);
    } else {
      form.resetField('currencyId');
    }
  };

  return (
    <Box>
      <Button
        onClick={openDialog}
        startIcon={<Gear />}
        sx={{ my: -1, whiteSpace: 'nowrap' }}
      >
        Manage settings
      </Button>
      <form>
        <Dialog fullWidth maxWidth='sm' onClose={closeDialog} open={isOpen}>
          <DialogTitle>Manage budget settings</DialogTitle>
          <DialogContent>
            <Stack alignItems='stretch' direction='column' gap={3}>
              <DialogContentText>
                Manage linked groups and currency for event expenses.
              </DialogContentText>
              <Controller
                control={control}
                name='groups'
                render={({ field }) => (
                  <form>
                    <EventGroupsAutocomplete
                      {...field}
                      disableFields={false}
                      form={form}
                      label='Link event to groups'
                      onGroupsChange={handleGroupsChange}
                    />
                  </form>
                )}
              />

              <Controller
                control={control}
                name='currencyId'
                render={({ field }) => (
                  <CurrencySelect
                    {...field}
                    control={control}
                    groupsWatch={groupsWatch}
                    label='Select currency'
                    name='currencyId'
                  />
                )}
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDialog} variant='outlined'>
              Cancel
            </Button>
            <Button
              disabled={
                !(formState.isDirty || currencyIdWatch !== data?.currency_id)
              }
              onClick={handleSubmit(handleMutation)}
              startIcon={<Check />}
              variant='contained'
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </form>
      <BudgetConfirmationDialog
        confirmButtonText='Unlink and save'
        message='By continuing, you will be unlinking the following groups from your event. That means any event expenses and analytics will no longer be associated with these groups:'
        onCancel={() => setConfirmingAction(undefined)}
        onConfirm={handleConfirm}
        open={confirmingAction === 'remove-groups'}
        removedGroups={removedGroups}
        title='Unlink group and save?'
      />
      <BudgetConfirmationDialog
        confirmButtonText='Continue'
        message='If you continue, the changes made to your budget settings will be discarded. Are you sure you want to leave without saving these changes?'
        onCancel={() => setConfirmingAction(undefined)}
        onConfirm={() => {
          resetForm();
          setConfirmingAction(undefined);
          setIsOpen(false);
        }}
        open={confirmingAction === 'discard-changes'}
        title='Unsaved Changes'
      />
      <BudgetConfirmationDialog
        confirmButtonText='Change and save'
        message='If you proceed, your currency type will change, but please note that any recorded spending will remain in its original value and will not be converted to match the new currency selected.'
        onCancel={() => setConfirmingAction(undefined)}
        onConfirm={() => {
          handleConfirm();
        }}
        open={confirmingAction === 'change-currency'}
        title='Change currency?'
      />
    </Box>
  );
};

export default EventBudgetSettings;
