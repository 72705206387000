import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Tab, Tabs } from '@mui/material';
import PropTypes from 'prop-types';

import ContentWidthContainer from 'components/shared/layout/ContentWidthContainer';
import DefaultAppBar from 'components/shared/layout/DefaultAppBar';
import TabPanel from 'components/shared/TabPanel';

const a11yProps = (index) => ({
  'aria-controls': `tabpanel-${index}`,
  id: `tab-${index}`,
});

const TabsLayout = ({
  hasUnsavedChanges = false,
  onChange,
  tabs,
  ...props
}) => {
  const location = useLocation();
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  useEffect(() => {
    if (location?.hash) {
      const tabIndex = tabs.findIndex((t) => `#${t.value}` === location.hash);
      if (tabIndex > -1 && tabIndex !== activeTabIndex) {
        setActiveTabIndex(tabIndex);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, tabs]);

  const handleChange = (event, newIndex) => {
    if (!hasUnsavedChanges) {
      setActiveTabIndex(newIndex);
    }
    onChange(newIndex, event);
  };

  return (
    <>
      <DefaultAppBar
        {...props}
        fixedLocation={props?.fixedLocation || 'below-tabs'}
        TabBar={
          <Tabs
            aria-label={`${props.title} tabs`}
            onChange={handleChange}
            scrollButtons='auto'
            value={activeTabIndex}
            variant='scrollable'
          >
            {tabs.map(({ label, labelCount, value }, index) => (
              <Tab
                {...a11yProps(index)}
                component={NavLink}
                key={index}
                label={`${label}${
                  labelCount && labelCount > 0 ? ` (${labelCount})` : ''
                }`}
                to={{ hash: value }}
              />
            ))}
          </Tabs>
        }
      />
      <ContentWidthContainer>
        {tabs.map((tab, index) => (
          <TabPanel index={index} key={index} value={activeTabIndex}>
            {tab.component}
          </TabPanel>
        ))}
      </ContentWidthContainer>
    </>
  );
};

TabsLayout.propTypes = {
  summaryStat: PropTypes.object,
  tabs: PropTypes.array,
  ...DefaultAppBar.propTypes,
};

export default TabsLayout;
