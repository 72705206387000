import React, {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { matchPath } from 'react-router';
import * as amplitude from '@amplitude/analytics-browser';
import * as Sentry from '@sentry/react';
import { useUnleashClient } from '@unleash/proxy-client-react';
import * as _ from 'lodash';
import { useSnackbar } from 'notistack';

import { FtnUser } from 'types/FtnUser';
import { axiosAuthenticated } from 'utils/axios';
import { fullName } from 'utils/employee';

export interface UserType {
  email?: string;
  events: any[];
  events_hosted_count?: number;
  has_google_admin_auth?: boolean;
  has_google_calendar_auth?: boolean;
  has_google_sso_auth?: boolean;
  has_outlook_auth?: boolean;
  has_zoom_meeting_auth?: boolean;
  has_zoom_webinar_auth?: boolean;
  has_zoom_webinar_license?: boolean;
  id: string;
  is_admin?: boolean;
  is_organizer?: boolean;
  mfa_required?: boolean;
  office_id?: string;
  organization_id?: string;
  sign_in_count?: number;
}

export interface AuthContextType {
  onboard: {
    setTempToken: (token: string | null) => void;
    token: string | null;
  };
  token: {
    isAuthenticated: boolean;
    setToken: (newToken: string | null) => void;
  };
  userProvider: {
    isOrganizerOrAdmin: boolean | undefined;
    setUser: (user: FtnUser) => void;
    user: FtnUser | undefined;
  };
  welcomeProvider: {
    show: boolean;
    welcomeShown: () => void;
  };
}
const PublicRoutes = [
  { exact: true, path: '/login' },
  { exact: true, path: '/sign-up' },
  { path: '/public/:id' },
  { exact: true, path: '/admin/sign-up' },
  { exact: true, path: '/auth/outlook' },
  { exact: true, path: '/auth/okta' },
  { exact: true, path: '/auth/okta/saml' },
  { exact: true, path: '/reset-password' },
  { exact: true, path: '/change-password' },
  { path: '/survey/:id/pre' },
  { path: '/survey/:id/post' },
  { exact: true, path: '/wait-for-token' },
  { path: '/ext_calendar/:id' },
];

export const AuthContext = createContext<AuthContextType | undefined>(
  undefined
);

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [token, setToken] = useState<string | null>(
    window.localStorage.getItem('token') || null
  );
  const [onboardToken, setOnboardToken] = useState<string | null>(null);
  const [showWelcome, setShowWelcome] = useState<boolean>(false);
  const [user, setUser] = useState<FtnUser | undefined>();
  const sessionWelcomed = window.localStorage.getItem('welcome_shown');
  const unleashClient = useUnleashClient();

  const { enqueueSnackbar } = useSnackbar();

  const setTokenWrapper = useCallback(
    (newToken: string | null) => {
      if (newToken === null) {
        localStorage.removeItem('token');
        setToken(null);
      } else {
        localStorage.setItem('token', newToken);
        setToken(newToken);
      }
    },
    [setToken]
  );

  const renderedChildren = useMemo(() => {
    if (!token) {
      if (
        _.some(PublicRoutes, (route) =>
          matchPath(window.location.pathname, route)
        )
      ) {
        return children;
      } else {
        const pathname = window.location.href;
        sessionStorage.setItem('fromPageUrl', JSON.stringify(pathname));
        window.location.replace('/login');
      }
    }
  }, [children, token]);

  const isOrganizerOrAdmin = useMemo(
    () => user?.is_admin || user?.is_organizer,
    [user?.is_admin, user?.is_organizer]
  );

  const registerPendo = useCallback((userLocal: any) => {
    window?.pendo?.initialize({
      account: {
        id: userLocal?.organization_id,
      },
      visitor: {
        events_hosted_count: userLocal?.events_hosted_count,
        full_name: fullName(userLocal),
        has_google_admin: userLocal?.has_google_admin_auth,
        has_google_calendar: userLocal?.has_google_calendar_auth,
        has_google_sso: userLocal?.has_google_sso_auth,
        has_mfa: userLocal?.mfa_required,
        has_outlook: userLocal?.has_outlook_auth,
        has_zoom_meeting: userLocal?.has_zoom_meeting_auth,
        has_zoom_webinar: userLocal?.has_zoom_webinar_auth,
        has_zoom_webinar_license: userLocal?.has_zoom_webinar_license,
        id: userLocal?.id,
        is_admin: userLocal?.is_admin,
        is_organizer: userLocal?.is_organizer,
        office_id: userLocal?.office_id,
        sign_in_count: userLocal?.sign_in_count,
      },
    });
  }, []);

  const registerAmplitude = useCallback((userLocal: any) => {
    const amplitudeApiKey = import.meta.env.VITE_AMPLITUDE_API_KEY;

    if (amplitudeApiKey == null) {
      return;
    }

    amplitude.setUserId(userLocal?.email);
    amplitude.setGroup('organization_id', userLocal?.organization_id);

    const identifyEvent = new amplitude.Identify();
    identifyEvent.set('user_id', userLocal?.id);
    identifyEvent.set('full_name', fullName(userLocal));
    identifyEvent.set('has_google_admin', userLocal?.has_google_admin_auth);
    identifyEvent.set(
      'has_google_calendar',
      userLocal?.has_google_calendar_auth
    );
    identifyEvent.set('has_google_sso', userLocal?.has_google_sso_auth);
    identifyEvent.set('has_mfa', userLocal?.mfa_required);
    identifyEvent.set('has_outlook', userLocal?.has_outlook_auth);
    identifyEvent.set('has_zoom_meeting', userLocal?.has_zoom_meeting_auth);
    identifyEvent.set('has_zoom_webinar', userLocal?.has_zoom_webinar_auth);
    identifyEvent.set(
      'has_zoom_webinar_license',
      userLocal?.has_zoom_webinar_license
    );
    identifyEvent.set('is_admin', userLocal?.is_admin);
    identifyEvent.set('is_organizer', userLocal?.is_organizer);
    identifyEvent.set('office_id', userLocal?.office_id);
    identifyEvent.set('sign_in_count', userLocal?.sign_in_count);
    amplitude.identify(identifyEvent);
  }, []);

  const registerIntercom = useCallback((userLocal: any) => {
    window?.Intercom('boot', {
      app_id: import.meta.env.VITE_INTERCOM_ID,
      email: userLocal?.email,
      user_id: userLocal?.id,
    });
  }, []);

  const setUserWrapper = useCallback(
    (userLocal: FtnUser) => {
      if (user?.sign_in_count === 1) {
        setShowWelcome(true);
      }

      Sentry?.setUser({ id: userLocal.id });

      unleashClient.setContextField('userId', userLocal.id);

      registerPendo(userLocal);
      registerAmplitude(userLocal);
      registerIntercom(userLocal);

      setUser(userLocal);
    },
    [
      registerAmplitude,
      registerIntercom,
      registerPendo,
      unleashClient,
      user?.sign_in_count,
    ]
  );

  useEffect(() => {
    if (token !== null) {
      if (!user) {
        axiosAuthenticated(
          { method: 'get', url: '/api/users/current' },
          (res: { data: any }) => {
            setUserWrapper(res.data);
          },
          (err: any) => {
            console.error(err);
          }
        );
      }
    }
  }, [token, user, setUserWrapper]);

  useEffect(() => {
    const fromPageUrl = sessionStorage.getItem('fromPageUrl');
    if (
      fromPageUrl !== null &&
      fromPageUrl !== JSON.stringify(window.location.href) && // No redirects to the page we are already on
      fromPageUrl !== JSON.stringify(window.origin + '/') // No redirects if the base URl was requested
    ) {
      enqueueSnackbar('You will be redirected after logging in.', {
        variant: 'info',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthContext.Provider
      value={{
        onboard: { setTempToken: setOnboardToken, token: onboardToken },
        token: {
          isAuthenticated: Boolean(token && token?.length > 0) ?? false,
          setToken: setTokenWrapper,
        },
        userProvider: {
          isOrganizerOrAdmin,
          setUser: setUserWrapper,
          user,
        },
        welcomeProvider: {
          show: sessionWelcomed ? false : showWelcome,
          welcomeShown: () => {
            window.localStorage.setItem('welcome_shown', String(true));
            setShowWelcome(false);
          },
        },
      }}
    >
      {!token && renderedChildren}
      {token && user && children}
    </AuthContext.Provider>
  );
};
