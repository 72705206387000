import React, { useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { TextFieldElement } from 'react-hook-form-mui';
import { useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
} from '@mui/material';
import { MinusCircle, Plus } from '@phosphor-icons/react';
import { useSnackbar } from 'notistack';

import DeleteModal from 'components/Core/DeleteModal';
import EventBudgetCurrencyField, {
  FieldPathByValueType,
} from 'components/Events/Controls/Budget/EventBudgetCurrencyField';
import EventExpenseCategoryField from 'components/Events/Controls/Budget/EventExpenseCategoryField';
import { defaultCurrency } from 'constants/currency.constants';
import { useEventBudget } from 'hooks/useEventBudget';
import { useExpenseSave } from 'hooks/useExpenseSave';
import Currency from 'types/Currency';
import {
  EventBudgetFormData,
  Expense,
  ExpenseCategory,
} from 'types/EventBudget';

interface AddExpensesProps {
  closeDialog: () => void;
  currency: Currency;
  isDialogOpen: boolean;
  options: ExpenseCategory[];
}

const defaultExpenseItem = {
  estimated_cost: null,
  event_budget_id: null,
  expense_category_id: null,
  name: '',
} as Expense;

const AddExpensesDialog = ({
  closeDialog,
  currency,
  isDialogOpen,
  options,
}: AddExpensesProps) => {
  const { id: eventId } = useParams<any>();
  const { enqueueSnackbar } = useSnackbar();
  const [isConfirming, setIsConfirming] = useState(false);

  const {
    retrieve: { data },
  } = useEventBudget({
    eventId,
  });

  const budgetForm = useForm<EventBudgetFormData>({
    defaultValues: {
      expense_items: [{ ...defaultExpenseItem, event_budget_id: data?.id }],
    },
    resetOptions: { keepDefaultValues: true },
  });

  const { append, fields, remove } = useFieldArray({
    control: budgetForm.control,
    name: 'expense_items',
  });

  const addMore = () => {
    append({ ...defaultExpenseItem, event_budget_id: data?.id });
  };

  const { mutate: saveExpenseItemsMutate } = useExpenseSave({
    dataHandler: () => {
      enqueueSnackbar('Your expense tracker has been updated!', {
        variant: 'success',
      });
      budgetForm.reset();
      closeDialog();
    },
    eventId,
  });

  const formState = budgetForm.formState;

  const { isDirty: isFormDirty } = formState;

  const handleCancellation = () => {
    if (isFormDirty) {
      setIsConfirming(true);
    } else {
      budgetForm.reset();
      closeDialog();
    }
  };

  return (
    <Dialog
      aria-label='add-expenses'
      fullWidth
      maxWidth='md'
      onClose={() => handleCancellation()}
      open={Boolean(isDialogOpen)}
    >
      <DialogTitle>Add expenses</DialogTitle>
      <FormProvider {...budgetForm}>
        <DialogContent>
          {fields?.map((field: any, index: number) => {
            const nameFieldState = budgetForm.getFieldState(
              `expense_items.${index}.name`,
              formState
            );

            return (
              <Stack
                alignItems='flex-start'
                direction='row'
                flexGrow={1}
                gap={2}
                gridTemplateColumns='repeat(2, 1.5fr) 1fr min-content'
                key={field.id}
                width='100%'
              >
                <TextFieldElement
                  error={
                    (!nameFieldState.isDirty && nameFieldState.isTouched) ||
                    nameFieldState.error !== undefined
                  }
                  label='Name'
                  name={`expense_items.${index}.name`}
                  placeholder='Expense name'
                  required
                  sx={{ flex: 1 }}
                />
                <EventExpenseCategoryField
                  autocompleteProps={{
                    sx: {
                      '& .MuiInputBase-root': {
                        py: '10.5px !important',
                      },
                      flex: 1,
                      minWidth: 200,
                    },
                  }}
                  label='Category'
                  matchId
                  name={`expense_items.${index}.expense_category_id`}
                  options={options}
                  textFieldProps={{
                    placeholder: 'Select category',
                  }}
                />
                <EventBudgetCurrencyField
                  control={budgetForm.control}
                  currency={currency || defaultCurrency}
                  label='Estimated cost'
                  name={
                    `expense_items.${index}.estimated_cost` as FieldPathByValueType<
                      EventBudgetFormData,
                      number | undefined
                    >
                  }
                  sx={{ maxWidth: 150 }}
                />
                <Box mt={4}>
                  <IconButton onClick={() => remove(index)}>
                    <MinusCircle />
                  </IconButton>
                </Box>
              </Stack>
            );
          })}
          <Button color='primary' onClick={addMore} startIcon={<Plus />}>
            Add more
          </Button>
        </DialogContent>
      </FormProvider>
      <DialogActions>
        <Button
          color='primary'
          data-testid='cancel'
          onClick={() => handleCancellation()}
          variant='bordered'
        >
          Cancel
        </Button>
        <Button
          color='primary'
          data-testid='submit'
          disabled={!isFormDirty}
          onClick={() => {
            budgetForm.handleSubmit((formData: EventBudgetFormData) => {
              saveExpenseItemsMutate(formData);
              budgetForm.reset();
            })();
          }}
          variant='contained'
        >
          Add expenses
        </Button>
        <DeleteModal
          custom={{
            byline:
              'Are you sure you want to continue without saving? By proceeding, any expenses entered will not be saved to your expense tracker. ',
            title: 'Close without saving?',
          }}
          onCancel={() => setIsConfirming(false)}
          onDelete={() => {
            budgetForm.reset();
            closeDialog();
            setIsConfirming(false);
          }}
          show={isConfirming}
          verb='discard'
        />
      </DialogActions>
    </Dialog>
  );
};

export default AddExpensesDialog;
