import React, { useContext, useMemo, useState } from 'react';
import { Chip, ListItem, Stack, Typography } from '@mui/material';
import * as _ from 'lodash';
import PropTypes from 'prop-types';

import DeleteModal from 'components/Core/DeleteModal';
import { ConditionalLoader } from 'components/Core/Loader';
import { InvitedCheckIcon } from 'components/Events/Controls/Invites/Invite.constants';
import { ManagerContext } from 'components/Events/Manager/ManagerContext';
import { GroupsContext } from 'contexts/GroupsContext';
import { ListsContext } from 'contexts/ListsContext';
import { OrganizationContext } from 'contexts/OrganizationContext';
import { getPersonalCalendar } from 'utils/calendar';
import { CalculateAccumulatedRecipients } from 'utils/invitations';
import { flexColumn } from 'utils/styles';

const CalendarHistory = ({ methods }) => {
  const [groups] = useContext(GroupsContext);
  const [lists] = useContext(ListsContext);
  const {
    actions: { removeCalendar },
    state: { event },
  } = useContext(ManagerContext);
  const [organization, , isLoading] = useContext(OrganizationContext);

  const [calIdToDelete, setCalToDelete] = useState(null);
  const [isDeletingCalendar, setIsDeletingCalendar] = useState(false);

  const prefix = 'Selected calendars: ';

  const history = useMemo(
    () =>
      _.chain(methods?.invitation)
        .groupBy('calendar.calendar_id')
        .map((group) => _.first(group))
        .value(),
    [methods]
  );

  const {
    invitedAllEmployees,
    invitedAllNonContacts,
    uniqueInvitedIndividualContacts,
  } = CalculateAccumulatedRecipients(
    methods,
    groups,
    lists,
    organization?.offices
  );

  const displayHistory = useMemo(
    () =>
      history?.map((method) => {
        const isPersonalCal = method?.calendar?.is_personal;
        const calendarEventId = event?.calendar_events?.find((cal) =>
          isPersonalCal
            ? cal.is_personal === true
            : cal.calendar_id === method?.calendar_id
        )?.id;

        const calendarLabel = isPersonalCal
          ? getPersonalCalendar(method.creator_employee)?.label
          : organization?.calendars?.find(
              (cal) => cal.calendar_id === method?.calendar?.calendar_id
            )?.name;

        const isRemoved = method?.calendar?.is_deleted;

        const canDelete =
          !isRemoved &&
          method?.status !== 'sending' &&
          method?.sent_at !== null;

        return Object.assign(
          {},
          { ...method },
          {
            deletable: canDelete,
            id: calendarEventId,
            label: calendarLabel,
            removed: isRemoved,
          }
        );
      }),
    [history, event?.calendar_events, organization?.calendars]
  );

  return (
    <>
      <ConditionalLoader conditions={[isLoading]}>
        <ListItem
          dense
          disableGutters
          disablePadding
          sx={{ ...flexColumn, alignItems: 'stretch', gap: 1 }}
        >
          <Stack alignItems='center' direction='row' flexWrap='wrap' gap={1}>
            <InvitedCheckIcon />
            <Typography textOverflow='ellipsis'>{prefix}</Typography>
            {displayHistory?.map((history) => (
              <Chip
                disabled={history.removed}
                key={`${history?.id}-${history?.label}`}
                label={history?.label}
                onDelete={() => {
                  setCalToDelete(history.id);
                  setIsDeletingCalendar(true);
                }}
                style={{
                  textDecoration: history.removed ? 'line-through' : 'none',
                }}
              />
            ))}
          </Stack>
          {invitedAllEmployees?.uniqueContactsCount > 0 ? (
            <Stack alignItems='center' direction='row' flexWrap='wrap' gap={1}>
              <InvitedCheckIcon />
              <Typography>{`${invitedAllEmployees?.uniqueContactsCount} selected guests:`}</Typography>
              {uniqueInvitedIndividualContacts?.length > 0 ? (
                <Chip
                  label={`Individual contacts (${uniqueInvitedIndividualContacts?.length})`}
                />
              ) : null}
              {invitedAllNonContacts?.length > 0 ? (
                <>
                  {invitedAllNonContacts?.map((item) => (
                    <Chip key={`${item.id}-${item.name}`} label={item.name} />
                  ))}
                </>
              ) : null}
            </Stack>
          ) : null}
        </ListItem>
      </ConditionalLoader>
      <DeleteModal
        noun='calendar event'
        onCancel={() => {
          setIsDeletingCalendar(false);
        }}
        onDelete={(onDone) => {
          removeCalendar(calIdToDelete, event?.id);
          setIsDeletingCalendar(false);
          onDone();
        }}
        owner={organization?.display_name}
        show={isDeletingCalendar}
        subject='your calendar event'
      />
    </>
  );
};

CalendarHistory.propTypes = {
  methods: PropTypes.object,
};

export default CalendarHistory;
