import React, { useContext, useMemo } from 'react';
import {
  Box,
  Button,
  capitalize,
  Card,
  CardActions,
  CardHeader,
  CardMedia,
  Slide,
  useTheme,
} from '@mui/material';
import {
  ArrowsClockwise,
  CircleWavyCheck,
  NotePencil,
  Trash,
} from '@phosphor-icons/react';
import PropTypes from 'prop-types';

import { ManagerContext } from 'components/Events/Manager/ManagerContext';
import { flexColumn } from 'utils/styles';

const InvitationMethodCard = ({
  alternateButton,
  byline,
  displayNoun,
  icon,
  isActive,
  isSwitchable,
  noun,
  onClick: handleClick,
  onDiscard,
  onSwitch: handleSwitch,
}) => {
  const {
    state: { event, inManagerContext },
  } = useContext(ManagerContext);

  const title = displayNoun || noun ? `${displayNoun || noun} invitations` : '';
  const theme = useTheme();

  const hasSelections = useMemo(() => {
    return !inManagerContext && isActive;
  }, [inManagerContext, isActive]);

  const isBlocked = useMemo(
    () => event?.rsvp_pause_allowed && noun !== 'email',
    [event?.rsvp_pause_allowed, noun]
  );

  return (
    <Card
      data-testid='TODO:DATA-CARD-88486'
      sx={{
        ...flexColumn,
        bgcolor: isBlocked ? 'grey.100' : 'white',
        flex: '1 1 33%',
        p: 1,
        position: 'relative',
        transition: 'all 300ms ease-in-out',
      }}
    >
      <CardMedia
        alt=''
        component='img'
        height='240'
        image={`/images/invite-${noun}.svg` || icon}
        sx={{
          my: 2,
          objectFit: 'contain',
          transition: 'all 300ms ease-in-out',
          ...(isBlocked
            ? {
                filter: 'saturate(0) opacity(0.7)',
                mixBlendMode: 'multiply',
              }
            : {}),
        }}
      />
      {hasSelections ? (
        <Box
          display='inline'
          position='absolute'
          right={24}
          sx={{ color: theme.palette.success.main }}
          top={24}
        >
          <CircleWavyCheck size={42} weight='fill' />
        </Box>
      ) : null}
      <CardHeader
        subheader={
          isBlocked
            ? `${capitalize(
                title
              )} are disabled because “Enable RSVP control” is selected.
              To send ${title}, deselect “Enable RSVP control”. `
            : byline
        }
        sx={{
          alignItems: 'flex-start',
          flexGrow: 1,
          pt: 1,
        }}
        title={capitalize(title)}
        titleTypographyProps={{
          gutterBottom: true,
          variant: 'h4',
          ...(isBlocked ? { color: 'grey.700' } : {}),
        }}
      />
      <Slide
        appear={false}
        direction='up'
        in={!isBlocked}
        mountOnEnter
        unmountOnExit
      >
        <CardActions
          sx={{
            flexDirection: { lg: 'row', xs: 'column-reverse' },
            ...(isBlocked
              ? { bottom: 0, left: 0, position: 'absolute', right: 0 }
              : {}),
          }}
        >
          {hasSelections && !alternateButton?.show ? (
            <>
              <Button
                data-testid='discard'
                disabled={isBlocked}
                fullWidth
                onClick={onDiscard}
                startIcon={<Trash />}
                variant='bordered'
              >
                Discard
              </Button>
              <Button
                disabled={isBlocked}
                fullWidth
                id='discard-button'
                onClick={() => handleClick(noun)}
                startIcon={<NotePencil />}
                variant='contained'
              >
                Edit
              </Button>
            </>
          ) : (
            <Button
              data-testid='TODO:DATA-BUTTON-72805'
              disabled={isBlocked}
              fullWidth
              onClick={() => {
                if (isSwitchable && !alternateButton?.show) {
                  handleSwitch(noun);
                } else {
                  handleClick(noun);
                }
              }}
              startIcon={
                isSwitchable && !alternateButton?.show ? (
                  <ArrowsClockwise size={20} />
                ) : null
              }
              variant={alternateButton?.show ? 'text' : 'bordered'}
            >
              {alternateButton?.show
                ? alternateButton?.copy
                : `${isSwitchable ? 'Switch to' : 'Send'} ${
                    displayNoun || noun
                  } invitations`}
            </Button>
          )}
        </CardActions>
      </Slide>
    </Card>
  );
};

InvitationMethodCard.propTypes = {
  alternateButton: PropTypes.any,
  byline: PropTypes.string,
  displayNoun: PropTypes.string,
  icon: PropTypes.any,
  isActive: PropTypes.bool,
  isSwitchable: PropTypes.bool,
  noun: PropTypes.string,
  onClick: PropTypes.func,
  onDiscard: PropTypes.func,
  onSwitch: PropTypes.func,
};

export default InvitationMethodCard;
