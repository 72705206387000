import React, { useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  capitalize,
  Card,
  CardActions,
  CardHeader,
} from '@mui/material';
import { AddressBook } from '@phosphor-icons/react';
import { plural } from 'pluralize';

import { CommunicationType } from 'components/Events/Controls/Communications/communication.types';
import CommunicationMethodMenu from 'components/Events/Controls/Communications/CommunicationMethodMenu';
import { ManagerContext } from 'components/Events/Manager/ManagerContext';
import useSelectCommunicationToSend from 'hooks/useSelectCommunicationToSend';
import { isOver } from 'utils/event';
import { flexColumn } from 'utils/styles';
import { toOxfordComma } from 'utils/text';

interface Props {
  type: CommunicationType;
}

const CommunicationTypeCard = ({ type }: Props) => {
  const {
    state: { event },
  } = useContext(ManagerContext);
  const history = useHistory();

  const { buttonLabel, id: typeId, subheader, subheaderAlt } = type;
  const { invitationMethods, messageMethods } = useSelectCommunicationToSend();

  const isInvitations = useMemo(() => typeId === 'invitation', [typeId]);
  const isForPastEvent = useMemo(() => isOver(event), [event]);

  const isDisabled = useMemo(
    () => (isInvitations ? isForPastEvent : event?.invitees_count < 1),
    [event.invitees_count, isForPastEvent, isInvitations]
  );

  const methods = useMemo(
    () => (isInvitations ? invitationMethods : messageMethods),
    [invitationMethods, messageMethods, isInvitations]
  );

  return (
    <Card
      sx={{
        ...flexColumn,
        bgcolor: isDisabled ? 'grey.100' : 'white',
        flex: '1 1 50%',
        p: 1,
      }}
      variant='outlined'
    >
      <CardHeader
        subheader={
          isDisabled && subheaderAlt
            ? subheaderAlt
            : `${[
                subheader,
                toOxfordComma(
                  methods.map((m) => m.label),
                  'or'
                ),
              ].join(' ')}.`
        }
        sx={{ alignItems: 'flex-start', flexGrow: 1, pt: 1 }}
        title={capitalize(plural(typeId || ''))}
        titleTypographyProps={{ gutterBottom: true, variant: 'h4' }}
      />
      <CardActions>
        {isDisabled ? (
          <>
            {isInvitations && (
              <Button
                fullWidth
                onClick={() => history.push(`/events/${event?.id}/guest_list`)}
                startIcon={<AddressBook />}
              >
                View guest list
              </Button>
            )}
          </>
        ) : (
          <CommunicationMethodMenu
            buttonLabel={buttonLabel}
            fullWidth
            type={type}
          />
        )}
      </CardActions>
    </Card>
  );
};

export default CommunicationTypeCard;
