import { useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { reactQueryAxios } from 'utils/axios';

const useGetEmployeesForOptions = () => {
  const queryClient = useQueryClient();

  const getOptions = async ({
    apiClient,
    type,
  }: {
    apiClient: any;
    type: string;
  }) =>
    apiClient({
      method: 'get',
      url: `/api/employees/${type}_options`,
    });

  const getGroupAdmins = useQuery({
    queryFn: () =>
      getOptions({
        apiClient: reactQueryAxios,
        type: 'group_admin',
      }).then((response: AxiosResponse) => response.data),

    queryKey: ['group_admin_options'],
  });

  const getEventHosts = useQuery({
    queryFn: () =>
      getOptions({
        apiClient: reactQueryAxios,
        type: 'event_host',
      }).then((response: AxiosResponse) => {
        const data = response.data;
        queryClient.setQueryData(['event_host_options'], data);
        return data;
      }),
    queryKey: ['event_host_options'],
  });

  return { getEventHosts, getGroupAdmins };
};

export default useGetEmployeesForOptions;
