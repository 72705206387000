import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import { NotePencil, Trash } from '@phosphor-icons/react';

import CurrencyTextField from 'components/shared/CurrencyTextField';
import { useEventBudget } from 'hooks/useEventBudget';
import Currency from 'types/Currency';

interface IEventBudgetDialogProps {
  currency?: Currency;
}

const EventBudgetDialog = ({ currency }: IEventBudgetDialogProps) => {
  const { id: eventId } = useParams<any>();

  const {
    remove,
    retrieve: { data: budgetData },
    update,
  } = useEventBudget({ eventId });

  const [isOpen, setIsOpen] = useState(false);
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
  const [pendingValue, setPendingValue] = useState(budgetData?.amount);

  const closeDialog = () => setIsOpen(false);
  const closeDeleteConfirm = () => setIsDeleteConfirmOpen(false);

  const openDialog = () => {
    setPendingValue(budgetData?.amount);
    setIsOpen(true);
  };

  const onSave = () => {
    update.mutate({ amount: Number(pendingValue) });
    closeDialog();
  };

  const onDeleteClick = () => {
    closeDialog();
    setIsDeleteConfirmOpen(true);
  };

  const onDeleteConfirm = () => {
    remove.mutate();
    closeDeleteConfirm();
  };

  return (
    <>
      {budgetData?.amount ? (
        <IconButton color='primary' onClick={() => openDialog()}>
          <NotePencil />
        </IconButton>
      ) : (
        <Button
          onClick={() => openDialog()}
          startIcon={<NotePencil />}
          sx={{ whiteSpace: 'nowrap' }}
        >
          Set event budget
        </Button>
      )}
      <Dialog fullWidth maxWidth='xs' onClose={closeDialog} open={isOpen}>
        <DialogTitle>
          {budgetData?.amount ? 'Update' : 'Set'} event budget
        </DialogTitle>
        <DialogContent>
          <CurrencyTextField
            currency={currency}
            fullWidth
            label='Event budget'
            onChange={(val) => setPendingValue(val)}
            onEnter={onSave}
            onFocus={(event) =>
              pendingValue === 0 ? event.target.select() : null
            }
            value={pendingValue}
          />
        </DialogContent>
        <DialogActions>
          {!!budgetData?.amount && Number(budgetData?.amount) > 0 && (
            <Button onClick={onDeleteClick} startIcon={<Trash />}>
              Delete
            </Button>
          )}
          <Box flexGrow={1}>&nbsp;</Box>
          <Button onClick={closeDialog} variant='outlined'>
            Cancel
          </Button>
          <Button
            onClick={onSave}
            startIcon={<NotePencil />}
            variant='contained'
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth='xs'
        onClose={closeDeleteConfirm}
        open={isDeleteConfirmOpen}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete the event budget? This action cannot
            be undone and may affect existing expense items.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteConfirm} variant='outlined'>
            Cancel
          </Button>
          <Button color='error' onClick={onDeleteConfirm} variant='contained'>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EventBudgetDialog;
