import React, { HTMLAttributes, useMemo } from 'react';
import {
  alpha,
  AutocompleteRenderOptionState,
  Checkbox,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { Icon } from '@phosphor-icons/react';
import { get } from 'lodash';

import EmployeeAvatar from 'components/shared/EmployeeAvatar';
import { toHtmlId } from 'utils/text';

interface Option {
  chip?: React.ReactNode;
  color?: string;
  Icon?: Icon;
  iconEdge?: 'start' | 'end';
  id?: string | number;
  label?: string;
  name?: string;
  secondary?: string;
  sendDate?: string;
  status?: 'invited' | 'scheduled';
  withAvatar?: boolean;
}

interface Props {
  key?: string;
  option: Option;
  props?: HTMLAttributes<HTMLLIElement>;
  state: AutocompleteRenderOptionState;
  textKey?: string;
}

const AutocompleteCheckboxOption = ({
  key,
  option,
  props,
  state,
  textKey = 'name',
}: Props) => {
  const theme = useTheme();
  const hasColor = option?.color && option?.color?.length > 0;
  const colorValue: any =
    option?.color !== undefined &&
    Object.keys(theme?.palette)?.includes(option?.color)
      ? theme?.palette[option?.color as keyof typeof theme.palette]
      : undefined;
  const OptionIconElement = option?.Icon;
  const iconEdge = option?.iconEdge || 'start';
  const OptionIcon: any = OptionIconElement
    ? (props: any) => (
        <OptionIconElement
          size={20}
          style={{ color: option?.color }}
          {...props}
        />
      )
    : undefined;
  const showCheckboxInColor = hasColor && !OptionIconElement;

  // const isSelected = useMemo(
  //   () =>
  //     state?.selected ||
  //     (!!option?.id && ownerState?.value?.includes(option?.id)),
  //   [option?.id, ownerState?.value, state?.selected]
  // );

  const optionLabel = useMemo(
    () => get(option, textKey) || option?.name || option?.label,
    [option, textKey]
  );

  return (
    <MenuItem
      {...props}
      component='li'
      disableRipple
      key={key}
      selected={state.selected}
      style={{
        paddingLeft: 4,
        ...(option?.secondary ? { alignItems: 'flex-start' } : {}),
      }}
      sx={{
        maxWidth: '100%',
        ...(showCheckboxInColor
          ? {
              '&.MuiBox-root.MuiAutocomplete-option': {
                '&.Mui-focused, &:not(.Mui-focused)': {
                  '&:focus, &:hover': {
                    backgroundColor: alpha(String(option?.color), 0.05),
                  },
                  backgroundColor: 'transparent',
                },
              },
            }
          : {
              '&.MuiBox-root.MuiAutocomplete-option': {
                '&.Mui-focused, &:not(.Mui-focused)': {
                  '&:focus, &:hover': {
                    bgcolor: 'grey.50',
                  },
                  backgroundColor: 'transparent',
                },
              },
            }),
      }}
    >
      <ListItemIcon>
        <Checkbox
          checked={state.selected}
          color='secondary'
          data-testid={`autocomplete-checkbox-option-${toHtmlId(optionLabel)}`}
          sx={{
            ...(showCheckboxInColor
              ? {
                  '&.Mui-checked': {
                    color: option?.color,
                  },
                  color: option?.color,
                }
              : {}),
            mt: option?.secondary ? '-3px' : '',
          }}
        />
      </ListItemIcon>
      {option && option?.withAvatar && option?.id ? (
        <ListItemAvatar sx={{ minWidth: 20, mr: 1 }}>
          <EmployeeAvatar
            employeeId={Number(option?.id)}
            fullName={optionLabel}
            size={20}
          />
        </ListItemAvatar>
      ) : null}
      <ListItemText
        primary={
          OptionIcon ? (
            <Stack alignItems='center' component='span' direction='row' gap={1}>
              {iconEdge === 'start' && (
                <OptionIcon
                  {...(hasColor
                    ? typeof colorValue === 'string'
                      ? { color: colorValue }
                      : colorValue && colorValue?.main
                      ? { color: colorValue?.main }
                      : {}
                    : {})}
                />
              )}
              <Typography variant='inherit'>{optionLabel}</Typography>
              {/* TODO: Refactor optionLabel to use {ownerState.getOptionLabel(option)}, update instances */}
              {iconEdge === 'end' && (
                <OptionIcon
                  {...(hasColor && Object.keys(colorValue).includes('main')
                    ? { color: colorValue?.main }
                    : {})}
                />
              )}
            </Stack>
          ) : (
            optionLabel
          )
        }
        primaryTypographyProps={{
          component: 'div',
          sx: { overflow: 'hidden', textOverflow: 'ellipsis' },
        }}
        secondary={option?.secondary || null}
        secondaryTypographyProps={
          option?.secondary ? { variant: 'caption' } : {}
        }
        sx={{ m: 0, overflow: 'hidden', p: 0 }}
      />
      {option?.chip}
    </MenuItem>
  );
};

export default AutocompleteCheckboxOption;
