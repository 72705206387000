import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import * as amplitude from '@amplitude/analytics-browser';
import { Button, Grid, Stack, Typography } from '@mui/material';
import { SignOut } from '@phosphor-icons/react';
import { useSnackbar } from 'notistack';
import pluralize from 'pluralize';

import Avatar from 'components/Core/Avatar';
import EventCards from 'components/Events/EventCards';
import EditProfileModal from 'components/Profile/EditProfileModal';
import ProfileCardGoogleCalendar from 'components/Profile/ProfileCardGoogleCalendar';
import ProfileCardZoom from 'components/Profile/ProfileCardZoom';
import InfoTooltip from 'components/shared/InfoTooltip';
import RightColumnLayout from 'components/shared/layout/RightColumnLayout';
import SurveyTemplateCards from 'components/Survey/SurveyTemplates/SurveyTemplateCards';
import { AuthContext } from 'contexts/AuthContext';
import { LayoutContext } from 'contexts/LayoutContext';
import { axiosAuthenticated as axios } from 'utils/axios';
import { fullName } from 'utils/employee';
import { ApplyFilterToChunk, useChunking } from 'utils/utils';

const Profile = () => {
  const profileEvents = useChunking('/api/users/events', 'events');
  const surveysChunk = useChunking('/api/survey_templates', 'templates');

  const {
    token: { setToken },
    userProvider: { isOrganizerOrAdmin, setUser, user },
  } = useContext(AuthContext);
  const { isMobile } = useContext(LayoutContext);
  const history = useHistory();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const [currTab, setCurrTab] = useState(
    location?.hash.length > 0 ? location.hash.replace('#', '') : 'user'
  );
  const [isEditing, setIsEditing] = useState(false);

  const mySurveysChunk = ApplyFilterToChunk(
    (survey) => survey.creator_id === user.id,
    surveysChunk
  );

  useMemo(() => {
    axios({ method: 'get', url: '/api/users/current' }, (res) => {
      setUser(res.data);
    });
  }, [setUser]);

  const removeFromChunks = (id) => {
    profileEvents.removeItemFromChunk(id);
  };

  const signOut = useCallback(() => {
    axios({ method: 'post', url: `/api/users/logout` }, () => {
      const amplitudeApiKey = import.meta.env.VITE_AMPLITUDE_API_KEY;

      history.push('/login');
      setToken(null);
      if (amplitudeApiKey != null) {
        amplitude.reset();
      }
      enqueueSnackbar('You were logged out.', { variant: 'success' });
    });
  }, [enqueueSnackbar, history, setToken]);

  const tabs = [
    {
      component: (
        <EventCards
          chunk={profileEvents}
          inProfile
          removeFromAllChunks={removeFromChunks}
          tab={currTab}
        />
      ),
      label: 'My events',
      value: 'user',
    },
    ...(isOrganizerOrAdmin
      ? [
          {
            component: (
              <SurveyTemplateCards
                chunk={mySurveysChunk}
                inProfile
                tab={currTab}
              />
            ),
            label: 'My survey templates',
            value: 'survey-templates',
          },
        ]
      : []),
  ];

  const handleTabChange = (activeTabIndex) => {
    if (tabs?.length > 0) {
      setCurrTab([...tabs][activeTabIndex]?.value);
    }
  };

  const columnContent = useMemo(
    () => (
      <>
        <Button
          color='neutral'
          data-testid='edit-profile'
          onClick={() => setIsEditing(true)}
          variant='outlined'
        >
          Edit profile
        </Button>
        <Button
          color='neutral'
          data-testid='sign-out'
          endIcon={<SignOut />}
          onClick={() => signOut()}
          variant='outlined'
        >
          Sign out
        </Button>
        <EditProfileModal
          setShow={setIsEditing}
          setUser={setUser}
          show={isEditing}
          user={user}
        />
        {isOrganizerOrAdmin && (
          <>
            <ProfileCardZoom />
            <ProfileCardGoogleCalendar />
          </>
        )}
      </>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isEditing, setUser, user]
  );

  return (
    <RightColumnLayout
      columnContent={columnContent}
      fixedContent={
        <Grid
          container
          direction='row'
          gap={2}
          item
          justifyContent='space-between'
          wrap='wrap'
          xs={12}
        >
          <Grid item xs='auto'>
            <Stack alignItems='center' direction='row' gap={2}>
              <Avatar
                data-testid='profile-avatar'
                employeeId={+user?.employee_id}
                height={isMobile ? 50 : 75}
                width={isMobile ? 50 : 75}
              >
                {user?.initials}
              </Avatar>
              <Stack>
                <Typography variant='h2' whiteSpace='nowrap'>
                  {fullName(user)}
                </Typography>
                <Typography variant='body1'>{user?.email}</Typography>
              </Stack>
            </Stack>
          </Grid>
          {isMobile ? (
            columnContent
          ) : (
            <Grid container direction='row' gap={3} item wrap='wrap' xs='auto'>
              <Grid item xs='auto'>
                <Typography fontWeight={600} variant='caption'>
                  Office
                </Typography>
                <Typography variant='body1'>
                  {user?.office?.name || 'No office assigned'}
                </Typography>
              </Grid>
              <Grid item xs='auto'>
                <Typography fontWeight={600} variant='caption'>
                  <InfoTooltip
                    content='Timezone is determined from your local system settings. Use the Date & Time settings in your operating system to change the timezone as needed.'
                    text='Timezone'
                  />
                </Typography>
                <Typography variant='body1'>
                  {Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone}
                </Typography>
              </Grid>
              <Grid item xs='auto'>
                <Typography
                  fontWeight={600}
                  variant='caption'
                  whiteSpace='nowrap'
                >
                  {`${pluralize('Event', user?.events_hosted_count)} hosted`}
                </Typography>
                <Typography variant='body1'>
                  {user?.events_hosted_count || 0}
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
      }
      fixedLocation='below-title'
      fixedSpacing={3}
      isTabsLayout
      onChange={handleTabChange}
      tabs={tabs}
      title='Profile'
    />
  );
};
export default Profile;
