import React from 'react';
import { FormLabel, Skeleton, Stack } from '@mui/material';

import AutocompletePlaceholder from 'components/shared/AutocompletePlaceholder';
import ImageField from 'components/shared/ImageField';
import {
  EventDetailsFormControl,
  EventDetailsFormCoverImageData,
  EventDetailsFormWatch,
} from 'types/EventDetailsForm';

export interface ImageDimensions {
  height?: number;
  width?: number;
  x?: number;
  y?: number;
}

export interface EventBannerImageFieldProps {
  control?: EventDetailsFormControl;
  disableFields: boolean;
  hasStarted: boolean;
  isLoading?: boolean;
  isOver: boolean;
  savedImageUrl?: string;
  watch: EventDetailsFormWatch;
}

const EventBannerImageField = ({
  control,
  disableFields,
  hasStarted,
  isLoading = false,
  isOver,
  savedImageUrl,
  watch,
}: EventBannerImageFieldProps) => {
  const coverImage = watch('coverImage') as EventDetailsFormCoverImageData;
  if (isLoading) {
    return (
      <Stack direction='column' gap={0.25}>
        <FormLabel>Event banner image</FormLabel>
        <Skeleton animation='wave' height={256} variant='rounded' />
      </Stack>
    );
  }

  return disableFields && !coverImage ? (
    <Stack direction='column' gap={0.25}>
      <FormLabel>Event banner image</FormLabel>
      <AutocompletePlaceholder items={[]} />
    </Stack>
  ) : (
    <ImageField
      control={control}
      id='event-cover-image'
      isDisabled={disableFields}
      label='Event banner image'
      name='coverImage'
      savedImageUrl={savedImageUrl}
      {...(hasStarted || isOver
        ? {
            uploadSubtext: 'No banner image uploaded',
            uploadText: `Event ${isOver ? 'ended' : 'already started'}!`,
          }
        : {
            uploadText: 'Drop an image here or click to browse your files',
          })}
    />
  );
};
export default EventBannerImageField;
