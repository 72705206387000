import React, { useContext } from 'react';
import { Autocomplete } from '@mui/material';
import PropTypes from 'prop-types';

import { ConditionalLoader } from 'components/Core/Loader';
import AutocompleteCheckboxOption from 'components/shared/AutocompleteCheckboxOption';
import { AutocompleteRenderInput } from 'components/shared/AutocompleteRenderInput';
import { ListsContext } from 'contexts/ListsContext';

const ContactListsAutocomplete = ({
  forFilter = false,
  fullWidth = false,
  isDisabled = false,
  maxTags = -1,
  onChange,
  sx = {},
  value = [],
}) => {
  const [rawOptions] = useContext(ListsContext);

  const options =
    rawOptions
      ?.sort((a, b) => a?.name?.localeCompare(b?.name))
      ?.map(({ employee_ids, ...o }) => ({
        employee_ids,
        label: o?.name,
        value: o?.id,
      })) || [];

  return (
    <ConditionalLoader conditions={[!options]}>
      <Autocomplete
        autoHighlight
        disableCloseOnSelect
        disabled={isDisabled}
        fullWidth={fullWidth}
        id='select-contact-lists'
        limitTags={maxTags}
        multiple
        onChange={onChange}
        openOnFocus
        options={options}
        renderInput={(params) => (
          <AutocompleteRenderInput
            forFilter={forFilter}
            label='Contact list'
            params={params}
            value={value}
          />
        )}
        renderOption={(props, option, state, ownerState) => (
          <AutocompleteCheckboxOption
            option={option}
            ownerState={ownerState}
            props={props}
            state={state}
          />
        )}
        slotProps={{
          popper: {
            placement: 'bottom-start',
            style: { width: 'fit-content' },
          },
        }}
        sx={sx}
        value={options?.filter((e) => value?.includes(e.value))}
      />
    </ConditionalLoader>
  );
};

ContactListsAutocomplete.propTypes = {
  forFilter: PropTypes.bool,
  fullWidth: PropTypes.bool,
  isDisabled: PropTypes.bool,
  maxTags: PropTypes.number,
  onChange: PropTypes.func,
  sx: PropTypes.object,
  value: PropTypes.array,
};

export default ContactListsAutocomplete;
