/* eslint-disable react/no-multi-comp */
import React, { useContext, useEffect, useMemo } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { useLocation } from 'react-router-dom';
import { GuardedRoute, GuardProvider } from 'react-router-guards';
import { Box } from '@mui/material';
import * as Sentry from '@sentry/react';
import { useFlag } from '@unleash/proxy-client-react';
import PropTypes from 'prop-types';

import EventGuests from 'components/Events/Controls/GuestList/EventGuests';
import EventView from 'components/Events/Controls/Shared/EventView';
import { EventWrapper } from 'components/Events/EventWrapper';
import Header from 'components/Events/Header/Header';
import {
  ManagerContext,
  ManagerProvider,
} from 'components/Events/Manager/ManagerContext';
import AnalyticsStep from 'components/Events/Steps/AnalyticsStep';
import DetailsStep from 'components/Events/Steps/DetailsStep';
import EventBudget from 'components/Events/Steps/EventBudget';
import ManageInvitations from 'components/Events/Steps/ManageInvitations';
import SurveyStep from 'components/Events/Steps/SurveyStep';
import DefaultLayout from 'components/shared/layout/DefaultLayout';
import { SlackChannelsProvider } from 'contexts/SlackChannelsContext';
import { SlackEmployeesProvider } from 'contexts/SlackEmployeesContext';
import { SyncedSlackChannelProvider } from 'contexts/SyncedSlackChannelContext';
import { isOver } from 'utils/event';

const SentryRoute = Sentry.withSentryRouting(Route);
const SentryGuardedRoute = Sentry.withSentryRouting(GuardedRoute);

export const UnpublishedRedirector = ({ children }) => {
  const {
    state: { event },
  } = useContext(ManagerContext);

  if (event?.published) {
    return children;
  } else {
    return <Redirect to={`/event/${event?.id}/create`} />;
  }
};

UnpublishedRedirector.propTypes = {
  children: PropTypes.node | PropTypes.element,
};

const FallthroughRoute = () => {
  const {
    state: { event },
  } = useContext(ManagerContext);

  if (isOver(event)) {
    return <Redirect to={`/events/${event?.id}/analytics`} />;
  } else {
    return <Redirect to={`/events/${event?.id}/edit`} />;
  }
};

const StepWrapper = ({ actions, children }) => (
  <UnpublishedRedirector>
    <DefaultLayout
      header={<Header actionsComponent={actions} isManager />}
      noPadding
      title={null}
    >
      <Box>{children}</Box>
    </DefaultLayout>
  </UnpublishedRedirector>
);

StepWrapper.propTypes = {
  actions: PropTypes.any,
  children: PropTypes.node,
};

const Routes = () => {
  const {
    actions: { setCurrentPath, setPreviousPath },
    state: { canEdit, currentPath, event, isPublic, userEmployeeId },
  } = useContext(ManagerContext);
  const location = useLocation();

  useEffect(() => {
    if (currentPath && currentPath !== location.pathname) {
      setPreviousPath(currentPath);
    }
    setCurrentPath(location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const onGuestList = useMemo(
    () =>
      userEmployeeId
        ? (event?.invitees || [])
            .map((invitee) => invitee?.employee_id)
            .includes(userEmployeeId)
        : false,
    [userEmployeeId, event]
  );

  const routeGuard = (to, from, next) => {
    if (canEdit) {
      next();
    } else if (isPublic || (!isPublic && onGuestList)) {
      next.redirect(`/events/${event?.id}/view`);
    } else {
      next.redirect('/events');
    }
  };

  const isCommsFlagEnabled = useFlag('event-communications');

  const isBudgetFlagEnabled = useFlag('event-budget');

  const eventInvitationPath = `/events/:id/${
    isCommsFlagEnabled
      ? 'communications/:type?/:method?/:commId?'
      : 'invitations'
  }`;

  return (
    <GuardProvider guards={[routeGuard]} ignoreGlobal>
      <Switch data-testid='TODO:DATA-SWITCH-93993'>
        <SentryGuardedRoute exact path='/events/:id/edit'>
          <UnpublishedRedirector>
            {/* <StepWrapper messageSubject='event detail'>*/}
            <DetailsStep />
            {/* </StepWrapper>*/}
          </UnpublishedRedirector>
        </SentryGuardedRoute>
        <SentryGuardedRoute exact path='/events/:id/surveys'>
          <StepWrapper>
            <SurveyStep />
          </StepWrapper>
        </SentryGuardedRoute>
        <SentryGuardedRoute path={eventInvitationPath}>
          <StepWrapper messageSubject='invites'>
            <ManageInvitations />
          </StepWrapper>
        </SentryGuardedRoute>
        <SentryGuardedRoute exact path='/events/:id/guest_list'>
          <StepWrapper messageSubject='guest list'>
            <EventGuests />
          </StepWrapper>
        </SentryGuardedRoute>
        <SentryRoute exact path='/events/:id/view'>
          <EventView />
        </SentryRoute>
        <SentryGuardedRoute exact path='/events/:id/analytics'>
          <StepWrapper>
            <AnalyticsStep />
          </StepWrapper>
        </SentryGuardedRoute>
        {isBudgetFlagEnabled && (
          <SentryGuardedRoute exact path='/events/:id/budget'>
            <StepWrapper>
              <EventBudget />
            </StepWrapper>
          </SentryGuardedRoute>
        )}
        <SentryGuardedRoute exact path='/events/:id'>
          <FallthroughRoute />
        </SentryGuardedRoute>
      </Switch>
    </GuardProvider>
  );
};

const EventManager = () => (
  <SlackEmployeesProvider>
    <SlackChannelsProvider>
      <SyncedSlackChannelProvider>
        <ManagerProvider>
          <EventWrapper>
            <Routes />
          </EventWrapper>
        </ManagerProvider>
      </SyncedSlackChannelProvider>
    </SlackChannelsProvider>
  </SlackEmployeesProvider>
);
export default EventManager;
