import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormGroup,
  FormLabel,
  Link,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { Plus } from '@phosphor-icons/react';
import PropTypes from 'prop-types';

import { ConditionalLoader, InlineLoader } from 'components/Core/Loader';
import RadioSelect from 'components/Core/RadioSelect';
import { AuthContext } from 'contexts/AuthContext';
import { SurveyTemplatesContext } from 'contexts/SurveyTemplatesContext';
import { axiosAuthenticated as axios } from 'utils/axios';
import { DefaultOptions, DefaultQuestion } from 'utils/survey';

const CreateSurveyTemplateButton = ({
  handleErrorsDisplay,
  inEditor,
  showAsLink = false,
  style,
}) => {
  const {
    actions: {
      applyMulti,
      applySingle,
      saveSurveyTemplate,
      setSavedSurveyTemplate,
      setShowErrors,
    },
    state: { showErrors, surveyErrors },
  } = useContext(SurveyTemplatesContext);
  const {
    userProvider: { user },
  } = useContext(AuthContext);

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setIsOpen] = useState(false);
  const [isWorking] = useState(false);
  const [loading, setLoader] = useState(false);
  const [surveyTemplateName, setSurveyTemplateName] = useState('');
  const [surveyTemplatePrivacy, setSurveyTemplatePrivacy] = useState(true);
  const [surveyTemplateStyle, setSurveyTemplateStyle] = useState(
    style || SurveyStyles[0].value
  );

  const onHide = () => {
    setIsOpen(false);
  };

  const createSurveyTemplate = () => {
    setLoader(true);
    axios(
      {
        data: {
          creator_id: user.id,
          name: surveyTemplateName,
          privacy: surveyTemplatePrivacy,
          style: surveyTemplateStyle,
        },
        method: 'post',
        url: `/api/survey_templates`,
      },
      (res) => {
        const { data } = res;
        setSavedSurveyTemplate(data);
        if (data.style === 'post') {
          const addNpsQuestion = [
            DefaultQuestion('post_survey', 0),
            `surveys.post_event_survey.survey_questions`,
          ];
          const addNpsOptions = [
            DefaultOptions(0, 0, 'nps'),
            `surveys.post_event_survey.survey_options`,
          ];
          const addTypeToContext = [data.style, 'style'];
          applyMulti([addNpsQuestion, addNpsOptions, addTypeToContext]);
        } else {
          applySingle(data.style, 'style');
        }
        setLoader(false);
        enqueueSnackbar(`'${surveyTemplateName}' survey created!`, {
          variant: 'success',
        });
        history.push(`/surveys/${data.id}/edit`);
      },
      () => {
        setLoader(false);
        enqueueSnackbar('Something went wrong.', { variant: 'error' });
      }
    );
  };

  const handleOpen = () => {
    setShowErrors(false);
    setIsOpen(true);
  };

  const handleOpenInEditor = () => {
    if (surveyErrors?.length > 0) {
      handleErrorsDisplay();
    } else {
      setSurveyTemplateStyle(style);
      applySingle(style, 'template.style');
      setIsOpen(true);
      setShowErrors(false);
    }
  };

  const handleTemplateName = (value) => {
    if (value.replace(/<(.|\n)*?>/g, '').trim().length > 0) {
      setSurveyTemplateName(value);
      inEditor && applySingle(value, 'template.name');
    } else {
      setSurveyTemplateName('');
    }
  };

  const handleTemplatePrivacy = () => {
    setSurveyTemplatePrivacy(!surveyTemplatePrivacy);
    if (inEditor) {
      applySingle(!surveyTemplatePrivacy, 'template.privacy');
    }
  };

  const handleCreateTemplate = () => {
    if (!surveyTemplateStyle) {
      setShowErrors(true);
      return;
    }

    if (inEditor) {
      saveSurveyTemplate();
      setIsOpen(false);
      setSurveyTemplateName('');
    } else {
      createSurveyTemplate();
    }
  };

  const handleStyleChange = (e) => {
    setShowErrors(false);
    setSurveyTemplateStyle(e);
  };

  return (
    <>
      {inEditor ? (
        <Button
          data-testid='save-as-template'
          onClick={handleOpenInEditor}
          size='small'
          variant='text'
        >
          Save as template
        </Button>
      ) : showAsLink ? (
        <Link
          color='primary'
          data-testid='create-new-template'
          onClick={handleOpen}
          sx={{ cursor: 'pointer' }}
        >
          Create new template
        </Link>
      ) : (
        <Button
          color='primary'
          data-testid='new-survey-template'
          onClick={handleOpen}
          startIcon={<Plus />}
          variant='contained'
        >
          New survey template
        </Button>
      )}
      <Dialog aria-label='Create' onClose={onHide} open={isOpen}>
        <DialogTitle>Create a survey template</DialogTitle>
        <DialogContent>
          {isWorking && <InlineLoader />}
          <ConditionalLoader conditions={[loading]}>
            <FormGroup>
              <TextField
                error={showErrors && !surveyTemplateName}
                field='survey_template_name'
                fullWidth
                helperText={
                  showErrors && !surveyTemplateName ? 'Name is required' : null
                }
                id='survey_template_name'
                label='Name'
                onBlur={() => (!inEditor ? setShowErrors(true) : null)}
                onChange={(e) => handleTemplateName(e.target.value)}
                placeholder='Social Event Feedback'
                required
                value={surveyTemplateName}
              />
            </FormGroup>
            {!inEditor && (
              <FormControl>
                <FormLabel>Type</FormLabel>
                <ToggleButtonGroup
                  exclusive
                  onChange={(e, v) => handleStyleChange(v)}
                  value={surveyTemplateStyle}
                >
                  {SurveyStyles.map((opt) => (
                    <ToggleButton
                      data-testid='TODO:DATA-TOGGLEBUTTON-41188'
                      key={opt.id}
                      value={opt.value}
                    >
                      {opt.name}
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
              </FormControl>
            )}
            <FormGroup>
              <RadioSelect
                label='Visibility'
                onChange={handleTemplatePrivacy}
                options={[
                  {
                    label: {
                      primary: 'Private',
                      secondary: 'Only me',
                    },
                    value: true,
                  },
                  {
                    label: {
                      primary: 'Shared',
                      secondary: 'Any organizer within the company',
                    },
                    value: false,
                  },
                ]}
                value={surveyTemplatePrivacy}
              />
            </FormGroup>
          </ConditionalLoader>
        </DialogContent>
        <DialogActions>
          <Button
            color='primary'
            data-testid='TODO:DATA-BUTTON-40606'
            disabled={surveyTemplateName?.length === 0}
            fullWidth
            onClick={handleCreateTemplate}
            variant='contained'
          >
            {inEditor
              ? 'Save survey template'
              : 'Create survey template & start building'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

CreateSurveyTemplateButton.propTypes = {
  handleErrorsDisplay: PropTypes.func,
  inEditor: PropTypes.bool,
  showAsLink: PropTypes.bool,
  style: PropTypes.string,
};

export default CreateSurveyTemplateButton;

const SurveyStyles = [
  {
    id: 0,
    name: 'Pre-event survey',
    value: 'pre',
  },
  {
    id: 1,
    name: 'Post-event survey',
    value: 'post',
  },
];
