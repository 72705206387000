import React, { useContext } from 'react';
import {
  Alert,
  AlertTitle,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import * as _ from 'lodash';
import PropTypes from 'prop-types';

import SurveyAnonymitySubheader from 'components/Events/Controls/Shared/SurveyAnonymitySubheader';
import SurveyQuestionList from 'components/Events/Controls/Shared/SurveyQuestionList';
import { ManagerContext } from 'components/Events/Manager/ManagerContext';
import RichTextDisplay from 'components/shared/RichTextDisplay';
import StandaloneSurvey from 'components/Survey/StandaloneSurvey';

const SurveyPreview = ({
  canEdit,
  cloneSurveyTemplate,
  displayResponders,
  handleClose,
  show,
  template,
  type,
  viewTemplate = false,
}) => {
  return viewTemplate ? (
    <SurveyTemplatePreview
      canEdit={canEdit}
      cloneSurveyTemplate={cloneSurveyTemplate}
      handleClose={handleClose}
      show={show}
      template={template}
      type={type}
    />
  ) : type === 'pre_event_survey' ? (
    <PreEventSurveyPreview
      displayResponders={displayResponders}
      handleClose={handleClose}
      show={show}
      type={type}
      viewTemplate={viewTemplate}
    />
  ) : (
    <PostEventSurveyPreview
      displayResponders={displayResponders}
      handleClose={handleClose}
      show={show}
      type={type}
      viewTemplate={viewTemplate}
    />
  );
};

SurveyPreview.propTypes = {
  canEdit: PropTypes.bool,
  cloneSurveyTemplate: PropTypes.any,
  handleClose: PropTypes.func,
  show: PropTypes.any,
  template: PropTypes.any,
  type: PropTypes.string,
  viewTemplate: PropTypes.bool,
};

export default SurveyPreview;

const PreEventSurveyPreview = ({
  displayResponders,
  handleClose,
  show,
  type,
}) => {
  const {
    state: { surveys },
  } = useContext(ManagerContext);

  return (
    <Dialog
      aria-label='Preview'
      disableEnforceFocus
      onClose={handleClose}
      open={show}
    >
      <DialogTitle>
        <Typography variant='body2'>
          Please submit this survey before the event!
        </Typography>
        <SurveyAnonymitySubheader displayResponders={displayResponders} />
        <RichTextDisplay
          data-testid='survey-description'
          text={_.get(surveys, `${type}.description`)}
        />
      </DialogTitle>
      <DialogContent sx={{ px: 3, py: 0 }}>
        <SurveyQuestionList type={type} />
      </DialogContent>
      <DialogActions>
        <Button data-testid='close' onClick={handleClose} variant='outlined'>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

PreEventSurveyPreview.propTypes = {
  handleClose: PropTypes.func,
  show: PropTypes.any,
  type: PropTypes.any,
};

const PostEventSurveyPreview = ({ displayResponders, handleClose, show }) => {
  return (
    <Dialog
      aria-label='Preview'
      disableEnforceFocus
      fullWidth
      maxWidth='lg'
      onClose={handleClose}
      open={show}
    >
      <DialogTitle>Post-event survey preview</DialogTitle>
      <DialogContent>
        <StandaloneSurvey
          displayResponders={displayResponders}
          inDialog
          type='post_event_survey'
        />
      </DialogContent>
    </Dialog>
  );
};

PostEventSurveyPreview.propTypes = {
  handleClose: PropTypes.func,
  show: PropTypes.any,
};

const SurveyTemplatePreview = ({
  canEdit,
  cloneSurveyTemplate,
  handleClose,
  show,
  template,
  type,
}) => {
  const sortedQuestions = template?.survey_questions?.sort(
    (a, b) => _.toNumber(a.position) - _.toNumber(b.position)
  );

  return (
    <Dialog
      aria-label='Preview'
      disableEnforceFocus
      fullWidth
      maxWidth='md'
      onClose={handleClose}
      open={show}
    >
      <DialogTitle>{template.name} survey preview</DialogTitle>
      <DialogContent>
        {canEdit ? null : (
          <Alert
            data-testid='if-you’d-like-to-edit-it,-please-click-“duplicate-survey”.'
            severity='warning'
            sx={{ mb: 1 }}
          >
            <AlertTitle>
              Since you are not the creator of this survey template, this is
              only a preview.
            </AlertTitle>
            If you&rsquo;d like to edit it, please click &ldquo;Duplicate
            survey&rdquo;.
          </Alert>
        )}
        <SurveyQuestionList
          canSeePreSurvey
          options={template?.survey_options}
          questions={sortedQuestions}
          showSummaries
          type={type}
        />
      </DialogContent>
      <DialogActions>
        <Button
          data-testid='close-preview'
          onClick={handleClose}
          variant='outlined'
        >
          Close preview
        </Button>
        {canEdit ? null : (
          <Button
            color='primary'
            data-testid='duplicate-survey-template'
            onClick={() => cloneSurveyTemplate(template.id)}
            type='submit'
            variant='contained'
          >
            Duplicate survey template
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

SurveyTemplatePreview.propTypes = {
  canEdit: PropTypes.bool,
  cloneSurveyTemplate: PropTypes.func,
  handleClose: PropTypes.func,
  show: PropTypes.any,
  template: PropTypes.shape({
    id: PropTypes.any,
    name: PropTypes.string,
    survey_options: PropTypes.any,
    survey_questions: PropTypes.array,
  }),
};
