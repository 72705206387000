import React, { useCallback, useContext, useMemo, useState } from 'react';
import {
  Button,
  Collapse,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
} from '@mui/material';
import {
  PencilSimple,
  Trash,
  UploadSimple,
  UserPlus,
} from '@phosphor-icons/react';
import dayjs from 'dayjs';
import {
  type MRT_ColumnDef,
  type MRT_ColumnFiltersState,
  type MRT_SortingState,
  LiteralUnion,
  MaterialReactTable,
  MRT_EditActionButtons,
  MRT_PaginationState,
  MRT_Row,
  MRT_RowSelectionState,
  MRT_TableInstance,
  MRT_Updater,
  useMaterialReactTable,
} from 'material-react-table';

import CSVUploadModal from 'components/Core/CSVUploadModal';
import DeleteModal from 'components/Core/DeleteModal';
import MRTToolbarAlertBannerContent from 'components/shared/MRTToolbarAlertBannerContent';
import MRTTopToolbarWrapper from 'components/shared/MRTTopToolbarWrapper';
import OfficesAutocomplete from 'components/shared/OfficesAutocomplete';
import {
  getSelectableMRTRowProps,
  getServerSideMRTOptions,
} from 'constants/table.constants';
import { OrganizationContext } from 'contexts/OrganizationContext';
import { useEmployees } from 'hooks/useEmployees';
import { Employee } from 'types/Employee';

const validateRequired = (value: string) => !!value.length;
const validateEmail = (email: string) =>
  !!email.length &&
  email
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

const validateEmployee = (employee: Omit<Employee, 'id'>) => ({
  email: !validateRequired(employee.email)
    ? 'Email is required'
    : !validateEmail(employee.email)
    ? 'Incorrect email format'
    : '',
  firstName: !validateRequired(String(employee.first_name))
    ? 'First name is required'
    : '',
  lastName: !validateRequired(String(employee.last_name))
    ? 'Last name is required'
    : '',
});

export interface Contact extends Employee {
  created_at: string;
  office_id?: number;
  role_type: string;
}

const ContactsTable = () => {
  const [organization] = useContext(OrganizationContext);

  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    []
  );
  const [deletingRow, setDeletingRow] = useState<MRT_Row<Contact> | undefined>(
    undefined
  );
  const [deletingRows, setDeletingRows] = useState<number[] | undefined>(
    undefined
  );
  const [globalFilter, setGlobalFilter] = useState('');
  const [isUploadingCsv, setIsUploadingCsv] = useState(false);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [validationErrors, setValidationErrors] = useState<
    Record<string, string | undefined>
  >({});

  const {
    create,
    remove,
    removeMultiple,
    retrieveAll: { data, isPending, isRefetching },
    update,
  } = useEmployees({
    columnFilters,
    globalFilter,
    pagination,
    sorting,
  });

  const handleFilterChange = (id: string, newValue: any) => {
    if (pagination?.pageIndex !== 0) {
      setPagination((prev) => ({ ...prev, pageIndex: 0 }));
    }
    const updatedFilters = [...columnFilters] || [];
    const filterIndex = columnFilters?.findIndex((f) => f?.id === id);
    if (filterIndex === -1) {
      setColumnFilters([...updatedFilters, { id, value: newValue }]);
    } else {
      updatedFilters[filterIndex].value = newValue;
      setColumnFilters(updatedFilters.filter((f: any) => f?.value?.length > 0));
    }
  };

  const handleGlobalFilterChange = (updater: MRT_Updater<any>) => {
    setGlobalFilter((prevGlobalFilter) =>
      updater instanceof Function ? updater(prevGlobalFilter) : updater
    );
    if (pagination.pageIndex !== 0) {
      setPagination((prev) => ({ ...prev, pageIndex: 0 }));
    }
  };

  const offices = useMemo(
    () =>
      organization?.offices?.map(
        ({ id, name }: { id: number; name: string }) => ({
          label: name,
          value: id,
        })
      ) || [],
    [organization]
  );

  const columns = useMemo<MRT_ColumnDef<Contact>[]>(
    () => [
      {
        accessorKey: 'first_name',
        header: 'First name',
        muiEditTextFieldProps: {
          error: !!validationErrors?.firstName,
          helperText: validationErrors?.firstName,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              firstName: undefined,
            }),
          placeholder: 'Alex',
          required: true,
        },
        size: 130,
      },
      {
        accessorKey: 'last_name',
        header: 'Last name',
        muiEditTextFieldProps: {
          error: !!validationErrors?.lastName,
          helperText: validationErrors?.lastName,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              lastName: undefined,
            }),
          placeholder: 'Miller',
          required: true,
        },
        size: 160,
      },
      {
        accessorKey: 'email',
        enableColumnFilter: false,
        header: 'Email',
        muiEditTextFieldProps: {
          error: !!validationErrors?.email,
          helperText: validationErrors?.email,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              email: undefined,
            }),
          placeholder: `alex.miller@${
            organization?.email_extensions?.length > 0
              ? organization?.email_extensions[0].domain
              : 'email.com'
          }`,
          required: true,
        },
      },
      {
        accessorFn: (row) => dayjs(row?.created_at)?.format('MM/DD/YYYY'),
        accessorKey: 'created_at',
        header: 'Joined',
        muiEditTextFieldProps: {
          sx: {
            display: 'none',
          },
        },
        size: 120,
      },
      {
        accessorFn: (originalRow) =>
          originalRow?.office_id
            ? organization?.offices?.find(
                (o: any) => o?.id === originalRow?.office_id
              )?.name
            : '',
        accessorKey: 'office_id',
        editSelectOptions: offices,
        editVariant: 'select',
        filterSelectOptions: offices,
        filterVariant: 'multi-select',
        header: 'Office',
        size: 150,
      },
      {
        accessorKey: 'role_type',
        enableColumnFilter: false,
        enableEditing: false,
        header: 'Role',
        id: 'role_type',
        muiEditTextFieldProps: {
          sx: {
            display: 'none',
          },
        },
        size: 120,
      },
    ],
    [
      offices,
      organization?.email_extensions,
      organization?.offices,
      validationErrors,
    ]
  );

  const hasSelectedRows = useCallback(
    (table: MRT_TableInstance<Contact>) =>
      table?.getState().rowSelection &&
      Object.values(table?.getState().rowSelection).length > 0,
    []
  );

  const defaultOptions = getServerSideMRTOptions<Contact>(
    `calc(100vh - 300px)`,
    true
  );

  const table = useMaterialReactTable({
    ...defaultOptions,
    columnFilterDisplayMode: 'custom',
    columns,
    createDisplayMode: 'modal',
    data: data?.data || [],
    editDisplayMode: 'modal',
    enableEditing: true,
    enableRowActions: true,
    enableRowSelection: true,
    enableStickyFooter: true,
    enableStickyHeader: true,
    muiTableBodyRowProps: ({ row, staticRowIndex, table }) =>
      getSelectableMRTRowProps({ row, staticRowIndex, table }),
    onCreatingRowCancel: ({ table }) => {
      table.resetRowSelection();
      setValidationErrors({});
    },
    onCreatingRowSave: ({
      exitCreatingMode,
      row,
      values,
    }: {
      exitCreatingMode: () => void;
      row: MRT_Row<Contact>;
      table: MRT_TableInstance<Contact>;
      values: Record<LiteralUnion<string, string>, any>;
    }) => {
      const newValidationErrors = validateEmployee(values);
      if (Object.values(newValidationErrors).some((error) => error)) {
        setValidationErrors(newValidationErrors);
        return;
      }
      setValidationErrors({});
      create.mutateAsync({ ...values, id: row.id }).then(() => {
        exitCreatingMode();
      });
    },
    onEditingRowCancel: ({ table }) => {
      table.resetRowSelection();
      setValidationErrors({});
    },
    onEditingRowSave: ({
      exitEditingMode,
      row,
      values,
    }: {
      exitEditingMode: () => void;
      row: MRT_Row<Contact>;
      table: MRT_TableInstance<Contact>;
      values: Record<LiteralUnion<string, string>, any>;
    }) => {
      const newValidationErrors = validateEmployee(values);
      if (Object.values(newValidationErrors).some((error) => error)) {
        setValidationErrors(newValidationErrors);
        return;
      }
      setValidationErrors({});
      update.mutateAsync({ ...values, id: row.id }).then(() => {
        exitEditingMode();
        table.resetRowSelection();
      });
    },
    onGlobalFilterChange: handleGlobalFilterChange,
    onPaginationChange: setPagination,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    positionActionsColumn: 'last',
    renderCreateRowDialogContent: ({ internalEditComponents, row, table }) => (
      <>
        <DialogTitle variant='h3'>Create contact</DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}
        >
          {internalEditComponents}
        </DialogContent>
        <DialogActions>
          {/* eslint-disable-next-line react/jsx-pascal-case */}
          <MRT_EditActionButtons row={row} table={table} variant='text' />
        </DialogActions>
      </>
    ),
    renderEditRowDialogContent: ({ internalEditComponents, row, table }) => (
      <>
        <DialogTitle variant='h3'>Edit contact</DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}
        >
          {internalEditComponents}
        </DialogContent>
        <DialogActions>
          {/* eslint-disable-next-line react/jsx-pascal-case */}
          <MRT_EditActionButtons row={row} table={table} variant='text' />
        </DialogActions>
      </>
    ),
    renderRowActions: ({ row, table }) => [
      <IconButton key='edit' onClick={() => table.setEditingRow(row)}>
        <PencilSimple />
      </IconButton>,
      <IconButton key='delete' onClick={() => setDeletingRow(row)}>
        <Trash />
      </IconButton>,
    ],
    renderToolbarAlertBannerContent: ({ table }) => (
      <MRTToolbarAlertBannerContent table={table} />
    ),
    renderTopToolbar: ({ table }) => (
      <MRTTopToolbarWrapper
        nounPlural='contacts'
        table={table}
        wrapperSxProps={{
          alignItems: 'center',
          bgcolor: 'transparent',
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          gap: 1,
          justifyContent: 'flex-start',
        }}
      >
        <>
          <OfficesAutocomplete
            forFilter
            onChange={(e, newValues) =>
              handleFilterChange(
                'office_id',
                newValues?.map((v: any) => v.value)
              )
            }
            sx={{ minWidth: 200 }}
            value={
              (columnFilters?.find((f) => f.id === 'office_id')
                ?.value as any[]) || []
            }
          />
          <Stack direction='row' flex={1} gap={1} justifyContent='flex-end'>
            <Collapse
              in={
                hasSelectedRows(table) &&
                !(table?.getState().editingRow || deletingRow)
              }
              orientation='horizontal'
            >
              <Button
                color='error'
                onClick={() =>
                  setDeletingRows(
                    Object.keys(table?.getState().rowSelection).map((k) =>
                      Number(k)
                    )
                  )
                }
                startIcon={<Trash />}
                sx={{ whiteSpace: 'nowrap' }}
                variant='contained'
              >
                Delete selected
              </Button>
            </Collapse>
            <Button
              onClick={() => setIsUploadingCsv(true)}
              startIcon={<UploadSimple />}
              sx={{ whiteSpace: 'nowrap' }}
              variant='contained'
            >
              Upload contacts
            </Button>
            <Button
              onClick={() => table.setCreatingRow(true)}
              startIcon={<UserPlus />}
              sx={{ whiteSpace: 'nowrap' }}
              variant='contained'
            >
              Add contact
            </Button>
          </Stack>
        </>
      </MRTTopToolbarWrapper>
    ),
    rowCount: data?.meta?.total_count ?? 0,
    state: {
      columnFilters,
      columnPinning: {
        left: ['mrt-row-select', 'first_name', 'last_name'],
      },
      globalFilter,
      isLoading: isPending,
      pagination,
      rowSelection,
      showProgressBars: isRefetching,
      sorting,
    },
  });

  return (
    <>
      <MaterialReactTable table={table} />
      <CSVUploadModal
        onClose={() => setIsUploadingCsv(false)}
        show={isUploadingCsv}
      />
      <DeleteModal
        isPerson
        noun='Contact'
        onCancel={() => {
          if (deletingRow) {
            table?.resetRowSelection();
          }
          setDeletingRow(undefined);
          setDeletingRows(undefined);
        }}
        onDelete={(done: () => unknown) =>
          deletingRows && deletingRows?.length > 0
            ? removeMultiple.mutate({
                done: () => {
                  table?.resetRowSelection();
                  done();
                  setDeletingRows(undefined);
                },
                ids:
                  deletingRows && deletingRows?.length > 0 ? deletingRows : [],
              })
            : deletingRow &&
              remove.mutate({
                done: () => {
                  table?.resetRowSelection();
                  done();
                  setDeletingRow(undefined);
                },
                employeeRow: deletingRow,
              })
        }
        owner={(organization && organization?.display_name) || 'Five to Nine'}
        plural={deletingRows && deletingRows?.length > 0}
        show={!!deletingRow || (deletingRows && deletingRows?.length > 0)}
        subject={
          deletingRow
            ? [
                deletingRow?.getValue('first_name'),
                deletingRow?.getValue('last_name'),
              ].join(' ')
            : deletingRows && deletingRows?.length > 0
            ? 'selected contacts'
            : ''
        }
      />
    </>
  );
};

export default ContactsTable;
