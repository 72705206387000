import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import axios from 'axios';
import { useSnackbar } from 'notistack';

import OnboardFrame from 'components/Onboard/OnboardFrame';
import PasswordStrength from 'components/Onboard/PasswordStrength';
import PasswordTextField from 'components/shared/PasswordTextField';

const ChangePassword = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [token, setToken] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setToken(params.get('token'));
  }, []);

  const changePassword = () => {
    setIsLoading(true);
    axios
      .post(`${import.meta.env.VITE_BE_URL}/api/users/change_password`, {
        user: { password, password_confirmation: confirmPassword, token },
      })
      .then(() => {
        setIsLoading(false);
        enqueueSnackbar('Your password was updated', {
          variant: 'success',
        });
        history.push('/login');
      })
      .catch((res) => {
        const message = res?.response?.data?.errors;
        setIsLoading(false);
        enqueueSnackbar(`Something went wrong! ${message}`, {
          variant: 'error',
        });
      });
  };

  return (
    <OnboardFrame
      conditions={[isLoading]}
      data-testid='change-password-container'
      title='Change password'
    >
      <form data-testid='change-password-form' onSubmit={changePassword}>
        <PasswordTextField
          autoComplete='new-password'
          handleChange={(e) => setPassword(e.target.value)}
          label='New password'
          placeholder='Add your new password here'
        />
        <Box py={1}>
          <PasswordStrength password={password} show />
        </Box>
        <PasswordTextField
          autoComplete='new-password'
          handleChange={(e) => setConfirmPassword(e.target.value)}
          label='Confirm new password'
          placeholder='Type your new password again'
        />
        <Button
          color='secondary'
          data-testid='change-password'
          onClick={changePassword}
          sx={{ mt: 2 }}
          variant='contained'
        >
          Change password
        </Button>
      </form>
    </OnboardFrame>
  );
};

export default ChangePassword;
