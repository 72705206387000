import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Stack, TextField } from '@mui/material';
import axios from 'axios';
import { useSnackbar } from 'notistack';

import OnboardFrame from 'components/Onboard/OnboardFrame';
import { useExternalSnackbarProps } from 'utils/snackbar';
import { ValidateEmail } from 'utils/utils';

const ResetPassword = () => {
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const snackbarProps = useExternalSnackbarProps();

  const showSnackbar = (text, variant) => {
    enqueueSnackbar(text, { ...snackbarProps, variant });
  };

  const validEmail = useMemo(() => ValidateEmail(email), [email]);

  const goToLogin = () => history.push('/login');

  const resetPassword = (ev) => {
    ev.preventDefault();
    if (!validEmail) {
      return showSnackbar('You must enter a valid email address', 'error');
    } else {
      setIsLoading(true);
      axios
        .post(`${import.meta.env.VITE_BE_URL}/api/users/reset_password`, {
          user: { email, origin_url: window.location.origin },
        })
        .then(() => {
          setIsLoading(false);
          showSnackbar(
            `If we find your account, we'll send you a reset link via email — go check it!`,
            'success'
          );
          goToLogin();
        })
        .catch(() => {
          setIsLoading(false);
          showSnackbar('Something went wrong! Please try again', 'error');
        });
    }
  };

  return (
    <OnboardFrame
      conditions={[isLoading]}
      data-testid='reset-password-container'
      subtitle="No worries, we'll email you a link to reset it."
      title='Forgot your password?'
    >
      <form data-testid='sign-in-form' onSubmit={resetPassword}>
        <TextField
          autoComplete='email'
          fullWidth
          id='add-email'
          label='Email'
          onChange={(e) => setEmail(e.target.value)}
          placeholder='you@yourcompany.com'
          required
          sx={{ mb: 3, mt: 0 }}
          type='email'
          value={email}
        />
        <Stack direction='column' spacing={1}>
          <Button
            color='secondary'
            data-testid='send-email'
            fullWidth
            onClick={(e) => resetPassword(e)}
            variant='contained'
          >
            Send email
          </Button>
          <Button
            color='secondary'
            data-testid='never-mind'
            fullWidth
            onClick={() => goToLogin()}
            variant='text'
          >
            Never mind
          </Button>
        </Stack>
      </form>
    </OnboardFrame>
  );
};

export default ResetPassword;
