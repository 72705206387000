import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Palette,
  PaletteColor,
  useTheme,
} from '@mui/material';
import { DotsThreeVertical } from '@phosphor-icons/react';
import { MRT_Row, MRT_TableInstance } from 'material-react-table';

import {
  CommunicationMethodId,
  CommunicationStatusId,
  CommunicationTypeId,
} from 'components/Events/Controls/Communications/communication.types';
import DiscardCommunicationConfirmation from 'components/Events/Controls/Communications/DiscardCommunicationConfirmation';
import SendNowConfirmation from 'components/Events/Controls/Communications/SendNowConfirmation';
import { ManagerContext } from 'components/Events/Manager/ManagerContext';
import Actions from 'constants/action.constants';
import { useCommunicationDelete } from 'hooks/useCommunicationDelete';
import { useCommunicationSendNow } from 'hooks/useCommunicationSendNow';
import useCopyToClipboard from 'hooks/useCopyToClipboard';
import useSelectCommunicationToSend from 'hooks/useSelectCommunicationToSend';
import Action, { ActionId } from 'types/Action';
import { getEventLink } from 'utils/event';

const CommunicationsRowActions = ({
  handleChange,
  params,
}: {
  handleChange: () => void;
  params: {
    row: MRT_Row<any>;
    table: MRT_TableInstance<any>;
  };
}) => {
  const {
    state: { event },
  } = useContext(ManagerContext);
  const { copy } = useCopyToClipboard();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const eventId = Number(id);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isSending, setIsSending] = useState(false);

  const theme = useTheme();

  const {
    isPending: isPendingDelete,
    isSuccess: isDeleted,
    mutate: deleteCommunication,
  } = useCommunicationDelete({
    dataHandler: () => {
      handleChange();
      setIsDeleting(false);
    },
    eventId,
  });

  const {
    isPending: isPendingSendNow,
    isSuccess: isSent,
    mutate: sendNow,
  } = useCommunicationSendNow({
    dataHandler: () => {
      handleChange();
      setIsSending(false);
    },
    eventId,
  });

  const { select } = useSelectCommunicationToSend();

  const rowValues = useMemo(() => params?.row?.original || {}, [params]);

  const methods = rowValues?.deliveryMethods as CommunicationMethodId[];
  const type = rowValues?.historyType as CommunicationTypeId;
  const status = rowValues?.status as CommunicationStatusId;
  const pendingActions: ActionId[] = useMemo(
    () => ['edit', 'delete', type === 'survey' ? 'copyLink' : 'send'],
    [type]
  );

  const availableActions: ActionId[] = useMemo(
    () =>
      ['drafted', 'scheduled'].includes(status)
        ? pendingActions
        : status === 'reschedule'
        ? [...pendingActions].filter((a) => a !== 'send')
        : [],
    [pendingActions, status]
  );

  const openMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
    setIsMenuOpen((previousOpen) => !previousOpen);
  };

  const closeMenu = useCallback(() => {
    if (anchorEl) {
      anchorEl.focus();
    }
    setAnchorEl(null);
    setIsMenuOpen(false);
  }, [anchorEl]);

  const dialogCopy = useMemo(
    () =>
      type === 'survey'
        ? {
            byline: `Proceeding with this action will permanently remove the post-event survey associated with ${event.name}. Are you absolutely sure?`,
            title: 'post-event survey',
          }
        : status === 'scheduled'
        ? {
            byline: `This action cannot be undone. Deleting your scheduled ${type} will permanently remove it from your upcoming event.`,
            title: `scheduled ${type}`,
          }
        : { byline: '', title: '' },
    [event.name, status, type]
  );

  const handleDialogAction = useCallback(
    (id: number | undefined, confirmation: string | undefined) => {
      if (id) {
        if (confirmation === 'delete') {
          deleteCommunication(id);
        } else {
          confirmation === 'send' && sendNow(id);
        }
      }
    },
    [deleteCommunication, sendNow]
  );

  const handleAction = useCallback(
    ({
      action,
      id,
      methods,
      type,
    }: {
      action: Action;
      id: number;
      methods: CommunicationMethodId[];
      status: CommunicationStatusId;
      type: CommunicationTypeId;
    }) => {
      const method =
        methods?.length === 1
          ? methods[0]
          : methods?.includes('slack') // INFO: slack_with_calendar, email_and_slack
          ? 'slack'
          : methods?.includes('calendar') // INFO: calendar with email checkbox selected
          ? 'calendar'
          : methods[0];
      switch (action.id) {
        case 'copyLink':
          copy(
            getEventLink('survey', 'post_event_survey', event),
            'survey link'
          );
          break;
        case 'edit':
          type === 'invitation' || type === 'message'
            ? history.push(
                `/events/${eventId}/communications/${type}/${method}${
                  id ? '/' + id : ''
                }`
              )
            : select(method, type, id);
          break;
        case 'send':
          setIsSending(true);
          break;
        case 'delete':
          setIsDeleting(true);
          break;
      }
    },
    [copy, event, eventId, history, select]
  );

  const actionOptions = useMemo(
    () =>
      availableActions?.map((actionId: ActionId) => {
        if (
          Actions.has(actionId) &&
          typeof Actions.get(actionId) !== 'undefined'
        ) {
          const action = Actions.get(actionId);
          const ActionIcon = action?.Icon;
          const colorValue: string | undefined =
            theme.palette &&
            action?.color !== 'default' &&
            action?.color !== 'secondary' &&
            (theme.palette[action?.color as keyof Palette] as PaletteColor)
              ? (theme.palette[action?.color as keyof Palette] as PaletteColor)
                  .main
              : undefined;
          return (
            <MenuItem
              color={action?.color}
              key={action?.label}
              onClick={() => {
                handleAction({
                  action: action as Action,
                  id: Number(rowValues?.commId),
                  methods,
                  status,
                  type,
                });
                closeMenu();
              }}
              sx={colorValue ? { color: `${colorValue} !important` } : {}}
            >
              {ActionIcon && (
                <ListItemIcon>
                  <ActionIcon color={colorValue} size={20} />
                </ListItemIcon>
              )}
              <ListItemText primary={action?.label || ''} />
            </MenuItem>
          );
        } else {
          return null;
        }
      }),
    [
      availableActions,
      closeMenu,
      handleAction,
      methods,
      rowValues?.commId,
      status,
      theme.palette,
      type,
    ]
  );

  return actionOptions?.length > 0 ? (
    <>
      <IconButton
        aria-controls={isMenuOpen ? rowValues?.id : undefined}
        aria-describedby={rowValues?.id}
        aria-expanded={isMenuOpen ? 'true' : undefined}
        aria-haspopup='true'
        data-testid='communication-actions-menu-button'
        onClick={openMenu}
        sx={{ transition: 'all 200ms ease-in-out' }}
      >
        <DotsThreeVertical weight='bold' />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id='lock-menu'
        key={`${rowValues?.id}-action-menu`}
        MenuListProps={{
          role: 'listbox',
        }}
        onClose={closeMenu}
        open={isMenuOpen}
      >
        {actionOptions}
      </Menu>
      <DiscardCommunicationConfirmation
        byline={dialogCopy?.byline}
        confirmButtonLabel='Yes, delete'
        isDiscarding={isPendingDelete || isDeleted}
        isOpen={isDeleting}
        onCancel={() => setIsDeleting(false)}
        onDiscard={() => handleDialogAction(rowValues?.id, 'delete')}
        title={`Are you sure you want to delete your ${dialogCopy?.title}?`}
      />
      <SendNowConfirmation
        isOpen={isSending}
        isSending={isPendingSendNow || isSent}
        onCancel={() => setIsSending(false)}
        onSendNow={() => {
          handleDialogAction(rowValues?.id, 'send');
        }}
        type={type}
      />
    </>
  ) : (
    <> </>
  );
};

export default CommunicationsRowActions;
