import React, { useCallback, useContext, useMemo } from 'react';
import { Autocomplete } from '@mui/material';
import PropTypes from 'prop-types';

import { ConditionalLoader } from 'components/Core/Loader';
import AutocompleteCheckboxOption from 'components/shared/AutocompleteCheckboxOption';
import { AutocompleteRenderInput } from 'components/shared/AutocompleteRenderInput';
import { AuthContext } from 'contexts/AuthContext';
import { GroupsContext } from 'contexts/GroupsContext';

const GroupsAutocomplete = ({
  forFilter = false,
  fullWidth = false,
  isDisabled = false,
  maxTags = -1,
  onChange,
  sx = {},
  value = [],
}) => {
  const [rawOptions] = useContext(GroupsContext);
  const {
    userProvider: { user },
  } = useContext(AuthContext);

  const isAdminOrMember = useCallback(
    (group) =>
      group.admin_employee_ids?.includes(user?.id) ||
      group.employee_ids?.includes(user?.id),
    [user?.id]
  );

  const options = useMemo(
    () =>
      rawOptions
        ?.filter((o) => !o?.private || isAdminOrMember(o))
        ?.sort((a, b) => a?.name?.localeCompare(b?.name))
        ?.map(({ employee_ids, ...o }) => ({
          employee_ids,
          label: o?.name,
          value: o?.id,
        })) || [],
    [isAdminOrMember, rawOptions]
  );

  return (
    <ConditionalLoader conditions={[!options]}>
      <Autocomplete
        autoHighlight
        disableCloseOnSelect
        disabled={isDisabled}
        fullWidth={fullWidth}
        id='select-groups'
        limitTags={maxTags}
        multiple
        onChange={onChange}
        options={options}
        renderInput={(params) => (
          <AutocompleteRenderInput
            forFilter={forFilter}
            label='Group'
            params={params}
            value={value}
          />
        )}
        renderOption={(props, option, state, ownerState) => (
          <AutocompleteCheckboxOption
            option={option}
            ownerState={ownerState}
            props={props}
            state={state}
          />
        )}
        slotProps={{
          popper: {
            placement: 'bottom-start',
            style: { width: 'fit-content' },
          },
        }}
        sx={sx}
        value={options?.filter((e) => value?.includes(e.value))}
      />
    </ConditionalLoader>
  );
};

GroupsAutocomplete.propTypes = {
  forFilter: PropTypes.bool,
  fullWidth: PropTypes.bool,
  isDisabled: PropTypes.bool,
  maxTags: PropTypes.number,
  onChange: PropTypes.func,
  sx: PropTypes.object,
  value: PropTypes.array,
};

export default GroupsAutocomplete;
