import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Chip } from '@mui/material';
import { ArrowLeft } from '@phosphor-icons/react';

import SlackSettings from 'components/Settings/Integrations/Slack/SlackIntegrationSettings';
import SyncedSlackChannels from 'components/Settings/Integrations/Slack/SyncedSlackChannels';
import TabsLayout from 'components/shared/layout/TabsLayout';

const SlackIntegration = () => {
  const history = useHistory();
  const [, setCurrTab] = useState(0);

  const tabs = [
    {
      component: <SyncedSlackChannels />,
      label: 'Channels',
      value: 'channels',
    },
    { component: <SlackSettings />, label: 'Settings', value: 'settings' },
  ];

  const handleTabChange = (activeTabIndex) => {
    setCurrTab([...tabs][activeTabIndex]?.value);
  };

  return (
    <TabsLayout
      button={
        <Button
          data-testid='back-to-integrations'
          onClick={() => history.push('/settings#integrations')}
          startIcon={<ArrowLeft />}
          variant='text'
        >
          Back to Integrations
        </Button>
      }
      fixedContent={
        <Chip
          color='success'
          label='Connected'
          sx={{ justifySelf: 'flex-start' }}
        />
      }
      fixedLocation='with-title'
      onChange={handleTabChange}
      tabs={tabs}
      title='Slack'
    />
  );
};

export default SlackIntegration;
