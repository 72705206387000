import React, { useContext } from 'react';
import { GuardProvider } from 'react-router-guards';
import * as _ from 'lodash';
import PropTypes from 'prop-types';

import { AuthContext } from 'contexts/AuthContext';

const GuardMap = {
  '/analytics': { scopes: ['is_admin', 'is_organizer'] },
  '/contacts': { scopes: ['is_admin', 'is_organizer'] },
  '/settings': { scopes: ['is_admin'] },
  '/groups/new': { scopes: ['is_admin', 'is_organizer'] },
  '/surveys': { scopes: ['is_admin', 'is_organizer'] },
};

const UserGuardProvider = ({ children }) => {
  const {
    userProvider: { user },
  } = useContext(AuthContext);

  const roleGuard = (to, from, next) => {
    if (user) {
      if (user.is_admin) {
        next();
      } else {
        const scopes = GuardMap[to?.match?.url].scopes;
        if (scopes) {
          if (_.some(scopes, (s) => user[s] === true)) {
            next();
          }
        }
      }
      next.redirect('/events');
    }
  };

  return <GuardProvider guards={[roleGuard]}>{children}</GuardProvider>;
};

UserGuardProvider.propTypes = {
  children: PropTypes.node,
};
export default UserGuardProvider;
