import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { Card, CardActions, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';

import GroupForm from 'components/Groups/GroupForm';
import DefaultLayout from 'components/shared/layout/DefaultLayout';
import SlackSyncedChip from 'components/shared/SlackSyncedChip';
import { AuthContext } from 'contexts/AuthContext';
import { GroupContext } from 'contexts/GroupContext';
import { GroupsContext } from 'contexts/GroupsContext';
import { SyncedSlackChannelContext } from 'contexts/SyncedSlackChannelContext';
import useGetSlackGroupDetails from 'hooks/useGetSlackGroupDetails';
import useSaveGroup from 'hooks/useSaveGroup';
import { axiosAuthenticated as axios } from 'utils/axios';

const GroupCreationPage = () => {
  const [cover_image, setCover_Image] = useState('');
  const [groups, setGroups] = useContext(GroupsContext);
  const [syncedChannels, , , fetchSyncedSlackChannels] = useContext(
    SyncedSlackChannelContext
  );

  const { dispatch, state: groupState } = useContext(GroupContext);
  const postGroup = useSaveGroup();
  const history = useHistory();
  const location = useLocation();

  const { enqueueSnackbar } = useSnackbar();
  const {
    userProvider: { user },
  } = useContext(AuthContext);
  const getSlackGroupDetails = useGetSlackGroupDetails();

  const setField = useCallback(
    (key, value) => {
      dispatch({ field: key, type: 'SET_FIELD', value });
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch({ type: 'CLEAR_GROUP' });

    setField('group_admin_ids', [user.employee_id]);
  }, [dispatch, setField, user]);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const channel = urlParams.get('channel');
    if (channel) {
      const slackChannel = syncedChannels.find((c) => `${c.id}` === channel);
      if (slackChannel) {
        setField('slack_channel_id', channel);
        setField('group_admin_ids', [user.employee_id]);
        getSlackGroupDetails(slackChannel.slack_id, slackChannel.name);
      }
    }
  }, [getSlackGroupDetails, location.search, setField, syncedChannels, user]);

  const onDrop = (files) => {
    if (files && files.length > 0) {
      setCover_Image(files[0]);
      setField('cover_image', files[0]);
    } else {
      setCover_Image(null);
    }
    setField('coverImageChanged', true);
  };

  const handleGroupCreate = (data) => {
    groups.push(data);
    setGroups(groups);
    enqueueSnackbar('Group was created.', { variant: 'success' });
    fetchSyncedSlackChannels();
    history.push(`/groups/${data.id}`);
  };

  const handleGroupFailure = (error) => {
    enqueueSnackbar(`Something went wrong! ${error}`, {
      variant: 'error',
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    postGroup(
      groupState,
      cover_image,
      handleGroupCreate,
      handleGroupFailure,
      axios,
      history
    );
  };

  return (
    <DefaultLayout
      breadcrumbs={[
        { label: 'Groups', url: '/groups' },
        { label: 'New group' },
      ]}
      title={
        <Stack direction='row' gap={2}>
          <Typography
            sx={{
              maxWidth: '60vw',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
            variant='h1'
          >
            New group
          </Typography>
          {groupState.slack_channel_id.length > 0 && <SlackSyncedChip />}
        </Stack>
      }
    >
      <form onSubmit={(e) => handleSubmit(e)}>
        <Card data-testid='TODO:DATA-CARD-54182'>
          <GroupForm cover_image={cover_image} onDrop={onDrop} />
          <CardActions>
            <LoadingButton
              color='primary'
              data-testid='create-group'
              disabled={!groupState.name}
              loading={groupState.isGroupSaving}
              type='submit'
              variant='contained'
            >
              Create group
            </LoadingButton>
          </CardActions>
        </Card>
      </form>
    </DefaultLayout>
  );
};

export default GroupCreationPage;
