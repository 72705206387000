import React from 'react';
import { Button } from '@mui/material';
import { Link } from '@phosphor-icons/react';

import { ManagerContext } from 'components/Events/Manager/ManagerContext';
import { PublicEventContext } from 'components/Events/PublicEvent/PublicEventContext';
import useCopyToClipboard from 'hooks/useCopyToClipboard';
import { getEventLink } from 'utils/event';
import { useContextWithFallback } from 'utils/utils';

const CopyEventLinkButton = () => {
  const {
    state: { isPublic },
  } = useContextWithFallback(ManagerContext, PublicEventContext);
  const { copy } = useCopyToClipboard();

  return (
    <Button
      data-testid='copy-event-link'
      onClick={() =>
        copy(getEventLink(isPublic ? 'public' : 'events') || '', 'event link')
      }
      startIcon={<Link data-testid='TODO:DATA-LINK-81349' />}
      variant='bordered'
    >
      Copy event link
    </Button>
  );
};

export default CopyEventLinkButton;
