import React, { useMemo } from 'react';
import CacheBuster from 'react-cache-buster';
import { Route, Switch } from 'react-router';
import { HashRouter } from 'react-router-dom';
import 'typeface-inter';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import * as Sentry from '@sentry/react';
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useFlag } from '@unleash/proxy-client-react';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useSnackbar } from 'notistack';

import AppRoutes from 'components/AppRoutes';
import AuthOktaSaml from 'components/AuthOktaSaml';
import { FullscreenLoader } from 'components/Core/Loader';
import WelcomeMessage from 'components/Onboard/WelcomeMessage';
import AudioEye from 'components/shared/AudioEye';
import HotJar from 'components/shared/Hotjar';
import Maintenance from 'components/shared/views/Maintenance';
import { AnalyticsProvider } from 'contexts/AnalyticsContext';
import { AuthProvider } from 'contexts/AuthContext';
import { EmployeesProvider } from 'contexts/EmployeesContext';
import { GroupProvider } from 'contexts/GroupContext';
import { GroupsProvider } from 'contexts/GroupsContext';
import { ListsProvider } from 'contexts/ListsContext';
import { OrganizationProvider } from 'contexts/OrganizationContext';
import { PublicOrgProvider } from 'contexts/PublicOrgContext';
import { SettingsProvider } from 'contexts/SettingsContext';

dayjs.extend(utc);
dayjs.extend(advancedFormat);

dayjs.extend(timezone);
dayjs?.tz?.setDefault('America/New_York');

const SentryRoute = Sentry ? Sentry.withSentryRouting(Route) : Route;

const App = () => {
  const isInMaintenanceMode = useFlag('MaintenanceMode');
  const isProduction = import.meta.env.NODE_ENV === 'production';
  const { enqueueSnackbar } = useSnackbar();

  const queryClient = new QueryClient({
    queryCache: new QueryCache({
      onError: (error, query) => {
        if (import.meta.env.DEV) {
          console.error(error);
        }
        if (query?.meta?.errorMessage) {
          enqueueSnackbar(String(query?.meta?.errorMessage), {
            variant: 'error',
          });
        }
      },
    }),
  });

  const cacheBusterLoader = useMemo(
    () => (
      <FullscreenLoader
        message='Checking for a new version of Five to Nine'
        show
      />
    ),
    []
  );

  return (
    <CacheBuster
      currentVersion={import.meta.env.VITE_VERSION}
      isEnabled={isProduction} // If false, the library is disabled.
      isVerboseMode={false} // If true, the library writes verbose logs to console.
      loadingComponent={cacheBusterLoader} // If not pass, nothing appears at the time of new version check.
    >
      {isInMaintenanceMode ? (
        <Maintenance />
      ) : (
        <QueryClientProvider client={queryClient}>
          <HashRouter>
            <Switch>
              <SentryRoute
                component={AuthOktaSaml}
                exact
                path='/auth/okta/saml'
              />
              <SentryRoute path='*'>
                <AuthProvider>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <PublicOrgProvider>
                      <HotJar />
                      <AudioEye />
                      <OrganizationProvider>
                        <EmployeesProvider>
                          <WelcomeMessage />
                          <GroupsProvider>
                            <GroupProvider>
                              <SettingsProvider>
                                <ListsProvider>
                                  <AnalyticsProvider>
                                    <AppRoutes />
                                  </AnalyticsProvider>
                                </ListsProvider>
                              </SettingsProvider>
                            </GroupProvider>
                          </GroupsProvider>
                        </EmployeesProvider>
                      </OrganizationProvider>
                    </PublicOrgProvider>
                  </LocalizationProvider>
                </AuthProvider>
              </SentryRoute>
            </Switch>
          </HashRouter>
          {import.meta.env.VITE_FLAG_REACT_QUERY ? (
            <ReactQueryDevtools initialIsOpen={false} />
          ) : null}
        </QueryClientProvider>
      )}
    </CacheBuster>
  );
};

export default App;
