import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { capitalize } from '@mui/material';
import {
  CalendarBlank,
  Circle,
  Copy,
  Eye,
  MinusCircle,
  PencilSimple,
} from '@phosphor-icons/react';
import dayjs from 'dayjs';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

import DeleteModal from 'components/Core/DeleteModal';
import SurveyPreview from 'components/Events/Controls/Surveys/SurveyPreview';
import GridCard from 'components/shared/GridCard';
import { useCloneSurveyTemplates } from 'components/Survey/SurveyTemplates/Hooks/TemplateSurveys';
import { OrganizationContext } from 'contexts/OrganizationContext';
import { axiosAuthenticated } from 'utils/axios';

const SurveyTemplateCard = ({
  creator = {},
  removeFromChunks,
  template,
  user,
}) => {
  const [organization] = useContext(OrganizationContext);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);
  const [isWorking, setIsWorking] = useState(false);
  const [viewTemplate, setViewTemplate] = useState(false);

  const canEdit = template.creator_id === user.id;

  const startTask = () => {
    setIsWorking(true);
  };

  const endTask = () => {
    setIsWorking(false);
  };

  const open = () => {
    if (canEdit) {
      history.push(`/surveys/${template?.id}/edit`);
    } else {
      setViewTemplate(true);
    }
  };

  const handleDelete = ({ id, name }) => {
    if (!id) {
      enqueueSnackbar('Something went wrong, please try again.', {
        variant: 'error',
      });
    } else if (id && !isWorking) {
      startTask();
      axiosAuthenticated(
        {
          method: 'delete',
          url: `/api/survey_templates/${id}`,
        },
        (res) => {
          if (res.data && res.data.error) {
            enqueueSnackbar('Something went wrong, please try again.', {
              variant: 'error',
            });
            endTask();
          } else {
            removeFromChunks(id);
            enqueueSnackbar(`"${name}" template deleted!`, {
              variant: 'success',
            });
            endTask();
          }
        },
        () => {
          enqueueSnackbar('Something went wrong, please try again.', {
            variant: 'error',
          });
          endTask();
        }
      );
    }
  };

  const clone = useCloneSurveyTemplates(
    startTask,
    endTask,
    (resp) => {
      enqueueSnackbar(
        'Survey template duplicated! Rename and/or update as needed.',
        { variant: 'success' }
      );
      history.push(`/surveys/${resp.id}/edit`);
    },
    () => {
      enqueueSnackbar('Something went wrong, please try again.', {
        variant: 'error',
      });
    }
  );

  const StyledCircle = (props) => <Circle weight='fill' {...props} />;

  return (
    <>
      <GridCard
        avatars={[creator]}
        canEdit={canEdit}
        handleOpenCard={open}
        isDescriptionless
        isImageless
        item={template}
        itemType='survey template'
        menuItems={[
          ...(canEdit
            ? [
                {
                  handleClick: () =>
                    history.push(`/surveys/${template.id}/edit`),
                  icon: <PencilSimple />,
                  label: 'Edit',
                  onlyIf: canEdit,
                },
              ]
            : []),
          {
            handleClick: () => setViewTemplate(true),
            icon: <Eye />,
            label: 'View',
          },
          {
            handleClick: () => clone(template.id),
            icon: <Copy />,
            label: 'Duplicate',
          },
          ...(canEdit
            ? [
                {
                  handleClick: () => setIsConfirmingDelete(true),
                  icon: <MinusCircle />,
                  label: 'Delete',
                },
              ]
            : []),
        ]}
        stats={[
          {
            Icon: StyledCircle,
            iconColor: template.style === 'pre' ? '#A783EA' : '#D975B2',
            id: 'type',
            text: `${capitalize(template.style)}-event`,
          },
          {
            Icon: CalendarBlank,
            id: 'created',
            text: dayjs(template.created_at).format('MM/DD/YYYY'),
          },
        ]}
        title={template.name}
      />
      <DeleteModal
        noun='survey template'
        onCancel={() => setIsConfirmingDelete(false)}
        onDelete={() => {
          handleDelete(template);
          setIsConfirmingDelete(false);
        }}
        owner={organization?.display_name}
        show={isConfirmingDelete}
        subject={template.name}
      />
      <SurveyPreview
        canEdit={canEdit}
        cloneSurveyTemplate={clone}
        handleClose={() => setViewTemplate(false)}
        show={viewTemplate}
        template={template}
        viewTemplate
      />
    </>
  );
};

SurveyTemplateCard.propTypes = {
  axios: PropTypes.func,
  creator: PropTypes.any,
  removeFromChunks: PropTypes.func,
  template: PropTypes.shape({
    created_at: PropTypes.any,
    creator_id: PropTypes.any,
    id: PropTypes.any,
    name: PropTypes.string,
    style: PropTypes.string,
  }),
  user: PropTypes.shape({
    id: PropTypes.any,
  }),
};

export default SurveyTemplateCard;
