import React, { useCallback, useContext, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Tab, Tabs } from '@mui/material';

import { ManagerContext } from 'components/Events/Manager/ManagerContext';

const ManageNavbar = () => {
  const {
    actions: { setActiveTabIndex },
    state: { activeTabIndex, canEdit, event, groupId, manageTabs },
  } = useContext(ManagerContext);

  const location = useLocation();
  const tabs = Object.values(manageTabs);

  const getTabIndex = useCallback(() => {
    // when splitting '/events/id/slug' by '/', the resulting array starts with an empty space,
    // thus placing 'events' at index 1, the id at index 2, and the slug at index 3
    const slug = location.pathname.split('/')[3];
    return tabs.findIndex((t) => t.slug === slug);
  }, [location.pathname, tabs]);

  useEffect(() => {
    let activeTabTimer;
    if (location?.pathname) {
      const tabIndex = getTabIndex();
      if (tabIndex > -1 && tabIndex !== activeTabIndex) {
        setActiveTabIndex(tabIndex);
      } else if (activeTabIndex !== false) {
        activeTabTimer = setTimeout(() => {
          const tabIndex = getTabIndex();
          if (tabIndex > -1 && tabIndex !== activeTabIndex) {
            setActiveTabIndex(tabIndex);
          }
        }, 500);
      }
    }
    return () => clearTimeout(activeTabTimer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const tabLink = (tabSlug) =>
    groupId
      ? `/events/${event.id}/${tabSlug}?group_id=${groupId}`
      : `/events/${event.id}/${tabSlug}`;

  return (
    <Tabs
      aria-label='Manage event tabs'
      scrollButtons='auto'
      value={activeTabIndex}
      variant='scrollable'
    >
      {canEdit &&
        tabs.map((tab, index) => (
          <Tab
            component={NavLink}
            data-testid={`manage-tab-item-${tab.slug}`}
            key={index}
            label={tab.label}
            to={tabLink(tab.slug)}
            value={index}
            // sx={tab.hidden ? { visibility: 'hidden' } : {}}
          />
        ))}
    </Tabs>
  );
};
export default ManageNavbar;
