import { useCallback, useContext, useEffect, useMemo } from 'react';
import { Redirect } from 'react-router-dom';
import { OktaAuth } from '@okta/okta-auth-js';
import axios from 'axios';
import { useSnackbar } from 'notistack';

import { Loader } from 'components/Core/Loader';
import { AuthContext } from 'contexts/AuthContext';
import { PublicOrgContext } from 'contexts/PublicOrgContext';

const OktaLoginRedirector = () => {
  const {
    token: { isAuthenticated, setToken },
    userProvider: { setUser, user },
  } = useContext(AuthContext);
  const [org] = useContext(PublicOrgContext);
  const { enqueueSnackbar } = useSnackbar();

  const oidcExchange = useCallback(
    (code, orgId) => {
      console.log('oidc exchange');
      axios({
        data: {
          user: {
            code,
            organization_id: orgId,
            redirect_uri: window.location.origin + '/auth/okta',
          },
        },
        method: 'post',
        url: `${import.meta.env.VITE_BE_URL}/api/users/okta_oauth`,
      })
        .then((res) => {
          console.log('token & user received');
          setUser(res.data.user);
          setToken(res.data.token);
        })
        .catch((err) => {
          enqueueSnackbar(
            'An unexpected error occured when logging into Okta.',
            {
              variant: 'error',
            }
          );
          console.log('err', err);
        });
    },
    [enqueueSnackbar, setToken, setUser]
  );

  const samlExchange = useCallback(
    (iss, orgId, clientId) => {
      console.log('saml exchange');
      const okta = new OktaAuth({
        clientId,
        issuer: `${iss}/oauth2`,
        pkce: false,
        redirectUri: window.location.origin + '/auth/okta',
        responseType: 'code',
        state: orgId,
      });
      okta.signInWithRedirect().catch((err) => {
        console.log('err', err);
        enqueueSnackbar('An unexpected error occured when logging into Okta.', {
          variant: 'error',
        });
      });
    },
    [enqueueSnackbar]
  );

  const query = new URLSearchParams(window.location.search);
  const code = query.get('code');
  const orgId = query.get('state');
  const iss = query.get('iss');

  useEffect(() => {
    if (code && orgId && !user) {
      oidcExchange(code, orgId);
    }
  }, [code, oidcExchange, orgId, user]);

  useEffect(() => {
    if (iss && org && !user) {
      samlExchange(iss, org.id, org.okta_client_id);
    }
  }, [org, samlExchange, iss, user]);

  const output = useMemo(() => {
    if (isAuthenticated === true && user) {
      return <Redirect to='/events' />;
    } else {
      return <Loader inline />;
    }
  }, [user, isAuthenticated]);

  return output;
};
export default OktaLoginRedirector;
