import React, { useLayoutEffect, useMemo, useRef, useState } from 'react';
import {
  Box,
  Button,
  capitalize,
  Collapse,
  Divider,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import PropTypes from 'prop-types';

/**
 * Renders <DescriptionInHtml /> component
 * @param {object} props
 * @param {string} className css class list
 * @param {string} text text to be set as inner HTML
 * @param {boolean} truncateLong default: false. Optionally truncate long description
 * @param {string} fadeToColor background color to fade out to if truncating long descriptions
 * @returns
 */
const DescriptionInHtml = ({
  subject = 'Description',
  text,
  truncateLong = false,
  ...props
}) => {
  const maxPreviewHeight = 100;
  const previewRef = useRef(null);

  const [containerHeight, setContainerHeight] = useState(maxPreviewHeight);
  const [descriptionHeight, setDescriptionHeight] = useState(0);
  const [isExpanded, setIsExpanded] = useState(false);

  const theme = useTheme();

  useLayoutEffect(() => {
    // Before rendering, but after doing layout mesaure how much space the full
    // description will need
    const descRef = previewRef.current;
    if (descRef && truncateLong === true) {
      const rect = descRef.getBoundingClientRect();
      setDescriptionHeight(rect.height);
      const yPosition = descRef.offsetTop;
      if (descriptionHeight > maxPreviewHeight) {
        const blocks = Array.from(descRef.children)
          .map((block) => block.offsetTop - yPosition + block.offsetHeight)
          .filter((val) => val < maxPreviewHeight);
        setContainerHeight(blocks[blocks.length - 1]);
      }
    }
  }, [descriptionHeight, text, truncateLong]);

  const descriptionElement = useMemo(
    () => (
      <Box
        dangerouslySetInnerHTML={{
          __html: text || '',
        }}
        ref={previewRef}
        {...props}
        maxWidth='100%'
        sx={{
          ...(props.sx ? props.sx : {}),
          '& > :first-child': {
            mt: 0,
          },
          '& > :last-child': {
            mb: 0,
          },
          '& ul, & ol': {
            pl: 2,
          },
        }}
      />
    ),
    [text, props]
  );

  return (
    <>
      {!truncateLong || descriptionHeight <= maxPreviewHeight ? (
        descriptionElement
      ) : (
        <Box maxWidth='100%' minHeight={containerHeight}>
          <Collapse
            collapsedSize={containerHeight}
            easing='1000ms'
            in={isExpanded}
            unmountOnExit
          >
            {descriptionElement}
          </Collapse>
          <Divider>
            <Stack
              alignItems='center'
              columnGap={1}
              direction={{ sm: 'row', xs: 'column' }}
            >
              <Typography color='text.secondary' variant='overline'>
                {capitalize(subject)} truncated
              </Typography>
              <Button
                data-testid='show'
                onClick={() => setIsExpanded(!isExpanded)}
                size='small'
                sx={{
                  display: 'inline',
                  fontSize: theme.typography.overline.fontSize,
                }}
              >
                Show {isExpanded ? 'less' : 'more'}
              </Button>
            </Stack>
          </Divider>
        </Box>
      )}
    </>
  );
};

DescriptionInHtml.propTypes = {
  className: PropTypes.string,
  subject: PropTypes.string,
  text: PropTypes.string,
  truncateLong: PropTypes.bool,
};
export default DescriptionInHtml;
