import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';

import Search from 'components/Core/Search';
import Filters from 'components/shared/Filters';
import { GroupsContext } from 'contexts/GroupsContext';
import { OrganizationContext } from 'contexts/OrganizationContext';
import useGetEmployeesForOptions from 'hooks/useGetEmployeesForOptions';
import { fullName } from 'utils/employee';

const EventFilters = ({
  onGroupChange,
  onHostChange,
  onOfficeChange,
  onSearchChange,
  onTypeChange,
  searchValue,
}) => {
  const [groups] = useContext(GroupsContext);
  const [organization, , , , , eventTypeColors] =
    useContext(OrganizationContext);

  const {
    getEventHosts: { data: event_host_employees },
  } = useGetEmployeesForOptions();

  const groupOpts = useMemo(
    () => groups?.map((g) => ({ id: g.id, name: g.name })),
    [groups]
  );

  const officeOpts = useMemo(
    () => organization?.offices,
    [organization?.offices]
  );

  const typeOpts = useMemo(
    () =>
      organization?.event_types?.map((type) => ({
        ...type,
        color: eventTypeColors !== null ? eventTypeColors[type?.id] : null,
      })),
    [eventTypeColors, organization?.event_types]
  );

  const hostOpts = useMemo(
    () =>
      event_host_employees
        ?.map((o) => ({
          id: o?.id,
          name: fullName(o),
          secondary: o?.email,
        }))
        ?.sort((a, b) => a?.name?.localeCompare(b?.name)),
    [event_host_employees]
  );

  return (
    <Filters
      filters={[
        {
          handleChange: onOfficeChange,
          label: 'Office',
          options: officeOpts || [],
        },
        {
          handleChange: onTypeChange,
          label: 'Type',
          options: typeOpts || [],
        },
        {
          handleChange: onGroupChange,
          label: 'Group',
          options: groupOpts || [],
        },
        {
          handleChange: onHostChange,
          label: 'Host',
          options: hostOpts || [],
        },
      ]}
    >
      <Search
        label='Search events'
        onChange={(e) => onSearchChange(e.target.value)}
        placeholder='Search events'
        value={searchValue}
      />
    </Filters>
  );
};

EventFilters.propTypes = {
  onGroupChange: PropTypes.func,
  onHostChange: PropTypes.func,
  onOfficeChange: PropTypes.func,
  onSearchChange: PropTypes.func,
  onTypeChange: PropTypes.func,
  searchValue: PropTypes.any,
};

export default EventFilters;
