import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from '@mui/material';
import {
  CalendarBlank,
  GlobeHemisphereWest,
  User,
} from '@phosphor-icons/react';

import Search from 'components/Core/Search';
import GroupCreate from 'components/Groups/GroupCreate';
import Filters from 'components/shared/Filters';
import GridCards from 'components/shared/GridCards';
import TabsLayout from 'components/shared/layout/TabsLayout';
import SlackSyncedChip from 'components/shared/SlackSyncedChip';
import { AuthContext } from 'contexts/AuthContext';
import { GroupsContext } from 'contexts/GroupsContext';
import { OrganizationContext } from 'contexts/OrganizationContext';
import { CloudinaryUrl } from 'utils/utils';

const Groups = () => {
  const {
    userProvider: { user },
  } = useContext(AuthContext);
  const [groups, , isLoading] = useContext(GroupsContext);
  const [org] = useContext(OrganizationContext);

  const history = useHistory();

  const [filterResults, setFilteredResults] = useState([]);
  const [isFiltering, setIsFiltering] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedOffices, setOffices] = useState([]);
  const [selectedTypes, setType] = useState([]);
  const [tab, setTab] = useState('all');

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const searchGroupName = useCallback(
    (group, searchTerm) =>
      group?.name?.toLowerCase().includes(searchTerm?.toLowerCase()),
    []
  );

  const officeFilter = useCallback(
    (selectedOffices, group) =>
      !selectedOffices.length > 0 ||
      group.office_ids.some((o) => selectedOffices.includes(o)),
    []
  );

  const typeFilter = useCallback(
    (selectedTypes, group) =>
      !selectedTypes.length > 0 || selectedTypes.includes(group.group_type_id),
    []
  );

  const memberOrAdmin = useCallback(
    (group, user) =>
      group.admin_employee_ids.includes(user.employee_id) ||
      group.employee_ids.includes(user.employee_id),
    []
  );

  const privateFilter = useCallback(
    (group, user) => !group.private || memberOrAdmin(group, user),
    [memberOrAdmin]
  );

  const myGroupsFilter = useCallback(
    (group, user, tab) => tab !== 'my' || memberOrAdmin(group, user),
    [memberOrAdmin]
  );

  useEffect(() => {
    if (groups && groups?.length > 0 && !isLoading) {
      setIsFiltering(true);
      const filteredGroups = groups?.filter(
        (group) =>
          searchGroupName(group, searchTerm) &&
          officeFilter(selectedOffices, group) &&
          typeFilter(selectedTypes, group) &&
          privateFilter(group, user) &&
          myGroupsFilter(group, user, tab)
      );

      const sortedGroups = filteredGroups.sort((a, b) =>
        a.name.localeCompare(b.name)
      );

      setFilteredResults(sortedGroups);
      setIsFiltering(false);
    }
  }, [
    searchTerm,
    selectedOffices,
    selectedTypes,
    groups,
    isLoading,
    tab,
    user.group_ids,
    searchGroupName,
    officeFilter,
    typeFilter,
    privateFilter,
    user,
    myGroupsFilter,
  ]);

  const canCreate = useMemo(
    () => user && (user.is_admin || user.is_organizer),
    [user]
  );

  const chunk = useMemo(
    () => ({
      hasMore: false,
      isLoading: isFiltering,
      values: filterResults?.map((group) => ({
        avatars: group && user ? group.admin_employees : null,
        description: group?.safe_description,
        handleOpenCard: () => history.push(`/groups/${group?.id}`),
        imageUrl: group?.cover_image ? CloudinaryUrl(group?.cover_image) : null,
        item: group,
        itemType: 'group',
        key: group?.id,
        placeholderBgColor: 'secondary',
        stats: [
          {
            Icon: User,
            id: 'members',
            text: group?.employee_ids.length || 0,
          },
          {
            Icon: CalendarBlank,
            id: 'events',
            text: group?.event_ids.length || 0,
          },
          ...(group?.synced_slack_channel !== null
            ? [
                {
                  id: 'slack_synced',
                  StatChip: <SlackSyncedChip size='small' />,
                },
              ]
            : [
                {
                  hidden: group?.private,
                  Icon: GlobeHemisphereWest,
                  id: 'public',
                },
              ]),
        ],
        title: group?.name,
      })),
    }),
    [filterResults, history, isFiltering, user]
  );

  const TabContent = (
    <GridCards
      chunk={chunk}
      NoDataComponent={
        canCreate ? (
          <Link data-testid='create-new-group' href='/groups/new'>
            Create new group
          </Link>
        ) : null
      }
      noun='groups'
    />
  );

  const tabs = [
    { component: TabContent, label: 'All groups', value: 'all' },
    { component: TabContent, label: 'My groups', value: 'my' },
  ];

  const handleTabChange = (activeTabIndex) => {
    setTab([...tabs][activeTabIndex]?.value);
  };

  return (
    <TabsLayout
      button={<GroupCreate canCreate={canCreate} />}
      fixedContent={
        <Filters
          filters={[
            {
              handleChange: setOffices,
              label: 'Office',
              options: org?.offices || [],
            },
            {
              handleChange: setType,
              label: 'Type',
              options: org?.group_types || [],
            },
          ]}
        >
          <Search
            label='Search groups'
            onChange={handleChange}
            placeholder='Search groups'
            value={searchTerm}
          />
        </Filters>
      }
      onChange={handleTabChange}
      tabs={tabs}
      title='Groups'
    />
  );
};

export default Groups;
