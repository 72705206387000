import React, { useContext, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import { isNil } from 'lodash';
import PropTypes from 'prop-types';

import GridCard from 'components/shared/GridCard';
import { LayoutContext } from 'contexts/LayoutContext';
import { flexColumn } from 'utils/styles';

const GridCards = ({
  CardComponent,
  children,
  chunk,
  isNarrow,
  NoDataComponent = <> </>,
  noDataText,
  noun,
}) => {
  const { isMobile } = useContext(LayoutContext);
  const { inView, ref } = useInView({ threshold: 0 });
  const [noData, setNoData] = useState(false);

  const cardColumns = isNarrow
    ? {
        lg: 4,
        md: 6,
        sm: 10,
      }
    : {
        lg: 3,
        md: 4,
        sm: 6,
        xs: 10,
      };

  useEffect(() => {
    if (inView === true) {
      chunk?.requestMore && chunk?.requestMore();
    }
  });

  useEffect(() => {
    let timeoutId;
    if (!isNil(chunk?.values) && chunk?.values?.length < 1) {
      timeoutId = setTimeout(() => {
        if (
          !isNil(chunk?.values) &&
          chunk?.values?.length < 1 &&
          !chunk?.isLoading
        ) {
          setNoData(true);
        } else {
          setNoData(false);
        }
      }, 400);
    } else {
      setNoData(false);
    }
    // cleanup function
    return () => clearTimeout(timeoutId);
  }, [chunk?.isLoading, chunk?.values]);

  return (
    <Grid
      alignItems='stretch'
      columnGap={isMobile ? 3 : null}
      columnSpacing={isMobile ? null : 3}
      container
      direction={isMobile ? 'column' : 'row'}
      justifyContent={{ sm: 'flex-start', xs: 'center' }}
      pb={8}
      rowGap={isMobile ? 4 : null}
      rowSpacing={isMobile ? null : 4}
    >
      {children || chunk?.values?.length > 0
        ? chunk?.values?.map((item, i) => (
            <Grid
              {...cardColumns}
              alignItems='stretch'
              container
              direction='column'
              item
              key={item.id || i}
            >
              {CardComponent ? (
                <CardComponent {...item} />
              ) : (
                <GridCard {...item} />
              )}
            </Grid>
          ))
        : null}
      <Grid item {...cardColumns}>
        <div
          className='intersection-elem'
          ref={ref}
          style={{ marginBottom: 16, marginTop: -16 }}
        />
        <Box
          {...flexColumn}
          alignItems='center'
          height='100%'
          justifyContent='center'
          mb={4}
          width='100%'
        >
          {noData && chunk?.values?.length === 0 ? (
            <Box alignSelf='flex-start' justifySelf='flex-start'>
              <Typography color='text.secondary' variant='body1'>
                {noDataText || `No ${noun}`}
              </Typography>
              {NoDataComponent}
            </Box>
          ) : (
            <CircularProgress
              disableShrink
              size={48}
              sx={{
                visibility:
                  !chunk?.values ||
                  chunk?.isLoading ||
                  (chunk?.values?.length > 0 && chunk?.hasMore)
                    ? 'visible'
                    : 'hidden',
              }}
            />
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default GridCards;

GridCards.propTypes = {
  CardComponent: PropTypes.any,
  children: PropTypes.node,
  chunk: PropTypes.object,
  isNarrow: PropTypes.bool,
  NoDataComponent: PropTypes.any,
  noDataText: PropTypes.string,
  noun: PropTypes.string,
};
