import React from 'react';
import PropTypes from 'prop-types';

import { SurveyResponseProvider } from 'components/Survey/SurveyResponseContext';
import SurveyWrapper from 'components/Survey/SurveyWrapper';

const Survey = ({ type }) => {
  return (
    <SurveyResponseProvider>
      <SurveyWrapper type={type} />
    </SurveyResponseProvider>
  );
};

Survey.propTypes = {
  type: PropTypes.oneOf(['pre_event_survey', 'post_event_survey']),
};

export default Survey;
