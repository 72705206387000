import React from 'react';
import { Button } from '@mui/material';

const ImageFieldButton = (props: any) => (
  <Button
    {...props}
    color='secondary'
    component={null}
    disabled={false}
    href={undefined}
    size='small'
    variant='bordered'
  />
);

export default ImageFieldButton;
