import React, { useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Card, CardHeader } from '@mui/material';

import EventBudgetDialog from 'components/Event/Budget/EventBudgetDialog';
import EventBudgetOverviewMetric from 'components/Event/Budget/EventBudgetOverviewMetric';
import EventBudgetSpendPerCategoryChart from 'components/Event/Budget/EventBudgetSpendPerCategoryChart';
import EventBudgetSummaryChart from 'components/Event/Budget/EventBudgetSummaryChart';
import InfoTooltip from 'components/shared/InfoTooltip';
import { OrganizationContext } from 'contexts/OrganizationContext';
import { useEventBudget } from 'hooks/useEventBudget';
import Currency from 'types/Currency';

const EventBudgetOverview = () => {
  const [organization] = useContext(OrganizationContext);
  const { id: eventId } = useParams<any>();

  const {
    retrieve: { data },
  } = useEventBudget({ eventId });

  const activeCurrency = useMemo(
    () =>
      organization?.currencies?.find(
        (c: Currency) => c.id === data?.currency_id
      ),
    [data?.currency_id, organization?.currencies]
  );

  return (
    <Box
      display='grid'
      gap={3}
      gridTemplateColumns={{ lg: 'min-content repeat(2, 1fr)', xs: '100%' }}
      gridTemplateRows={{ lg: 'auto', xs: 'repeat(3, min-content)' }}
    >
      <Box
        display='grid'
        gap={1.5}
        gridTemplateColumns={{ lg: '192px', sm: 'repeat(3, 1fr)', xs: '100%' }}
        gridTemplateRows={{
          lg: 'repeat(3, 1fr)',
          sm: 'auto',
          xs: 'repeat(3, auto)',
        }}
      >
        <EventBudgetOverviewMetric
          currency={activeCurrency}
          title='Actual spend'
          tooltip='The sum of actual costs listed for all expenses.'
          value={data?.actual_spend}
        />
        <EventBudgetOverviewMetric
          currency={activeCurrency}
          title='Estimated spend'
          tooltip='The sum of estimated costs listed for all expenses.'
          value={data?.estimated_spend}
        />
        <EventBudgetOverviewMetric
          currency={activeCurrency}
          EditComponent={EventBudgetDialog}
          title='Event budget'
          tooltip='The allocated budget towards planning and organizing your event.'
          value={data?.amount}
        />
      </Box>
      {/* TODO: Create EventBudgetChart component */}
      <Card sx={{ overflow: 'visible' }}>
        <CardHeader
          title={
            <InfoTooltip
              content='The summary of actual spend, estimated spend, and event budget associated with this event.'
              text='Event summary'
              textVariant='body2'
            />
          }
        />
        <Box height={220}>
          <EventBudgetSummaryChart
            actual={data?.actual_spend}
            budget={data?.amount}
            currency={activeCurrency}
            estimated={data?.estimated_spend}
          />
        </Box>
      </Card>
      <Card sx={{ overflow: 'visible' }}>
        <CardHeader
          title={
            <InfoTooltip
              content='The breakdown of actual costs based on expense categories.'
              text='Actual spend per category'
              textVariant='body2'
            />
          }
        />
        <Box height={220} mt={-1.5}>
          <EventBudgetSpendPerCategoryChart
            currency={activeCurrency}
            data={
              data && data?.category_totals?.length > 0
                ? data?.category_totals
                    ?.filter((c) => c.active)
                    ?.map((c) => ({
                      id: c.name,
                      label: c.name,
                      percentage: c.percent_of_total_spend,
                      value: c.category_total_actual_spend,
                    }))
                : []
            }
          />
        </Box>
      </Card>
    </Box>
  );
};

export default EventBudgetOverview;
