import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Cloudinary } from 'cloudinary-core';
import * as _ from 'lodash';

import { axiosAuthenticated as axios } from 'utils/axios';

/* Cloudinary url processing */
export const CloudinaryUrl = (publicId, width, height) => {
  const cld = new Cloudinary({
    cloud_name: 'five-to-nine',
    secure: process.env.NODE_ENV !== 'development',
  });
  const t = cld.transformation().crop('fill').width(width).height(height);
  const url = cld.url(publicId, t.toOptions());
  return url;
};

/* Custom Hooks & Related Helpers */
export const useChunking = (baseUrl, propName) => {
  const [values, setValues] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [next, setNext] = useState(1);
  const [total, setTotal] = useState();
  const [hasMore, setHasMore] = useState(true);

  const loadNext = () => {
    if (next != null && !isLoading) {
      setIsLoading(true);

      axios({ url: `${baseUrl}?page=${next}` }, (res) => {
        setValues(_.unionBy(values, res.data[propName], 'id'));
        setNext(res.data.info.next);
        setTotal(res.data.info.count);
        if (!res.data.info.next) {
          setHasMore(false);
        }
        setIsLoading(false);
      });
    }
  };

  const removeItemFromChunk = (id) => {
    const newValues = _.filter(values, (item) => item.id !== id);

    setValues(newValues);
  };

  return {
    hasMore,
    isLoading,
    removeItemFromChunk,
    requestMore: loadNext,
    total,
    values,
  };
};

const useTimer = (onTimeout, timerLengthMs) => {
  const timeoutRef = useRef(null);
  const onTimeoutRef = useRef(onTimeout);

  useEffect(() => {
    onTimeoutRef.current = onTimeout;
  }, [onTimeout]);

  useEffect(() => {
    if (timerLengthMs) {
      timeoutRef.current = setTimeout(onTimeoutRef.current, timerLengthMs);
      return () => clearTimeout(timeoutRef.current);
    }
  });

  return timeoutRef;
};

export const useDelayedLoading = (loaderDelayMs = 350) => {
  const [isWorking, setWorking] = useState(false);
  const [longWorking, setLongWorking] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState(null);

  useTimer(
    () => {
      setLongWorking(true);
    },
    isWorking ? loaderDelayMs : null
  );

  const startTask = (msg = null) => {
    setWorking(true);
    setLongWorking(false);
    if (msg) {
      setLoadingMessage(msg);
    }
  };

  const endTask = () => {
    setWorking(false);
    setLongWorking(false);
    setLoadingMessage(null);
  };

  return { endTask, isWorking, loadingMessage, longWorking, startTask };
};

export const useContextWithFallback = (...args) => {
  const context = _.find(args, (arg) => arg._currentValue !== undefined);
  const actContext = useContext(context);

  return actContext;
};

export const usePagination = ({ data, pageSize }) => {
  const [page, setPage] = useState(1);
  const numPage = useMemo(() => {
    if (data?.length) {
      const pages = Math.ceil(data.length / pageSize);
      if (page > pages) {
        setPage(1);
      }
      return pages;
    } else {
      return 0;
    }
  }, [data, page, pageSize]);
  const pageData = data?.slice((page - 1) * pageSize, page * pageSize);
  const pageSequence = _.filter(
    _.range(page - 2, page + 3),
    (p) => p > 0 && p <= numPage
  );

  return {
    numPage,
    page,
    pageData,
    pageSequence,
    setPage,
  };
};

export const ApplyFilterToChunk = (filterFunc, chunk) => {
  const values = _.filter(chunk.values, (ev) => filterFunc(ev));
  return { ...chunk, values };
};

const EmailRegex =
  // eslint-disable-next-line no-control-regex
  // /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
  /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;

export const ValidateEmail = (email) => EmailRegex.test(_.toLower(email));

// TODO: Determine if we want to require protocol when validating
//      (if not, we'll need to come up with an alternative approach)
export const ValidateUrl = (string) => {
  let givenURL;
  try {
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    givenURL = new URL(string);
  } catch (error) {
    return false;
  }
  return true;
};

export const PasswordLengthRegex = /.{8,}/;
export const PasswordDigitRegex = /.*\d/;
export const PasswordUpperRegex = /.*[A-Z]/;
export const PasswordLowerRegex = /.*[a-z]/;
export const PasswordSpecialRegex = /.*(!|@|#|\$|%|&|\*|\(|\)|\+|\?|\/)/;

export const ValidatePassword = (pw) =>
  PasswordLengthRegex.test(pw) &&
  PasswordDigitRegex.test(pw) &&
  PasswordUpperRegex.test(pw) &&
  PasswordLowerRegex.test(pw) &&
  PasswordSpecialRegex.test(pw);

export const asCurrency = (amount, currency_id, decimalPlaces = 2) => {
  if (amount == null || isNaN(amount)) {
    amount = 0;
  }

  amount = Number(amount);

  const usesCurrency = currency_id && currency_id !== null;

  const currencyFn = new Intl.NumberFormat('en-US', {
    ...(usesCurrency ? { currency: currency_id } : {}),
    maximumFractionDigits: decimalPlaces,
    minimumFractionDigits: decimalPlaces,
    style: usesCurrency ? 'currency' : 'decimal',
  });

  if (amount === 0 && usesCurrency) {
    return currencyFn.format(0);
  }

  if (amount === 0 && !usesCurrency) {
    return '0';
  }

  return currencyFn.format(amount);
};
