import React, { useContext } from 'react';
import { Chip, ListItem, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import { InvitedCheckIcon } from 'components/Events/Controls/Invites/Invite.constants';
import { GroupsContext } from 'contexts/GroupsContext';
import { ListsContext } from 'contexts/ListsContext';
import { OrganizationContext } from 'contexts/OrganizationContext';
import { CalculateAccumulatedRecipients } from 'utils/invitations';
import { flexColumn } from 'utils/styles';

const EmailHistory = ({ batches, message }) => {
  const [groups] = useContext(GroupsContext);
  const [lists] = useContext(ListsContext);
  const [org] = useContext(OrganizationContext);

  const {
    invitedIndividualContacts,
    invitedAllEmployees,
    invitedAllNonContacts,
  } = CalculateAccumulatedRecipients(batches, groups, lists, org?.offices);

  return (
    <ListItem
      dense
      disableGutters
      disablePadding
      sx={{ ...flexColumn, alignItems: 'stretch', gap: 1 }}
    >
      {message?.invite_body?.length > 0 ? (
        <Stack alignItems='center' direction='row' gap={1}>
          <InvitedCheckIcon />
          <Typography>Personalized message</Typography>
        </Stack>
      ) : null}
      <Stack alignItems='center' direction='row' flexWrap='wrap' gap={1}>
        {invitedAllEmployees?.uniqueContactsCount > 0 ? (
          <>
            <InvitedCheckIcon />
            <Typography>{`${invitedAllEmployees?.uniqueContactsCount} selected guests:`}</Typography>
          </>
        ) : null}
        {invitedIndividualContacts?.length > 0 ? (
          <Chip
            label={`Individual contacts (${invitedIndividualContacts?.length})`}
          />
        ) : null}
        {invitedAllNonContacts?.length > 0 ? (
          <>
            {invitedAllNonContacts?.map((item) => (
              <Chip key={`${item.id}-${item.name}`} label={item.name} />
            ))}
          </>
        ) : null}
      </Stack>
    </ListItem>
  );
};

EmailHistory.propTypes = {
  batches: PropTypes.object,
  message: PropTypes.object,
};

export default EmailHistory;
