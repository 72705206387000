import React from 'react';
import { useParams } from 'react-router-dom';

import EventCreate from 'components/Event/EventCreate';
import { useGetEventDetails } from 'hooks/useGetLoadEventDetails';

const EventLoader = () => {
  const { id } = useParams<any>();

  const { data: savedEvent, isPending } = useGetEventDetails({ eventId: id });

  return <EventCreate isLoading={isPending} savedEventDetails={savedEvent} />;
};

export default EventLoader;
