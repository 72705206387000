import React, { useCallback, useEffect, useState } from 'react';
import { Prompt, useHistory, useLocation } from 'react-router-dom';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import * as _ from 'lodash';
import PropTypes from 'prop-types';

const UnsavedChangesModal = ({
  hasUnsavedChanges = false,
  onCancel,
  onConfirm,
  surveyTab,
  tryClose = false,
}) => {
  const [showingModal, setShowModal] = useState(false);
  const [lastLoc, setLocation] = useState(null);
  const [confirmLeave, setConfirm] = useState(false);
  const history = useHistory();
  const location = useLocation();

  const handleCancel = () => {
    setShowModal(false);
    if (onCancel) {
      onCancel();
    }
  };

  const handleConfirm = () => {
    setShowModal(false);
    setConfirm(true);
    if (onConfirm) {
      onConfirm();
    }
    if (lastLoc) {
      history.push(lastLoc.pathname);
    }
  };

  const handleNavBlocking = useCallback(
    (nextLoc) => {
      if (nextLoc.pathname !== location.pathname && !confirmLeave) {
        setShowModal(true);
        setLocation(nextLoc);
        return false;
      }
      return true;
    },
    [confirmLeave, location]
  );

  useEffect(() => {
    if (confirmLeave && surveyTab) {
      history.push({
        pathname: `/surveys`,
      });
    } else if (confirmLeave && lastLoc) {
      if (_.includes(lastLoc?.search, 'group_id')) {
        history.replace({
          pathname: lastLoc.pathname,
          search: lastLoc.search,
        });
      } else {
        history.push(lastLoc.pathname);
      }
      setConfirm(false);
    }
  }, [confirmLeave, history, lastLoc, surveyTab]);

  return (
    <>
      <Prompt message={handleNavBlocking} when={hasUnsavedChanges} />
      <Dialog
        aria-label='Unsaved Changes'
        onClose={handleCancel}
        open={showingModal || tryClose}
      >
        <DialogTitle>You have unsaved changes</DialogTitle>
        <DialogContent>
          Wait! You have unsaved changes. If you leave now, you&apos;ll lose
          these changes. Are you sure you&apos;d like to continue?
        </DialogContent>
        <DialogActions>
          <Button
            color='secondary'
            data-testid='back'
            onClick={handleCancel}
            variant='bordered'
          >
            Back
          </Button>
          <Button
            color='primary'
            data-testid='continue'
            onClick={handleConfirm}
            variant='contained'
          >
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

UnsavedChangesModal.propTypes = {
  hasUnsavedChanges: PropTypes.bool,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  surveyTab: PropTypes.any,
  tryClose: PropTypes.bool,
};
export default UnsavedChangesModal;
