import React, { useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Circle } from '@phosphor-icons/react';
import dayjs from 'dayjs';

import FtnDataGrid from 'components/shared/dataGrid/FtnDataGrid';
import { AuthContext } from 'contexts/AuthContext';
import { NpsAccent } from 'utils/nps';

const EventTable = ({ events, isLoading }) => {
  const {
    userProvider: { user },
  } = useContext(AuthContext);
  const history = useHistory();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleSelectionChange = (rowId) => {
    const selectedEvent = events?.find((evt) => evt.id === rowId[0]);
    if (user?.is_admin || selectedEvent.host_ids?.includes(user?.employee_id)) {
      history.push(`/events/${rowId[0]}/analytics`);
    }
  };

  const columns = [
    {
      field: 'name',
      flex: 2,
      headerName: 'Event name',
      minWidth: 200,
    },
    {
      align: 'right',
      field: 'invitees',
      flex: 1,
      headerAlign: 'right',
      headerName: 'Invited',
      minWidth: 80,
      type: 'number',
    },
    {
      align: 'right',
      field: 'nps',
      flex: 1,
      headerAlign: 'right',
      headerName: 'NPS score',
      minWidth: 80,
      renderCell: (params) => (
        <>
          {params.value ? (
            <Grid container>
              <Grid
                item
                sx={{
                  color: theme.palette[NpsAccent(params.value)]?.main,
                  textAlign: 'right',
                }}
                xs={6}
              >
                <Circle size={20} weight='fill' />
              </Grid>
              <Grid item sx={{ textAlign: 'right' }} xs={6}>
                {params.value}
              </Grid>
            </Grid>
          ) : (
            <Typography color='text.secondary' variant='overline'>
              &mdash;
            </Typography>
          )}
        </>
      ),
      type: 'number',
    },
    {
      align: 'right',
      field: 'rsvps',
      flex: 1,
      headerAlign: 'right',
      headerName: 'Accepted',
      minWidth: 80,
      type: 'number',
    },
    {
      align: 'right',
      field: 'attendees',
      flex: 1,
      headerAlign: 'right',
      headerName: 'Attended',
      minWidth: 80,
      type: 'number',
    },
    {
      align: isSmallScreen ? 'left' : 'right',
      field: 'date',
      flex: 2,
      headerAlign: isSmallScreen ? 'left' : 'right',
      headerName: 'Event date',
      minWidth: 180,
      type: 'dateTime',
      valueFormatter: ({ value }) =>
        value && dayjs(value).format(isSmallScreen ? 'MM/DD/YY LT' : 'LLL'),
      valueGetter: ({ value }) => value && new Date(value),
    },
  ];

  const data = useMemo(
    () =>
      events?.map((ev) => ({
        attendees: ev.attended_count,
        date: ev.start,
        has_nps: ev.survey_responses_count > 0,
        host_ids: ev.host_ids,
        id: ev.id,
        invitees: ev.invited_count,
        isClickable: user?.is_admin || ev.host_ids.includes(user?.employee_id),
        name: ev.name,
        nps:
          typeof ev.nps === 'number' && ev.nps !== 0
            ? ev.nps
            : ev.nps === 0 &&
              ev.survey_responses_count > 0 &&
              ev.nps_scores?.length > 0
            ? 0
            : null,
        responses: ev.survey_responses_count,
        rsvps: ev.rsvp_count,
      })),
    [events, user]
  );

  return (
    <FtnDataGrid
      columns={columns}
      data-testid='analytics-events-grid'
      isLoading={isLoading}
      isRowSelectable={(row) =>
        user?.is_admin || row?.host_ids?.includes(user?.employee_id)
      }
      noun='event'
      onRowSelectionModelChange={handleSelectionChange}
      rows={data}
      withLinearLoading
    />
  );
};

export default EventTable;
