import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { Plus } from '@phosphor-icons/react';
import PropTypes from 'prop-types';

import EventCard from 'components/Home/EventCard';
import GridCards from 'components/shared/GridCards';
import { axiosAuthenticated as axios } from 'utils/axios';

const GroupEvents = ({ group, user }) => {
  const history = useHistory();

  const [isLoading, setLoading] = useState(false);
  const [myEvents, setMine] = useState(null);
  const [pastEvents, setPast] = useState(null);
  const [tab, setTab] = useState('my');
  const [upcomingEvents, setUpcomming] = useState(null);

  const isMounted = useRef(true);

  useEffect(
    () => () => {
      isMounted.current = false;
    },
    []
  );

  const getEvents = useCallback(
    (type, setter) => {
      setLoading(true);
      axios(
        {
          method: 'get',
          url: `/api/group_events/${type}?group_id=${group.id}`,
        },
        (res) => {
          if (isMounted.current) {
            setter(res.data.events);
            setLoading(false);
          }
        },
        (err) => {
          console.error(err);
          if (isMounted.current) {
            setLoading(false);
          }
        }
      );
    },
    [group.id]
  );

  useEffect(() => {
    switch (tab) {
      case 'upcoming':
        if (!upcomingEvents) {
          getEvents('upcoming', setUpcomming);
        }
        break;
      case 'past':
        if (!pastEvents) {
          getEvents('past', setPast);
        }
        break;
      case 'my':
        if (!myEvents) {
          getEvents('mine', setMine);
        }
        break;
      default:
        break;
    }
  }, [tab, getEvents, upcomingEvents, pastEvents, myEvents]);

  const isAdmin = useMemo(
    () =>
      group && group.admin_employees?.some((emp) => emp.user_id === user.id),
    [group, user.id]
  );

  const groupEvents = useMemo(() => {
    switch (tab) {
      case 'my':
        return myEvents;
      case 'upcoming':
        return upcomingEvents;
      default:
        return pastEvents;
    }
  }, [myEvents, pastEvents, tab, upcomingEvents]);

  const canEdit = useMemo(
    () =>
      user.is_admin ||
      isAdmin ||
      (group.can_organizers_edit && user.is_organizer),
    [user, group, isAdmin]
  );

  const chunk = useMemo(
    () => ({
      hasMore: false,
      isLoading,
      values: groupEvents,
    }),
    [groupEvents, isLoading]
  );

  const eventCategories = ['Upcoming', 'Past', 'My'];

  return (
    <Stack direction='column' gap={2} mt={2}>
      <Stack
        alignItems='center'
        direction='row'
        gap={2}
        justifyContent='flex-start'
      >
        <Typography variant='h3'>Events</Typography>
        <ToggleButtonGroup
          aria-labelledby='group-events-filter-options'
          exclusive
          name='group-events-filter-options'
          onChange={(ev, val) => {
            if (val !== null) {
              setTab(val);
            }
          }}
          value={tab}
        >
          {eventCategories.map((category) => (
            <ToggleButton
              data-testid='TODO:DATA-TOGGLEBUTTON-53110'
              key={category}
              value={category.toLowerCase()}
            >
              {category}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
        {canEdit && (
          <Stack direction='row' flexGrow={1} justifyContent='flex-end'>
            <Button
              data-testid='create-group-event'
              onClick={() =>
                history.push(`/event/new/create?group_id=${group.id}`)
              }
              startIcon={<Plus size={16} />}
              sx={{ justifySelf: 'flex-end', whiteSpace: 'nowrap' }}
              variant='outlined'
            >
              Create group event
            </Button>
          </Stack>
        )}
      </Stack>
      <GridCards
        CardComponent={(e) => <EventCard event={e} key={e.id} user={user} />}
        chunk={chunk}
        isNarrow
        noDataText={
          tab === 'my'
            ? "You don't have any events tagged with this group"
            : `There are no ${tab} events for this group`
        }
        noun='group events'
      />
    </Stack>
  );
};

GroupEvents.propTypes = {
  group: PropTypes.shape({
    admin_employee_ids: PropTypes.array,
    admin_employees: PropTypes.array,
    can_organizers_edit: PropTypes.any,
    id: PropTypes.any,
  }),
  user: PropTypes.shape({
    id: PropTypes.any,
    is_admin: PropTypes.bool,
    is_organizer: PropTypes.bool,
  }),
};

export default GroupEvents;
