import React, {
  ElementType,
  FC,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Link,
  Stack,
  Switch,
  TextField,
  useTheme,
} from '@mui/material';
import {
  CheckCircle,
  FileCsv,
  LightbulbFilament,
  Trash,
} from '@phosphor-icons/react';
import { useFlag } from '@unleash/proxy-client-react';
import * as _ from 'lodash';
import { useSnackbar } from 'notistack';
import * as Papa from 'papaparse';

import FileUploader from 'components/shared/FileUploader';
import UploaderTable from 'components/shared/UploaderTable';
import { EmployeesContext } from 'contexts/EmployeesContext';
import { ListsContext } from 'contexts/ListsContext';
import { Employee } from 'types/Employee';
import { axiosAuthenticated as axios } from 'utils/axios';
import { flexColumn } from 'utils/styles';

interface CsvUploaderProps {
  dataFormatter?: (
    data: any[],
    eventId?: number,
    hasListName?: boolean,
    listName?: string
  ) => any;
  displayExtra?: boolean;
  eventId?: number;
  file?: string;
  hideUploadDetail?: boolean;
  instruction?: string;
  listNameRequired?: boolean;
  onUpload?: (employees: any[], list?: any, event?: any) => void;
  setIsLoading?: (isLoading: boolean) => void;
  toastMessage?: string;
  UploadButton: ElementType;
  uploadType?: string;
  url?: string;
}

const CsvUploader: FC<CsvUploaderProps> = ({
  dataFormatter,
  displayExtra,
  eventId,
  file,
  hideUploadDetail,
  instruction,
  listNameRequired,
  onUpload,
  setIsLoading,
  toastMessage,
  UploadButton,
  uploadType = 'contacts',
  url,
}) => {
  const { getAllEmployees, setCurrentPage } = useContext(EmployeesContext);
  const [lists, setLists] = useContext(ListsContext);

  const buttonRef = useRef(null);
  const scrollContainer = useRef<HTMLDivElement>(null);
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  const [employeesToAdd, setEmployeesToAdd] = useState<Employee[]>([]);
  const [fileName, setFileName] = useState('');
  const [fileSize, setFileSize] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [listName, setListName] = useState('');
  const [saveAsList, setSaveAsList] = useState(false);
  const [selected, setSelected] = useState<number[]>([]);
  const [showError, setShowError] = useState(false);
  const [isValidData, setIsValidData] = useState(false);

  const contactListEnabled = useFlag('contact-lists');

  const hasListName = useMemo(() => _.trim(listName)?.length > 0, [listName]);

  const toggleSaveAsList = (e: {
    target: { checked: boolean | ((prevState: boolean) => boolean) };
  }) => {
    setSaveAsList(e.target.checked);
  };

  const clearAll = () => {
    if (buttonRef.current) {
      (buttonRef.current as any).removeFile();
    }
    setEmployeesToAdd([]);
    setListName('');
  };

  const uploadUrl = () => url || '/api/employees/upload';

  const uploadData = () => {
    const validEmployees = employeesToAdd.filter(
      (employee) => employee !== null
    );
    if (dataFormatter) {
      return dataFormatter(validEmployees, eventId, hasListName, listName);
    } else {
      return {
        employees: validEmployees,
        save_as_segment: hasListName,
        segment_name: listName.trim(),
      };
    }
  };

  const showUploadError = () => {
    enqueueSnackbar(
      'We were unable to upload your CSV. Please check the format & try again!',
      { variant: 'error' }
    );
  };

  const uploadCsv = () => {
    const validEmployees = employeesToAdd.filter(
      (employee) => employee !== null
    );

    setIsLoading && setIsLoading(true);
    setIsUploading(true);
    axios(
      {
        data: uploadData(),
        method: 'post',
        timeout: 30000,
        url: uploadUrl(),
      },
      (res) => {
        const employeesData = res.data.employees
          ? _.toArray(res.data.employees)
          : [];

        if (employeesData.length > 0) {
          setCurrentPage(1);
          getAllEmployees({ page: 1 });
        }
        if (res.data.list) {
          setLists(
            _.chain(lists).unionBy([res.data.list], 'id').sortBy('id').value()
          );
        }
        const failedEmployees =
          validEmployees.length - (res.data.employees || []).length;
        if (failedEmployees > 0) {
          enqueueSnackbar(
            `${failedEmployees} contact(s) couldn't be uploaded due to missing or invalid data.`,
            { variant: 'warning' }
          );
        }
        onUpload && onUpload(employeesData, res.data.list, res.data.event);
        enqueueSnackbar(
          toastMessage ||
            `Your contacts were saved${
              hasListName ? ' into a contact list' : ''
            }.`,
          { variant: 'success' }
        );
        setIsOpen(false);
        clearAll();
        setIsLoading && setIsLoading(false);
        setIsUploading(false);
      },
      (error) => {
        showUploadError();
        setIsLoading && setIsLoading(false);
        setIsUploading(false);
        console.error(error);
      }
    );
  };

  const handleParsedFile = (parsedFile: Papa.ParseResult<unknown>) => {
    setEmployeesToAdd([]);

    let employeesToBeAdded: Employee[] = [];
    const employeeColumns = ['id', 'email', 'first_name', 'last_name'];

    if (parsedFile.data && parsedFile.data.length > 0) {
      const parsedData: { [key: string]: any }[] = parsedFile.data as {
        [key: string]: any;
      }[];
      const cols = Object.keys(parsedData[0]);
      const colMap = new Map<string, string>();
      cols.forEach((colName) => {
        const col = colName.toLowerCase();
        employeeColumns.forEach((ec) => {
          const ecString = ec.includes('_') ? ec.split('_')[0] : ec;
          if (col.includes(ecString)) {
            colMap.set(ec, colName);
          }
        });
      });

      employeesToBeAdded = parsedData
        .map((employee, index) => {
          const employeeEntry: Employee = {
            email: '',
            id: index + 1,
          };
          employeeColumns.forEach((ec) => {
            const colName = colMap.get(ec);
            if (colName) {
              const value = employee[colName];
              if (
                value !== undefined &&
                value !== null &&
                value.trim() !== ''
              ) {
                employeeEntry[ec] = value;
              }
            }
          });
          return employeeEntry;
        })
        .filter((employee) => employee.email && employee.email.includes('@'));
    }

    setIsValidData(employeesToBeAdded.length > 0);
    setEmployeesToAdd(_.uniqBy(employeesToBeAdded, 'email'));
    setSelected(_.uniqBy(employeesToBeAdded, 'email').map((e) => Number(e.id)));
    const invalidCount = parsedFile.data.length - employeesToBeAdded.length;
    if (invalidCount > 0) {
      enqueueSnackbar(
        `${invalidCount} invalid entries were found and will be skipped.`,
        { variant: 'warning' }
      );
    }
  };

  const parseFile = (files: any[]) => {
    setFileName(files[0].name);
    setFileSize(files[0].size);
    Papa.parse(files[0], {
      complete: (data) => handleParsedFile(data),
      header: true,
      skipEmptyLines: true,
    });
  };

  const hasError = useMemo(
    () => saveAsList && listName?.length < 1,
    [saveAsList, listName]
  );

  const value = useMemo(
    () => employeesToAdd?.map((a) => a?.id),
    [employeesToAdd]
  );

  const handleSelections = (sel: (string | number)[]) => {
    const selectedEmployees =
      employeesToAdd?.filter((emp) => sel.includes(emp.id)) ?? [];

    const selectedIds = selectedEmployees
      .map((emp) =>
        typeof emp.id === 'number' ? emp.id : parseInt(emp.id, 10)
      )
      .filter((id) => !isNaN(id));

    setSelected(selectedIds);
  };

  const fileSizeText = useMemo(() => {
    const isLarge = fileSize && fileSize > 1000000;
    const size = isLarge ? fileSize / 1000000 : fileSize / 1000;
    if (size) {
      return ` (${size} ${isLarge ? 'MB' : 'KB'})`;
    } else {
      return '';
    }
  }, [fileSize]);

  return (
    <>
      <UploadButton onClick={() => setIsOpen(true)} />
      <Dialog
        aria-label='Upload CSV'
        fullWidth
        maxWidth='md'
        onClose={() => {
          clearAll();
          setIsOpen(false);
        }}
        open={isOpen}
      >
        <DialogTitle>Upload {uploadType}</DialogTitle>
        <DialogContent ref={scrollContainer} sx={{ ...flexColumn, gap: 2 }}>
          <DialogContentText>
            {
              instruction ||
                `Add guests to your invitation by uploading a CSV below. All new guests recognized on the CSV will be added to your contacts and selected to receive this invitation.`
              /*  `You can upload ${uploadType}s from a CSV with the following columns:` */
            }
          </DialogContentText>
          <Collapse
            in={!employeesToAdd || employeesToAdd?.length === 0}
            timeout={700}
            unmountOnExit
          >
            <Stack direction='column' gap={3}>
              <Alert
                data-testid='TODO:DATA-ALERT-42231'
                icon={<LightbulbFilament size={40} weight='fill' />}
                severity='info'
              >
                <DialogContentText>
                  Be sure to include separate columns for first name, last name,
                  and email or download and use our{' '}
                  <Link
                    data-testid='sample-csv-template.'
                    href={file || '/files/Sample CSV - Contact Upload.csv'}
                    rel='noreferrer'
                    target='_blank'
                    type='text'
                  >
                    sample CSV template.
                  </Link>
                </DialogContentText>
              </Alert>
              <FileUploader Icon={FileCsv} noun='CSV' onDrop={parseFile} />
            </Stack>
          </Collapse>
          {employeesToAdd?.length > 0 && (
            <>
              <Stack
                alignItems='center'
                direction='row'
                display='flex'
                gap={1}
                // Add this line}
                justifyContent='space-between'
              >
                <TextField
                  data-testid='TODO:DATA-TEXTFIELD-23319'
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <CheckCircle
                        color={theme.palette.success.main}
                        size={24}
                        weight='fill'
                      />
                    ),
                    readOnly: true,
                    startAdornment: <FileCsv size={24} />,
                  }}
                  value={`${fileName}${fileSizeText}`}
                />
                <IconButton
                  color='secondary'
                  data-testid='TODO:DATA-ICONBUTTON-18888'
                  onClick={() => clearAll()}
                >
                  <Trash size={24} />
                </IconButton>
              </Stack>

              {/* // sx={{ px: 0 }}
              // titleTypographyProps={{
              //   variant: 'body2',
              //   justifyItems: 'center',
              // }} */}
              {displayExtra || !hideUploadDetail ? (
                <UploaderTable
                  disable={false}
                  isSelectable
                  onChange={(selected) => handleSelections(selected)}
                  options={employeesToAdd}
                  showInvitedAsFixed
                  value={value}
                />
              ) : null}
              {contactListEnabled && (
                <Stack direction='column'>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={saveAsList}
                        data-testid='TODO:DATA-SWITCH-35101'
                        inputProps={{
                          'aria-label': 'save-to-contact-list' || 'switch',
                        }}
                        onChange={toggleSaveAsList}
                      />
                    }
                    label='Save uploaded contacts to a contact list'
                    sx={{ ml: 0 }}
                  />
                  <Collapse
                    addEndListener={() => {
                      if (saveAsList) {
                        setTimeout(
                          () =>
                            scrollContainer.current?.scrollBy({
                              behavior: 'smooth',
                              top: 500,
                            }),
                          200
                        );
                      }
                    }}
                    in={saveAsList}
                    unmountOnExit
                  >
                    <TextField
                      data-testid='TODO:DATA-TEXTFIELD-37545'
                      error={showError && hasError}
                      fullWidth
                      helperText={
                        showError && hasError && 'This field is required'
                      }
                      onChange={(e) => setListName(e.target.value)}
                      placeholder='Contact list name'
                      required={saveAsList}
                      value={listName}
                    />
                  </Collapse>
                </Stack>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            data-testid='cancel'
            onClick={() => {
              clearAll();
              setIsOpen(false);
            }}
            variant='text'
          >
            Cancel
          </Button>
          <LoadingButton
            color='primary'
            data-testid='upload-csv'
            disabled={!isValidData || (hasListName && listName?.length === 0)}
            loading={isUploading}
            onClick={() => {
              if (!isValidData) {
                enqueueSnackbar('Please upload a valid CSV file.', {
                  variant: 'error',
                });
                return;
              }
              if (listNameRequired && !listName) {
                setShowError(true);
              } else {
                setShowError(false);
                uploadCsv();
              }
            }}
            variant='contained'
          >
            Upload CSV
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CsvUploader;
