import React from 'react';

import EventView from 'components/Events/Controls/Shared/EventView';
import { EventWrapper } from 'components/Events/EventWrapper';
import { PublicEventProvider } from 'components/Events/PublicEvent/PublicEventContext';

const PublicEvent = () => {
  return (
    <PublicEventProvider>
      <EventWrapper>
        <EventView isPublic />
      </EventWrapper>
    </PublicEventProvider>
  );
};

export default PublicEvent;
