import React, { useContext, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { Building } from '@phosphor-icons/react';
import pluralize from 'pluralize';

import AutocompleteWithAvatars from 'components/shared/AutocompleteWithAvatars';
import SummaryField from 'components/shared/SummaryField';
import { OrganizationContext } from 'contexts/OrganizationContext';
import { EventDetailsFormControl } from 'types/EventDetailsForm';

interface EventOfficesFieldProps {
  control: EventDetailsFormControl;
  isLoading?: boolean;
}

const EventOfficesField = ({
  control,
  isLoading = false,
}: EventOfficesFieldProps) => {
  const [org] = useContext(OrganizationContext);

  const options = useMemo(
    () =>
      org && org?.offices && org?.offices?.length > 0
        ? org?.offices
            ?.sort((a: any, b: any) => a?.name?.localeCompare(b?.name))
            ?.map((opt: any) => ({
              id: opt?.id,
              label: opt?.name,
              value: opt?.id,
            }))
        : [],
    [org]
  );

  const onChange = (field: any, value: any) => {
    const vals = Array.isArray(value) ? value : [];

    field.onChange(vals?.map((opt: any) => opt.value));
  };

  return (
    <Controller
      control={control}
      name='officeIds'
      render={({ field }) => {
        const value = [
          ...(options && options?.length > 0
            ? options.filter((opt: any) => field?.value?.includes(opt.value))
            : []),
        ];
        return (
          <SummaryField
            component={
              <>
                {options?.length > 0 ? (
                  <AutocompleteWithAvatars
                    hasItemCountAsValue
                    isOptionEqualToValue={(opt: any, value: any) =>
                      opt?.value === value?.value
                    }
                    onChange={(_, value) => onChange(field, value)}
                    options={options}
                    value={value}
                  />
                ) : null}
              </>
            }
            Icon={Building}
            isLoading={isLoading}
            primary='Offices'
            value={
              value?.length > 0
                ? pluralize('office', value?.length, true)
                : null
            }
            valueType='none'
          />
        );
      }}
    />
  );
};

export default EventOfficesField;
