import React, { useContext } from 'react';
import { Autocomplete, Skeleton } from '@mui/material';
import PropTypes from 'prop-types';

import { ConditionalLoader } from 'components/Core/Loader';
import AutocompleteCheckboxOption from 'components/shared/AutocompleteCheckboxOption';
import { AutocompleteRenderInput } from 'components/shared/AutocompleteRenderInput';
import { OrganizationContext } from 'contexts/OrganizationContext';

const OfficesAutocomplete = ({
  forFilter = false,
  fullWidth = false,
  isDisabled = false,
  maxTags = -1,
  onChange,
  sx = {},
  value = [],
}) => {
  const [organization] = useContext(OrganizationContext);

  const options =
    organization && organization?.offices && organization?.offices?.length > 0
      ? organization?.offices
          ?.sort((a, b) => a?.name?.localeCompare(b?.name))
          ?.map(({ employee_ids, ...o }) => ({
            employee_ids,
            label: o?.name,
            value: o?.id,
          }))
      : [];

  return (
    <ConditionalLoader
      conditions={[!organization, !options]}
      loaderComponent={<Skeleton height={44} variant='rounded' width={200} />}
    >
      <Autocomplete
        autoHighlight
        disableCloseOnSelect
        disabled={isDisabled}
        fullWidth={fullWidth}
        id='select-offices'
        limitTags={maxTags}
        multiple
        onChange={onChange}
        openOnFocus
        options={options}
        renderInput={(params) => (
          <AutocompleteRenderInput
            forFilter={forFilter}
            label='Office'
            params={params}
            value={value}
          />
        )}
        renderOption={(props, option, state, ownerState) => (
          <AutocompleteCheckboxOption
            option={option}
            ownerState={ownerState}
            props={props}
            state={state}
          />
        )}
        slotProps={{
          popper: {
            placement: 'bottom-start',
            style: { width: 'fit-content' },
          },
        }}
        sx={sx}
        value={options?.filter((e) => value?.includes(e.value))}
      />
    </ConditionalLoader>
  );
};

OfficesAutocomplete.propTypes = {
  forFilter: PropTypes.bool,
  fullWidth: PropTypes.bool,
  isDisabled: PropTypes.bool,
  maxTags: PropTypes.number,
  onChange: PropTypes.func,
  sx: PropTypes.object,
  value: PropTypes.array,
};

export default OfficesAutocomplete;
