import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@mui/material';
import { Plus } from '@phosphor-icons/react';
import { useFlag } from '@unleash/proxy-client-react';

import GroupCreateDialog from 'components/Groups/GroupCreateDialog';

interface Props {
  canCreate: boolean;
}

const GroupCreate = ({ canCreate }: Props) => {
  const isSlackGroupSyncEnabled = useFlag('group-slack-synced-membership');
  const history = useHistory();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleNewGroup = useCallback(() => {
    if (isSlackGroupSyncEnabled) setIsDialogOpen(true);
    else history.push('/groups/new');
  }, [history, isSlackGroupSyncEnabled]);

  return (
    <>
      {canCreate && (
        <>
          <Button
            data-testid='new-group'
            onClick={handleNewGroup}
            startIcon={<Plus />}
            variant='contained'
          >
            New group
          </Button>
          <GroupCreateDialog
            close={() => setIsDialogOpen(false)}
            isOpen={isDialogOpen}
          />
        </>
      )}
    </>
  );
};

export default GroupCreate;
