import React, { useEffect, useMemo, useState } from 'react';
import {
  alpha,
  Chip,
  Divider,
  FormLabel,
  Stack,
  Typography,
} from '@mui/material';
import * as _ from 'lodash';

import RsvpButtons from 'components/Events/Controls/Rsvp/RsvpButtons';
import PreviewQuestion from 'components/Events/Controls/Shared/PreviewQuestion';
import StandaloneSurveyHeader from 'components/Events/Controls/Shared/StandaloneSurveyHeader';
import { ManagerContext } from 'components/Events/Manager/ManagerContext';
import { PublicEventContext } from 'components/Events/PublicEvent/PublicEventContext';
import { SurveyResponseContext } from 'components/Survey/SurveyResponseContext';
import SurveyUnavailable from 'components/Survey/SurveyUnavailable';
import { NpsOptions, SurveyQuestionTypeMap } from 'constants/survey.constants';
import { SurveyTemplatesContext } from 'contexts/SurveyTemplatesContext';
import { DefaultQuestion } from 'utils/survey';
import { toHtmlId } from 'utils/text';
import { useContextWithFallback } from 'utils/utils';

const SurveyQuestionList = ({
  canSeePreSurvey,
  defaultQuestions = false,
  emailOnly = false,
  eventAvailable,
  handleChange,
  hasAnsweredSurvey,
  hasValidEmail,
  inDialog,
  isPreEvent,
  isPreview,
  onGuestList,
  options,
  questions,
  setLeftEmailField,
  showRSVP = false,
  showSummaries = false,
  summarySx,
  type,
}) => {
  const {
    actions: { applySingle, handleRsvpClick },
    state: {
      event,
      inEditorContext,
      inManagerContext,
      inPublicEventContext,
      inSurveyResponseContext,
      rsvpStatus,
      surveyJustSubmitted,
      surveyResponses,
      surveys,
    },
  } = useContextWithFallback(
    ManagerContext,
    PublicEventContext,
    SurveyResponseContext,
    SurveyTemplatesContext
  );
  const [responses, setResponses] = useState(
    inSurveyResponseContext && isPreEvent ? { ...surveyResponses.pre } : {}
  );

  useEffect(() => {
    handleChange && handleChange(responses);
  }, [handleChange, responses]);

  const showRSVPEmailQuestion = useMemo(
    () =>
      surveys?.pre_event_survey?.survey_questions?.find(
        (question) => question.id === 'email'
      ),
    [surveys?.pre_event_survey?.survey_questions]
  );

  const surveyQuestions = useMemo(
    () =>
      isPreview
        ? DefaultQuestion('public_pre_survey')
        : showRSVP
        ? surveys?.pre_event_survey?.survey_questions?.filter(
            (question) => question.id !== 'email'
          )
        : questions ||
          _.get(surveys, `${type}.survey_questions`) ||
          event[type].survey_questions,
    [event, isPreview, questions, showRSVP, surveys, type]
  );

  const sortedQuestions = useMemo(
    () =>
      surveyQuestions?.sort(
        (a, b) => _.toNumber(a.position) - _.toNumber(b.position)
      ),
    [surveyQuestions]
  );

  const validQuestions = useMemo(
    () => surveyQuestions?.filter((question) => question?._destroy !== 1),
    [surveyQuestions]
  );

  const surveyOptions = useMemo(
    () => options || _.get(surveys, `${type}.survey_options`),
    [options, surveys, type]
  );

  const validOptions = useMemo(
    () => surveyOptions?.filter((option) => option?._destroy !== 1),
    [surveyOptions]
  );

  const handleSupplyResponse = (questionId, response) => {
    responses[questionId] = response;
    const resp = { ...responses, [questionId]: response };
    setResponses(resp);

    if (inManagerContext || inPublicEventContext || inSurveyResponseContext) {
      const surveyResponses = _.get(`surveys.${type}.responses`);
      applySingle(
        surveyResponses ? _.concat(surveyResponses, responses) : responses,
        `surveys.${type}.responses`
      );
    }
  };

  const canSeeSurvey = useMemo(
    () =>
      isPreview ||
      canSeePreSurvey ||
      (!showRSVP &&
        inSurveyResponseContext &&
        onGuestList &&
        !surveyJustSubmitted) ||
      inManagerContext ||
      inPublicEventContext ||
      inEditorContext,
    [
      canSeePreSurvey,
      inEditorContext,
      inManagerContext,
      inPublicEventContext,
      inSurveyResponseContext,
      isPreview,
      onGuestList,
      showRSVP,
      surveyJustSubmitted,
    ]
  );

  const startingPosition = useMemo(
    () =>
      Math.min(
        sortedQuestions
          ?.filter((q) => q?.id !== 'email')
          ?.map((q) => q?.position)
      ) || 0,
    [sortedQuestions]
  );

  return (
    <Stack
      alignItems='stretch'
      direction='column'
      gap={showSummaries ? 0 : isPreview ? 1 : 2}
      p={0}
    >
      {showRSVP && eventAvailable ? (
        <Stack direction='column' gap={0.5}>
          <PreviewQuestion
            handleSupplyResponse={handleSupplyResponse}
            key={`${
              showRSVPEmailQuestion?.id
            }-${showRSVPEmailQuestion?.title?.replace(' ', '-')}`}
            noOrder
            question={showRSVPEmailQuestion}
            responses={responses}
            setLeftEmailField={setLeftEmailField}
          />
          <Stack direction='column'>
            <FormLabel required>Will you attend this event?</FormLabel>
            <RsvpButtons
              areDisabled={!hasValidEmail}
              handleSelection={handleRsvpClick}
              statusId={rsvpStatus}
            />
          </Stack>
          {canSeeSurvey && (
            <StandaloneSurveyHeader
              hasDescription={
                surveys?.pre_event_survey?.description?.length > 0
              }
              isPreEvent
            />
          )}
        </Stack>
      ) : null}
      {canSeeSurvey
        ? sortedQuestions
            ?.filter((question) =>
              emailOnly
                ? question?.title.toLowerCase().indexOf('email') !== -1
                : true
            )
            ?.map((question, index) => ({
              ...question,
              position: emailOnly
                ? 1
                : startingPosition === -1
                ? index + 2
                : index + 1,
              typeConfig: SurveyQuestionTypeMap?.get(question?.question_type),
            }))
            ?.map((question, index) =>
              question ? (
                <React.Fragment
                  key={`${question?.id}-${toHtmlId(question?.title)}`}
                >
                  {showSummaries ? (
                    <>
                      <Stack
                        alignItems='flex-start'
                        direction='row'
                        sx={summarySx || { p: 2 }}
                        width='100%'
                      >
                        <Typography
                          color='text.primary'
                          flexShrink={0}
                          variant='body2'
                          width={24}
                        >
                          {`${question.position}.`}
                        </Typography>
                        <Stack direction='column' flexGrow={1} gap={1}>
                          <Typography
                            color='text.primary'
                            component='div'
                            variant='body2'
                          >
                            {question?.title}
                            {question?.required ? (
                              <Typography
                                color='error'
                                display='inline'
                                variant='inherit'
                              >
                                &thinsp;&#42;
                              </Typography>
                            ) : null}
                          </Typography>
                          {question?.question_type === 'nps' ? (
                            <Typography color='grey.600' variant='body1'>
                              {NpsOptions.map((o) => o.label).join(', ')}
                            </Typography>
                          ) : validOptions.filter(
                              (o) => o?.survey_question_id === question?.id
                            )?.length > 0 ? (
                            <Typography color='grey.600' variant='body1'>
                              {validOptions
                                .filter(
                                  (o) => o?.survey_question_id === question?.id
                                )
                                .map((o) => o.text)
                                .join(', ')}
                            </Typography>
                          ) : null}
                        </Stack>
                        <Chip
                          icon={question?.typeConfig?.surveyIcon}
                          label={question?.typeConfig?.label}
                          sx={{
                            bgcolor: alpha(
                              question?.typeConfig?.colors?.light,
                              0.5
                            ),
                            color: question?.typeConfig?.colors?.dark,
                            ml: 2,
                          }}
                        />
                      </Stack>
                      <Divider />
                    </>
                  ) : (
                    <PreviewQuestion
                      displayPosition={question.position}
                      handleSupplyResponse={handleSupplyResponse}
                      key={`${question?.id}-${toHtmlId(question?.title)}`}
                      noOrder={defaultQuestions}
                      options={validOptions}
                      previewTemplate={isPreview}
                      question={question}
                      questionIndex={index}
                      responses={responses}
                      setLeftEmailField={setLeftEmailField}
                      surveyType={type}
                    />
                  )}
                </React.Fragment>
              ) : null
            )
        : (!inDialog || inDialog === undefined) && (
            <SurveyUnavailable
              eventAvailable={eventAvailable}
              hasAnsweredSurvey={hasAnsweredSurvey}
              isOrgLevelEvent={event?.privacy === 'organization'}
              isPreEvent={isPreEvent}
              onGuestList={onGuestList}
              rsvpStatus={rsvpStatus}
              surveyJustSubmitted={surveyJustSubmitted}
            />
          )}
    </Stack>
  );
};

export default SurveyQuestionList;
