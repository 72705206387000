import React from 'react';
import { useHistory } from 'react-router-dom';
import { Typography } from '@mui/material';

const AuthOktaSaml = () => {
  const history = useHistory(); // use useHistory inside the component

  const hash = window.location.hash;
  const queryIndex = hash.indexOf('?');
  let query = '';

  if (queryIndex !== -1) {
    query = hash.substring(queryIndex + 1);
  }

  const params = new URLSearchParams(query);
  const newToken = params.get('token');

  if (newToken) {
    localStorage.setItem('token', newToken);
    history.push('/events'); // Just push the path without hash part
  } else {
    return (
      <Typography>
        Something went wrong, please contact your Administrator if the problem
        continues.
      </Typography>
    );
  }
};

export default AuthOktaSaml;
