/* eslint-disable react/no-multi-comp */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useContext, useMemo, useRef, useState } from 'react';
import { useCSVReader } from 'react-papaparse';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  useTheme,
} from '@mui/material';
import { FileCsv, MinusCircle } from '@phosphor-icons/react';
import { useFlag } from '@unleash/proxy-client-react';
import * as _ from 'lodash';
import { useSnackbar } from 'notistack';
import * as Papa from 'papaparse';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';

import Dropdown from 'components/Core/Functional/Dropdown';
import { ConditionalLoader } from 'components/Core/Loader';
import FileUploader from 'components/shared/FileUploader';
import { EmployeesContext } from 'contexts/EmployeesContext';
import { ListsContext } from 'contexts/ListsContext';
import { axiosAuthenticated as axios } from 'utils/axios';
import { RolesSelectOptions } from 'utils/user';

/**
 * Renders <CSVUploadModal /> component
 * @param {object} props
 * @param {boolean} props.show True, if the modal should be shown
 * @param {Function} props.onClose modal close callback
 * @param {Function} [props.onUpload] upload callback
 * @param {boolean} [props.noSegment] True, if saving as a segment should be disallowed
 * @param {string} [props.eventId] Only use if googleMeet is true, identifier of the event we are working with
 * @param {string} [props.instruction] note to user on the information needed in the csv file
 * @param {string} [props.file] file path for downloading sample csv
 * @param {Function} [props.dataFormatter] format upload data for api call
 * @param {string} [props.url] url for api call if different from "/api/employees/upload"
 * @param {boolean} [props.displayExtra] True, if csv reader needs to display the NameRoleList
 * @param {string} [props.toastMessage] specific message for success toast
 * @param {boolean} [props.hideUploadDetail] True, if do not wish to display name/email or any other csv details in the csv reader
 * @param {boolean} [props.setIsLoading] True if wish to set a loader for when uploading a large file. The parent component should handle the conditional loader
 * @param {array} [props.condition]s array of conditions to be pass to conditional loader
 */
const CSVUploadModal = ({
  contactListNameRequired,
  dataFormatter,
  displayExtra,
  eventId,
  file,
  hideUploadDetail,
  instruction,
  noSegment,
  onClose,
  onUpload,
  setIsLoading,
  show,
  showRoleColumn,
  toastMessage,
  uploadType = 'contact',
  url,
}) => {
  const { employees, setEmployees } = useContext(EmployeesContext);
  const [lists, setLists] = useContext(ListsContext);
  const contactListEnabled = useFlag('contact-lists');

  const { CSVReader } = useCSVReader();
  const buttonRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  const [CSVIsUploading, setCSVIsUploading] = useState(false);
  const [employeesToAdd, setEmployeesToAdd] = useState([]);
  const [fileName, setFileName] = useState('');
  const [removeHoverColor, setRemoveHoverColor] = useState(
    theme.palette.grey[300]
  );
  const [segmentName, setSegmentName] = useState('');
  const [showError, setShowError] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [zoneHover, setZoneHover] = useState(false);
  const [isValidData, setIsValidData] = useState(false);

  const styles = {
    borderRadius: 4,
    default: {
      borderColor: theme.palette.grey[500],
    },
    file: {
      background: 'none',
      borderRadius: 0,
      display: 'flex',
      flexDirection: 'column',
      height: 40,
      justifyContent: 'center',
      position: 'relative',
      width: 'auto',
      zIndex: 10,
    },
    fileNameInfo: {
      background: 'none',
    },
    fileSizeInfo: {
      display: 'none',
    },
    info: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: 10,
      paddingRight: 10,
    },
    name: {
      backgroundColor: 'none',
      borderRadius: 0,
      fontSize: 14,
      marginBottom: '0.5em',
    },
    progressBar: {
      bottom: 14,
      paddingLeft: 10,
      paddingRight: 10,
      position: 'absolute',
      width: '100%',
    },
    remove: {
      height: 23,
      position: 'absolute',
      right: 6,
      top: 6,
      width: 23,
    },
    zone: {
      alignItems: 'center',
      border: `2px dashed ${theme.palette.grey[500]}`,
      borderRadius: 1,
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'center',
      padding: 20,
    },
    zoneHover: {
      borderColor: theme.palette.primary.main,
    },
  };

  const saveAsSegment = useMemo(
    () => _.trim(segmentName).length > 0,
    [segmentName]
  );

  const clearAll = () => {
    if (buttonRef.current) {
      buttonRef.current.removeFile();
    }
    setEmployeesToAdd([]);
    setSegmentName('');
  };

  const uploadUrl = () => url || '/api/employees/upload';

  const uploadData = () => {
    const validEmployees = employeesToAdd.filter(
      (employee) => employee !== null
    );
    if (dataFormatter) {
      return dataFormatter(validEmployees, eventId, saveAsSegment, segmentName);
    } else {
      return {
        employees: validEmployees.map((employee) => ({
          email: employee.email,
          first_name: employee.first_name,
          last_name: employee.last_name,
          role: employee.role || '',
        })),
        save_as_segment: saveAsSegment,
        segment_name: segmentName.trim(),
      };
    }
  };

  const uploadCSV = () => {
    const validEmployees = employeesToAdd.filter(
      (employee) => employee !== null
    );

    setIsLoading && setIsLoading(true);
    setCSVIsUploading(true);
    axios(
      {
        data: uploadData(),
        method: 'post',
        timeout: 30000,
        url: uploadUrl(),
      },
      (res) => {
        if (res.data.employees) {
          setEmployees(
            _.chain(employees)
              .unionBy(res.data.employees, 'id')
              .sortBy('email')
              .value()
          );
        }
        if (res.data.list) {
          setLists(
            _.chain(lists).unionBy([res.data.list], 'id').sortBy('id').value()
          );
        }
        const failedEmployees =
          validEmployees.length - (res.data.employees || []).length;
        if (failedEmployees > 0) {
          enqueueSnackbar(
            `${failedEmployees} contact(s) couldn't be uploaded due to missing or invalid data.`,
            { variant: 'warning' }
          );
        }
        onUpload && onUpload(res.data.employees, res.data.list, res.data.event);
        onClose && onClose();
        clearAll();
        setIsLoading && setIsLoading(false);
        setCSVIsUploading(false);
        enqueueSnackbar(
          toastMessage ||
            `Your contacts were saved${
              saveAsSegment ? ' into a contact list' : ''
            }.`,
          { variant: 'success' }
        );
      },
      (error) => {
        showUploadError();
        setIsLoading && setIsLoading(false);
        setCSVIsUploading(false);
        console.error(error);
      }
    );
  };

  // eslint-disable-next-line no-unused-vars
  const handleOpenDialog = (e) => {
    if (buttonRef.current) {
      buttonRef.current.open(e);
    }
  };

  const parseFile = (files) => {
    setFileName(files[0].name);
    Papa.parse(files[0], {
      complete: (data) => handleOnFileLoad(data),
      header: true,
      skipEmptyLines: true,
    });
  };

  const handleOnFileLoad = (parsedFile) => {
    setEmployeesToAdd([]);
    let employeesToBeAdded = [];
    const employeeColumns = ['email', 'first_name', 'last_name', 'role'];
    const invalidEntries = [];

    if (parsedFile.data) {
      const parsedData = parsedFile.data;
      const cols = Object.keys(parsedData[0]);
      const colMap = new Map();
      cols.forEach((colName) => {
        const col = colName.toLowerCase();
        employeeColumns.forEach((ec) => {
          const ecString = ec.includes('_') ? ec.split('_')[0] : ec;
          if (col.includes(ecString)) {
            colMap.set(ec, colName);
          }
        });
      });
      employeesToBeAdded = parsedData
        .map((employee) => {
          const employeeEntry = {};
          employeeColumns.forEach((ec) => {
            const colName = colMap.get(ec);
            if (colName) {
              const value = employee[colName];
              if (
                value !== undefined &&
                value !== null &&
                value.trim() !== ''
              ) {
                employeeEntry[ec] = value;
              }
            }
          });
          if (!employeeEntry.email || !employeeEntry.email.includes('@')) {
            invalidEntries.push(employee);
            return null;
          }
          return employeeEntry;
        })
        .filter((employee) => employee !== null);
    }

    const invalidCount = parsedFile.data.length - employeesToBeAdded.length;
    if (invalidCount > 0) {
      enqueueSnackbar(
        `${invalidCount} invalid entries were found and will be skipped.`,
        { variant: 'warning' }
      );
    }

    setIsValidData(employeesToBeAdded.length > 0);
    setEmployeesToAdd(_.uniqBy(employeesToBeAdded, 'email'));
  };

  const showUploadError = () => {
    enqueueSnackbar(
      'We were unable to upload your CSV. Please check the format & try again!',
      { variant: 'error' }
    );
  };

  const handleRoleUpdate = ({ id, role }) => {
    setEmployeesToAdd(
      employeesToAdd?.map((emp, i) => (i === id ? { ...emp, role } : emp))
    );
  };

  const handleEmployeeRemove = (ind) => {
    setEmployeesToAdd(employeesToAdd?.filter((emp, index) => index !== ind));
  };

  const emailCount = useMemo(
    () =>
      employeesToAdd?.filter((emp) => emp?.email?.trim()?.length > 0).length,
    [employeesToAdd]
  );

  const hasError = useMemo(
    () => contactListNameRequired && segmentName?.length < 1,
    [contactListNameRequired, segmentName]
  );

  return (
    <Dialog
      aria-label='Upload CSV'
      fullWidth
      maxWidth='md'
      onClose={() => {
        clearAll();
        onClose && onClose();
      }}
      open={show}
    >
      <ConditionalLoader
        conditions={[CSVIsUploading]}
        inline
        text='Uploading file...'
      >
        <DialogTitle>Upload {uploadType}s</DialogTitle>
        <DialogContent>
          <Collapse
            in={!employeesToAdd || employeesToAdd.length === 0}
            timeout={700}
            unmountOnExit
          >
            <>
              <DialogContentText>
                {instruction ||
                  `You can upload ${uploadType}s from a CSV with the following columns:`}
              </DialogContentText>
              <Card
                data-testid='TODO:DATA-CARD-34253'
                sx={{ mb: 3 }}
                variant='outlined'
              >
                <TableContainer>
                  <Table size='small'>
                    <TableHead>
                      <TableRow>
                        <TableCell>First name</TableCell>
                        <TableCell>Last name</TableCell>
                        <TableCell>Email</TableCell>
                        {showRoleColumn && <TableCell>Role</TableCell>}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>Pat</TableCell>
                        <TableCell>Williams</TableCell>
                        <TableCell>pat.williams@myorg.com</TableCell>
                        {showRoleColumn && <TableCell>Organizer</TableCell>}
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Card>
              <FileUploader Icon={FileCsv} noun='CSV' onDrop={parseFile} />
            </>
          </Collapse>
          {employeesToAdd?.length > 0 && (
            <Card data-testid='TODO:DATA-CARD-58148' elevation={0}>
              <CardHeader
                action={
                  <Button
                    color='error'
                    data-testid='remove-file'
                    onClick={() => clearAll()}
                    variant='text'
                  >
                    Remove file
                  </Button>
                }
                subheader={`${pluralize('valid contact', emailCount, true)}
                 to be uploaded`}
                sx={{ px: 0 }}
                title={fileName}
              />
              <CardContent sx={{ p: 0 }}>
                <Box border={`1px solid ${theme.palette.grey[300]}`} mb={2}>
                  {displayExtra ? (
                    <NameRoleList
                      emailCount={emailCount}
                      employeesToAdd={employeesToAdd}
                      handleEmployeeRemove={handleEmployeeRemove}
                      handleRoleUpdate={handleRoleUpdate}
                    />
                  ) : (
                    <>
                      {!hideUploadDetail && (
                        <NameEmailList employeesToAdd={employeesToAdd} />
                      )}
                    </>
                  )}
                </Box>
                {!noSegment && contactListEnabled && (
                  <TextField
                    error={showError && hasError}
                    fullWidth
                    helperText={
                      showError && hasError
                        ? 'This field is required'
                        : 'Save these contacts to a Contact List'
                    }
                    label='Contact list name'
                    onChange={(e) => setSegmentName(e.target.value)}
                    placeholder='New hires'
                    required={contactListNameRequired}
                    value={segmentName}
                  />
                )}
              </CardContent>
            </Card>
          )}
        </DialogContent>
        <DialogActions>
          <Button data-testid='cancel' onClick={() => onClose()} type='text'>
            Cancel
          </Button>
          <Button
            data-testid='download-a-sample-csv'
            href={file || '/files/Sample CSV - Contact Upload.csv'}
            rel='noreferrer'
            target='_blank'
            type='text'
            variant='outlined'
          >
            Download sample
          </Button>
          <Button
            color='primary'
            data-testid='upload'
            disabled={
              !isValidData || (saveAsSegment && segmentName?.length === 0)
            }
            onClick={() => {
              if (contactListNameRequired && !segmentName) {
                setShowError(true);
              } else {
                setShowError(false);
                uploadCSV();
              }
            }}
            variant='contained'
          >
            Upload
          </Button>
        </DialogActions>
      </ConditionalLoader>
    </Dialog>
  );
};

CSVUploadModal.propTypes = {
  contactListNameRequired: PropTypes.bool,
  dataFormatter: PropTypes.func,
  displayExtra: PropTypes.bool,
  eventId: PropTypes.string,
  file: PropTypes.string,
  hideUploadDetail: PropTypes.bool,
  instruction: PropTypes.string,
  noSegment: PropTypes.bool,
  onClose: PropTypes.func,
  onUpload: PropTypes.func,
  setIsLoading: PropTypes.func,
  show: PropTypes.bool,
  showRoleColumn: PropTypes.bool,
  toastMessage: PropTypes.string,
  url: PropTypes.string,
};

export default CSVUploadModal;

const NameRoleList = ({
  employeesToAdd,
  handleEmployeeRemove,
  handleRoleUpdate,
}) => (
  <Table size='small'>
    <TableHead>
      <TableRow>
        <TableCell>Email</TableCell>
        <TableCell>Role</TableCell>
        <TableCell>Remove</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {employeesToAdd?.map((e, i) => (
        <TableRow key={e.id}>
          <TableCell>{e.email}</TableCell>
          <TableCell>
            <Dropdown
              onChange={(evt) =>
                handleRoleUpdate({ id: i, role: evt.target.value })
              }
              options={RolesSelectOptions}
              value={RolesSelectOptions.find((r) => r.value === e.role)}
            />
          </TableCell>
          <TableCell>
            <IconButton
              data-testid='TODO:DATA-ICONBUTTON-73713'
              onClick={() => handleEmployeeRemove(i)}
            >
              <MinusCircle />
            </IconButton>
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
);

NameRoleList.propTypes = {
  employeesToAdd: PropTypes.array,
  handleEmployeeRemove: PropTypes.func,
  handleRoleUpdate: PropTypes.func,
};

const NameEmailList = ({ employeesToAdd }) => (
  <Table size='small'>
    <TableHead>
      <TableRow>
        <TableCell>First name</TableCell>
        <TableCell>Last name</TableCell>
        <TableCell>Email</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {employeesToAdd?.map((e, _i) => (
        <TableRow key={e.email}>
          <TableCell>{e.first_name}</TableCell>
          <TableCell>{e.last_name}</TableCell>
          <TableCell>{e.email}</TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
);

NameEmailList.propTypes = {
  employeesToAdd: PropTypes.array,
};
