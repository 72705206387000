import React, { useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { Stack } from '@mui/system';
import {
  getMRT_RowSelectionHandler,
  MaterialReactTable,
  MRT_ColumnDef,
  MRT_PaginationState,
  MRT_Row,
  MRT_RowSelectionState,
  MRT_SortingState,
  MRT_TableInstance,
  MRT_Updater,
  useMaterialReactTable,
} from 'material-react-table';
import { singular } from 'pluralize';

import {
  CommunicationFormData,
  CommunicationMethodId,
} from 'components/Events/Controls/Communications/communication.types';
import InvitationSelectCollectionMembers from 'components/Events/Controls/Invites/InvitationSelectCollectionMembers';
import { InvitationSelectGuestStatusChip } from 'components/Events/Controls/Invites/InvitationSelectGuestStatusChip';
import MRTToolbarAlertBannerContent from 'components/shared/MRTToolbarAlertBannerContent';
import MRTTopToolbarWrapper from 'components/shared/MRTTopToolbarWrapper';
import TabLabelWithCount from 'components/shared/TabLabelWithCount';
import {
  getDefaultMRTOptions,
  getInitialPagination,
  getInitialSorting,
  getServerSideMRTOptions,
} from 'constants/table.constants';
import { useEventInvitation } from 'hooks/useEventInvitation';
import { useEventInvitationOptions } from 'hooks/useEventInvitationOptions';
import {
  EventInvitationGuestCollectionType,
  EventInvitationGuestTypeId,
} from 'types/EventInvitationGuestType';

interface Collection {
  id: number;
  members: number;
  name: string;
  scheduled_for: string | null;
  sent_at: string | null;
}

interface Props {
  commId?: number;
  guestType: EventInvitationGuestCollectionType;
}

const initialSorting = getInitialSorting();
const initialPagination = getInitialPagination({});

const InvitationSelectCollections = ({ commId, guestType }: Props) => {
  const form = useFormContext<CommunicationFormData>();
  const { id, method } = useParams<{
    id: string;
    method: CommunicationMethodId;
  }>();
  const eventId = Number(id);
  const invitationId = commId ? Number(commId) : 'new';

  const [globalFilter, setGlobalFilter] = useState('');
  const [pagination, setPagination] =
    useState<MRT_PaginationState>(initialPagination);
  const [sorting, setSorting] = useState<MRT_SortingState>(initialSorting);

  const rowSelection = form.watch(`rowSelection.${guestType.id}`);

  const {
    retrieve: { data, isLoading, isRefetching },
  } = useEventInvitationOptions({
    eventId,
    globalFilter,
    invitationId,
    method,
    optionType: singular(guestType.id) as EventInvitationGuestTypeId,
    pagination,
    sorting,
  });

  const { retrieveSelectionData } = useEventInvitation({
    eventId,
    invitationId,
    method,
  });

  const handleGlobalFilterChange = (updater: MRT_Updater<any>) => {
    setGlobalFilter((prevGlobalFilter) =>
      updater instanceof Function ? updater(prevGlobalFilter) : updater
    );
    if (pagination.pageIndex !== 0) {
      setPagination((prev) => ({ ...prev, pageIndex: 0 }));
    }
  };

  const handleRowSelectionChange = (
    updater: MRT_Updater<MRT_RowSelectionState>
  ) => {
    const newValue =
      updater instanceof Function ? updater(rowSelection) : updater;
    form.setValue(`rowSelection.${guestType.id}`, newValue);
    retrieveSelectionData({
      guestType: guestType.id,
      selections: newValue,
    }).then((val: { selections: any[] }) => {
      form.setValue(`selections.${guestType.id}`, val.selections);
    });
  };

  const columns = useMemo<MRT_ColumnDef<Collection>[]>(
    () => [
      {
        accessorKey: 'name',
        // eslint-disable-next-line react/no-multi-comp
        Cell: ({ row }: { row: MRT_Row<Collection> }) => (
          <Stack
            alignItems='center'
            direction='row'
            gap={2}
            justifyContent='space-between'
            width='100%'
          >
            <TabLabelWithCount
              color='neutral'
              count={row.original.members}
              label={String(row.original.name)}
              variant='light'
            />
            <InvitationSelectGuestStatusChip row={row as MRT_Row<any>} />
          </Stack>
        ),
        grow: true,
        header: 'Name',
      },
    ],
    []
  );

  const serverSideMRTOptions = getServerSideMRTOptions<Collection>();

  const table = useMaterialReactTable({
    ...serverSideMRTOptions,
    columns,
    data: data?.data || [],
    displayColumnDefOptions: {
      'mrt-row-expand': {
        grow: false,
        header: '',
      },
      'mrt-row-select': {
        grow: false,
      },
    },
    enableExpandAll: false,
    enableExpanding: true,
    enableRowSelection: (row: MRT_Row<Collection>) =>
      !(row?.original?.scheduled_for || row?.original?.sent_at),
    enableTopToolbar: true,
    initialState: {
      showGlobalFilter: true,
      sorting: [
        {
          desc: false,
          id: 'name',
        },
      ],
    },
    muiDetailPanelProps: {
      sx: {
        overflowY: 'auto',
      },
    },
    muiExpandButtonProps: ({ row, table }) => ({
      onClick: () => table.setExpanded({ [row.id]: !row.getIsExpanded() }), // set only this row to be expanded
    }),
    muiTableBodyRowProps: ({ isDetailPanel, row, staticRowIndex, table }) => {
      const isSelectable =
        !isDetailPanel && !row.original.scheduled_for && !row.original.sent_at;
      return {
        onClick: (event) => {
          if (isSelectable) {
            getMRT_RowSelectionHandler({ row, staticRowIndex, table })(event);
          }
        },
        sx: {
          ':NOT(.Mui-selected)&:hover td::after': {
            bgcolor: isSelectable ? 'grey.100' : 'white',
          },
          ...(isSelectable && { cursor: 'pointer' }),
        },
      };
    },
    onGlobalFilterChange: handleGlobalFilterChange,
    onPaginationChange: setPagination,
    onRowSelectionChange: handleRowSelectionChange,
    onSortingChange: setSorting,
    positionExpandColumn: 'first',
    positionGlobalFilter: 'left',
    positionToolbarAlertBanner: 'bottom',
    renderDetailPanel: ({ row }: { row: MRT_Row<Collection> }) => (
      <>
        {row.getIsExpanded() && (
          <InvitationSelectCollectionMembers
            invitationId={invitationId}
            isSelected={
              rowSelection[String(row.id)] ||
              (row?.original?.scheduled_for || '')?.length +
                (row?.original?.sent_at || '')?.length >
                0
            }
            method={method}
            option={row?.original}
            optionType={guestType?.id}
          />
        )}
      </>
    ),
    renderToolbarAlertBannerContent: ({ table }) => (
      <MRTToolbarAlertBannerContent
        table={table as MRT_TableInstance<any>}
        {...getDefaultMRTOptions()}
      />
    ),
    renderTopToolbar: ({ table }) => (
      <MRTTopToolbarWrapper table={table as MRT_TableInstance<any>} />
    ),
    rowCount: data?.meta?.total_count ?? 0,
    state: {
      columnPinning: { right: ['mrt-row-expand'] },
      globalFilter,
      isLoading: isLoading || isRefetching,
      pagination,
      rowSelection,
      showProgressBars: isRefetching,
      sorting,
    },
  });

  return <MaterialReactTable table={table} />;
};

export default InvitationSelectCollections;
