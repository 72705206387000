import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import {
  CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { Check } from '@phosphor-icons/react';

import {
  CommunicationMethodId,
  InvitationFormData,
} from 'components/Events/Controls/Communications/communication.types';
import RadioLabel from 'components/shared/RadioLabel';
import { useEventInvitationOptionMembers } from 'hooks/useEventInvitationOptionMembers';
import { EventInvitationGuestTypeId } from 'types/EventInvitationGuestType';

type Props = {
  invitationId: number | 'new';
  isSelected: boolean;
  method: CommunicationMethodId;
  option: {
    id: number;
    members: number;
  };
  optionType: EventInvitationGuestTypeId;
};

const InvitationSelectCollectionMembers = ({
  invitationId,
  isSelected,
  method,
  option,
  optionType,
}: Props) => {
  const { id } = useParams<any>();
  const eventId = Number(id);
  const form = useFormContext<InvitationFormData>();

  const selectedGuestIds = form.watch('selectedGuestIds');

  const {
    retrieve: { data, isLoading },
  } = useEventInvitationOptionMembers({
    eventId,
    invitationId,
    method,
    optionId: option?.id,
    optionType,
  });

  return (
    <List data-testid='collection-members' dense disablePadding>
      {isLoading && <CircularProgress variant='indeterminate' />}
      {data
        ?.sort((a: { name: string }, b: { name: string }) =>
          a?.name?.localeCompare(b?.name)
        )
        ?.map(
          (
            {
              email,
              full_name,
              id,
              previously_invited,
            }: {
              email: string;
              full_name: string;
              id: number;
              previously_invited: boolean;
            },
            index: number
          ) => (
            <ListItem
              disableGutters
              disablePadding
              key={`${optionType}-member-${id}-${index}`}
            >
              <ListItemIcon sx={{ minWidth: 36 }}>
                {(isSelected || // parent collection is selected
                  selectedGuestIds?.has(id) || // guest was selected as part of this invitation
                  previously_invited) && ( // guest was selected as part of another invitation
                  <Check size={22} />
                )}
              </ListItemIcon>
              <ListItemText
                primary={<RadioLabel primary={full_name} secondary={email} />}
              />
            </ListItem>
          )
        )}
    </List>
  );
};

export default InvitationSelectCollectionMembers;
