import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useFlag } from '@unleash/proxy-client-react';
import * as _ from 'lodash';

import UnsavedChangesModal from 'components/Core/UnsavedChangesModal';
import Communications from 'components/Events/Controls/Communications/Communications';
import { ManagerContext } from 'components/Events/Manager/ManagerContext';
import InvitationsStep from 'components/Events/Steps/InvitationsStep';

const ManageInvitations = () => {
  const {
    actions: { /* clearPending, */ saveRsvpPauseActive },
    state: { event, /* invite_method, invites, */ savedEvent },
  } = useContext(ManagerContext);

  // const isCommsFlagEnabled = useFlag('event-communications');

  const [isPendingSave, setIsPendingSave] = useState(false);
  // const [tryingClose, setTryClose] = useState(false);

  useEffect(() => {
    if (
      isPendingSave &&
      event?.rsvp_pause_active !== savedEvent?.rsvp_pause_active
    ) {
      saveRsvpPauseActive();
      setIsPendingSave(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event.rsvp_pause_active]);

  /* const hasUnsavedInvitees = useMemo(() => {
    const inviteesForCurrentMethod = _.get(
      invites,
      `${invite_method}.invitees`,
      []
    );
    const unsavedInvitees = _.differenceBy(
      inviteesForCurrentMethod,
      savedEvent?.invitees,
      (invitee) => invitee?.employee_id || invitee?.id
    );
    return unsavedInvitees.length > 0;
  }, [savedEvent, invite_method, invites]); */

  /* const hasUnsavedChanges = useMemo(
    () =>
      hasUnsavedInvitees ||
      (invite_method === 'slack' &&
        _.differenceBy(
          invites?.slack?.channels?.map((channel) => channel.slack_id),
          savedEvent?.invites_by_types?.slack?.channels
        ).length > 0),
    [
      hasUnsavedInvitees,
      invite_method,
      invites?.slack?.channels,
      savedEvent?.invites_by_types?.slack?.channels,
    ]
  ); */

  return <Communications />;

  // TODO: Finish deprecating Comms = Off codepaths
  /* return (
    <>
      {isCommsFlagEnabled ? (
        <Communications />
      ) : (
        <>
          <InvitationsStep />
          <UnsavedChangesModal
            hasUnsavedChanges={hasUnsavedChanges}
            onCancel={() => setTryClose(false)}
            onConfirm={() => {
              setTryClose(false);
              clearPending(false, false, false, true);
            }}
            tryClose={tryingClose}
          />
        </>
      )}
    </>
  ); */
};

export default ManageInvitations;
