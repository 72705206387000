import { useQuery } from '@tanstack/react-query';
import { gql } from 'graphql-request';

import { useGraphQLClient } from 'hooks/useGraphQLClient';

const eventInvitationsRecipientsQueryDocument = gql`
  query eventInvitationsRecipientsQueryDocument($eventId: ID!) {
    eventInvitations(eventId: $eventId) {
      id
      inviteType
      isSent
      recipients {
        recipientType
        recipientId
      }
    }
  }
`;
export const useEventInvitations = ({ eventId }: { eventId: number }) => {
  const graphQLClient = useGraphQLClient();

  return useQuery<any>({
    queryFn: async () =>
      await graphQLClient?.request(eventInvitationsRecipientsQueryDocument, {
        eventId,
      }),
    queryKey: ['event', eventId, 'communications', 'invitations'],
  });
};
