import React from 'react';
import { Redirect, Route, Router, Switch } from 'react-router';
import { GuardedRoute } from 'react-router-guards';
import { GoogleOAuthProvider } from '@react-oauth/google';
import * as Sentry from '@sentry/react';
import { createBrowserHistory } from 'history';

import Analytics from 'components/Analytics/Analytics';
import Contacts from 'components/Contacts/Contacts';
import Head from 'components/Core/Head';
import UserGuardProvider from 'components/Core/UserGuardProvider';
import EventCreateRoutes from 'components/Event/EventCreateRoutes';
import EventManager from 'components/Events/Manager/EventManager';
import PublicEvent from 'components/Events/PublicEvent/PublicEvent';
import Group from 'components/Groups/Group';
import GroupCreationPage from 'components/Groups/GroupCreationPage';
import Groups from 'components/Groups/Groups';
import Home from 'components/Home/Home';
import AdminLogin from 'components/Onboard/AdminLogin';
import ChangePassword from 'components/Onboard/ChangePassword';
import Login from 'components/Onboard/Login';
import OktaLoginRedirector from 'components/Onboard/OktaLoginRedirector';
import ResetPassword from 'components/Onboard/ResetPassword';
import SignUp from 'components/Onboard/SignUp';
import TokenAccept from 'components/Onboard/TokenAccept';
import Profile from 'components/Profile/Profile';
import EventRedirector from 'components/Public/PublicGcalEvent/EventRedirector';
import SlackIntegration from 'components/Settings/Integrations/Slack/SlackIntegration';
import Settings from 'components/Settings/Settings';
import AppLayout from 'components/shared/AppLayout';
import NotFound from 'components/shared/views/NotFound';
import ZoomRedirect from 'components/shared/ZoomRedirect';
import Survey from 'components/Survey/Survey';
import Surveys from 'components/Survey/Surveys';
import SurveyTemplateCards from 'components/Survey/SurveyTemplates/SurveyTemplateCards';
import SurveyTemplateForm from 'components/Survey/SurveyTemplates/SurveyTemplateForm';
import { HomeProvider } from 'contexts/HomeContext';
import { SlackChannelsProvider } from 'contexts/SlackChannelsContext';
import { SurveyTemplatesProvider } from 'contexts/SurveyTemplatesContext';
import { SyncedSlackChannelProvider } from 'contexts/SyncedSlackChannelContext';

const SentryRoute = Sentry ? Sentry.withSentryRouting(Route) : Route;
const SentryGuardedRoute = Sentry
  ? Sentry.withSentryRouting(GuardedRoute)
  : GuardedRoute;

const history = createBrowserHistory();

const googleClientId = import.meta.env.VITE_GOOGLE_CLIENT_ID;
const googleCalendarClientId = import.meta.env.VITE_GOOGLE_CALENDAR_CLIENT_ID;

const AppRoutes = () => (
  <Router history={history}>
    <SurveyTemplatesProvider>
      <Switch data-testid='route-switch'>
        {/* INFO: When adding a publicly accessible route, 
            you will need to update AuthContext's PublicRoutes */}

        {/* SIGN UP ---------- */}
        <SentryRoute exact path='/admin/sign-up'>
          <Head title='Sign up' />
          <GoogleOAuthProvider clientId={googleClientId}>
            <AdminLogin />
          </GoogleOAuthProvider>
        </SentryRoute>
        <SentryRoute exact path='/sign-up'>
          <Head title='Sign up' />
          <GoogleOAuthProvider clientId={googleClientId}>
            <SignUp />
          </GoogleOAuthProvider>
        </SentryRoute>

        {/* LOGIN / PASSWORD ---------- */}
        <SentryRoute exact path='/login'>
          <Head title='Log in' />
          <Login />
        </SentryRoute>
        <SentryRoute exact path='/reset-password'>
          <Head title='Reset password' />
          <ResetPassword />
        </SentryRoute>
        <SentryRoute exact path='/change-password'>
          <Head title='Change password' />
          <ChangePassword />
        </SentryRoute>

        {/* AUTHENTICATE ---------- */}
        <SentryRoute exact path='/auth/okta'>
          <OktaLoginRedirector />
        </SentryRoute>
        <SentryRoute exact path='/wait-for-token'>
          <TokenAccept />
        </SentryRoute>

        {/* PUBLIC EVENT ---------- */}
        <SentryRoute path='/public/:id'>
          <Head title='View event' />
          <PublicEvent />
        </SentryRoute>
        <SentryRoute exact path='/survey/:id/pre'>
          {/* TODO: change this route to '/public/:id/survey/pre' */}
          <Head title='Pre-event survey' />
          <Survey type='pre_event_survey' />
        </SentryRoute>
        <SentryRoute exact path='/survey/:id/post'>
          {/* TODO: change this route to '/public/:id/survey/post' */}
          <Head title='Post-event survey' />
          <Survey type='post_event_survey' />
        </SentryRoute>
        <SentryRoute path='/ext_calendar/:id'>
          <Head title='Events' />
          <EventRedirector />
        </SentryRoute>

        <UserGuardProvider>
          <AppLayout>
            <GoogleOAuthProvider clientId={googleCalendarClientId}>
              <SlackChannelsProvider>
                <SyncedSlackChannelProvider>
                  <Switch data-testid='route-switch-authenticated'>
                    {/* EVENTS ---------- */}
                    <SentryRoute exact path='/events'>
                      <Head title='Events' />
                      <HomeProvider>
                        <Home />
                      </HomeProvider>
                    </SentryRoute>
                    <SentryRoute path='/events/:id'>
                      <GoogleOAuthProvider clientId={googleCalendarClientId}>
                        <EventManager />
                      </GoogleOAuthProvider>
                    </SentryRoute>
                    <SentryRoute path='/event/'>
                      <EventCreateRoutes />
                    </SentryRoute>

                    {/* GROUPS ---------- */}
                    <SentryRoute exact path='/groups'>
                      <Head title='Groups' />
                      <Groups />
                    </SentryRoute>
                    <SentryGuardedRoute exact path='/groups/new'>
                      <Head title='Create Group' />
                      <GroupCreationPage />
                    </SentryGuardedRoute>
                    <SentryRoute path='/groups/:id'>
                      <Head title='View Group' />
                      <Group />
                    </SentryRoute>

                    {/* CONTACTS ---------- */}
                    <SentryGuardedRoute exact path='/contacts'>
                      <Head title='Contacts' />
                      <Contacts />
                    </SentryGuardedRoute>

                    {/* ANALYTICS ---------- */}
                    <SentryGuardedRoute exact path='/analytics'>
                      <Head title='Analytics' />
                      <Analytics />
                    </SentryGuardedRoute>

                    {/* SETTINGS ---------- */}
                    <SentryGuardedRoute exact path='/settings/'>
                      <Head title='Settings' />
                      <Settings />
                    </SentryGuardedRoute>

                    {/* INTEGRATIONS ---------- */}
                    <SentryGuardedRoute
                      exact
                      path='/settings/integrations/slack'
                    >
                      <Head title='Slack integration' />
                      <SlackIntegration />
                    </SentryGuardedRoute>
                    <SentryRoute path='/zoom/callback'>
                      <Head title='Zoom integration' />
                      <ZoomRedirect />
                    </SentryRoute>
                    <SentryRoute path='/auth/outlook'>
                      <Head title='Outlook integration' />
                      Sending you back to Five To Nine...
                    </SentryRoute>

                    {/* SURVEYS ---------- */}
                    <SentryGuardedRoute exact path='/surveys'>
                      <Head title='Surveys' />
                      <Surveys />
                    </SentryGuardedRoute>
                    <SentryGuardedRoute exact path='/surveys/new'>
                      <Head title='Create survey' />
                      <SurveyTemplateCards />
                    </SentryGuardedRoute>
                    <SentryRoute path='/surveys/:id'>
                      <SurveyTemplateForm />
                    </SentryRoute>

                    {/* PROFILE ---------- */}
                    <SentryRoute exact path='/profile'>
                      <Head title='Profile' />
                      <Profile />
                    </SentryRoute>

                    {/* NO PATHNAME (REDIRECT) ---------- */}
                    <SentryRoute exact path='/'>
                      <Redirect to='/events' />
                    </SentryRoute>

                    {/* NOT FOUND ---------- */}
                    <SentryRoute path='*'>
                      <Head title='Page Not Found' />
                      <NotFound />
                    </SentryRoute>
                  </Switch>
                </SyncedSlackChannelProvider>
              </SlackChannelsProvider>
            </GoogleOAuthProvider>
          </AppLayout>
        </UserGuardProvider>
      </Switch>
    </SurveyTemplatesProvider>
  </Router>
);

export default AppRoutes;
