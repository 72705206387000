import { useQuery } from '@tanstack/react-query';

import { Group } from 'types/Group';
import { reactQueryAxios } from 'utils/axios';

export const useGroups = () => {
  const queryKey = ['budget_setting_groups'];
  const url = `/api/groups`;

  const getGroups = async ({ apiClient }: { apiClient: any }) => {
    const response = await apiClient({
      method: 'get',
      url,
    });

    return response.data;
  };

  const retrieve = useQuery<Group[], Error, Group[]>({
    queryFn: () =>
      getGroups({ apiClient: reactQueryAxios }).then((response) =>
        response?.map((g: Group) => {
          const currency = g?.currency_details?.symbol || '$';
          const currencyCode = g?.currency_details?.code || 'USD';
          return {
            ...g,
            currency_symbol: currency,
            nameWithCurrency: `${g.name} (${currencyCode} ${currency})`,
          };
        })
      ),
    queryKey,
  });

  return { retrieve };
};
