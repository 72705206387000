import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import * as _ from 'lodash';

import { getPersonalCalendar } from 'utils/calendar';

dayjs.extend(utc);
dayjs.extend(relativeTime);

const today = dayjs(Date.now());

export const DefaultEventTimes = {
  ends: today.add(7, 'day').set('hour', 10).set('minute', 0).set('second', 0),
  start: today.add(7, 'day').set('hour', 9).set('minute', 0).set('second', 0),
};

export const PrivacyFilter = (ev, user) => {
  if (ev.privacy === 'invite_only') {
    if (user.is_admin) {
      return true;
    }
    if (!ev.host_ids.includes(user.employee_id)) {
      return false;
    }
  }
  return true;
};

export const OfficeFilter = (ev, officeIds) => {
  if (officeIds?.length === 0) {
    // Catch empty array and null
    return true;
  }
  return _.intersection(ev.office_ids, officeIds).length > 0;
};

export const TypeFilter = (ev, typeIds) => {
  if (typeIds?.length === 0) {
    // Catch empty array and null
    return true;
  }
  return typeIds.includes(ev.event_type_id);
};

export const HostFilter = (ev, hostIds) => {
  if (hostIds?.length === 0) {
    // Catch empty array and null
    return true;
  }
  return _.intersection(ev.host_ids, hostIds).length > 0;
};

export const SearchFilter = (ev, searchVal) => {
  if (searchVal && searchVal?.length > 0) {
    return ev.name?.toLowerCase().includes(searchVal?.trim()?.toLowerCase());
  } else {
    return true;
  }
};

export const GroupFilter = (ev, groupIds) => {
  if (groupIds?.length === 0) {
    // Catch empty array and null
    return true;
  }
  return _.intersection(ev.group_ids, groupIds).length > 0;
};

export const CalculateNPS = (events) => {
  const totals = _.reduce(
    events,
    (res, value) => ({
      detractorCount: res.detractorCount + value.detractor_count,
      passiveCount: res.passiveCount + value.passive_count,
      promoterCount: res.promoterCount + value.promoter_count,
    }),
    {
      detractorCount: 0,
      passiveCount: 0,
      promoterCount: 0,
    }
  );
  const totalCount =
    totals.promoterCount + totals.detractorCount + totals.passiveCount;
  return {
    ...totals,
    nps:
      totalCount === 0
        ? 0
        : totals.promoterCount / totalCount -
          totals.detractorCount / totalCount,
    totalCount,
  };
};

export const isOver = (ev) => {
  const now = dayjs().utc();
  const endOfEvent = dayjs(ev?.ends).utc();
  return now.isAfter(endOfEvent);
};

export const hasStarted = (ev) => {
  const now = dayjs().utc();
  const startOfEvent = dayjs(ev?.start).utc();
  return now.isAfter(startOfEvent);
};

export const getEventLink = (
  type = 'event',
  surveyType = 'post_event_survey',
  event
) => {
  const id = parseInt(location?.pathname?.split('/')[2]);
  let link = `${window.location.origin}/${type}/${id}`;
  switch (type) {
    case 'survey':
      link += `/${surveyType.replace('_event_survey', '')}`;
      break;
    case 'meeting':
      link = event?.meeting?.link;
      break;
    default:
      break;
  }
  return link;
};

export const DefaultInvites = (user) => ({
  calendar: {
    calendars: [getPersonalCalendar(user)],
    groups: [],
    invitees: [],
    lists: [],
    notifies: false,
    offices: [],
  },
  email: {
    groups: [],
    invitees: [],
    lists: [],
    offices: [],
  },
  slack: {
    channels: [],
    has_calendar_invite: true,
    invitees: [],
  },
});

export const DefaultMessage = {
  invitee_statuses: [],
  is_scheduled: false,
  message_body: '',
  message_type: '',
  scheduled_for: '',
  show_event_details_button: false,
  show_rsvp_buttons: false,
  slack_channel_ids: [],
};

export const DefaultManagerSurvey = {
  post_event_survey: {
    editing: false,
  },
  pre_event_survey: {
    editing: false,
  },
};

export const DefaultInviteMessage = (event, isCommsFlagEnabled) => {
  if (!isCommsFlagEnabled && !_.isEqual(event, null)) {
    const lastSlackMessage = event?.invites_by_types?.slack?.last_invite_body;
    const lastEmailMessage = event?.invites_by_types?.email?.last_invite_body;
    if (
      event?.invite_body?.length > 0 &&
      (!lastEmailMessage || lastEmailMessage.length === 0) &&
      (!lastSlackMessage || lastSlackMessage.length === 0)
    ) {
      return {
        email: {
          invite_body: event?.invite_body,
          invite_subject: event?.invite_subject,
        },
        slack: {
          invite_body: event?.invite_body,
        },
      };
    } else if (lastEmailMessage?.length > 0 || lastSlackMessage?.length > 0) {
      return {
        email: {
          invite_body: lastEmailMessage,
          invite_subject: event?.invites_by_types?.email?.last_invite_subject,
        },
        slack: {
          invite_body: lastSlackMessage || '',
        },
      };
    } else {
      return {
        email: {
          invite_body: '',
          invite_subject: '',
        },
        slack: {
          invite_body: '',
        },
      };
    }
  } else if (isCommsFlagEnabled) {
    return {
      email: {
        invite_body: '',
        invite_subject: '',
      },
      slack: {
        invite_body: '',
      },
    };
  }
};

export const FromGroupId = (location) => {
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('group_id');

  if (id) {
    return _.toInteger(id);
  }
};
