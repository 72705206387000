import { useCallback, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router';
import * as _ from 'lodash';
import { useSnackbar } from 'notistack';

import { axiosAuthenticated as axios } from 'utils/axios';
import { DefaultSurveyTemplate } from 'utils/survey';
import { transformSurveyTemplateToFormData } from 'utils/transform';

export const useLoadSurveyTemplate = (
  startTask,
  endTask,
  loadingPredicate,
  setSavedSurveyTemplate,
  clearPending,
  applySingle
) => {
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const id = parseInt(location?.pathname?.split('/')[2]);

    if (true && loadingPredicate()) {
      startTask("We're gathering the details about your survey template!");
      axios(
        {
          method: 'get',
          url: `/api/survey_templates/${id}`,
        },
        (res) => {
          if (res.data && res.data.error) {
            enqueueSnackbar(
              `An error occurred loading the survey template. ${res.data.error}` +
                { variant: 'error' }
            );
          } else {
            const { data } = res;
            setSavedSurveyTemplate(data);
            clearPending(false, true, false);
            applySingle(data.style, `style`);
          }
          endTask();
        },
        () => {
          endTask();
        }
      );
    }
  }, [
    location?.pathname,
    enqueueSnackbar,
    clearPending,
    endTask,
    startTask,
    loadingPredicate,
    setSavedSurveyTemplate,
    applySingle,
  ]);
};

export const useSaveSurveyTemplate = (
  startTask,
  endTask,
  pendingData,
  savedSurveyTemplate,
  onSuccess,
  onFailure,
  type,
  inEditor = false
) =>
  useCallback(() => {
    if (
      !_.isEqual(pendingData.surveys, DefaultSurveyTemplate) ||
      !_.isEqual(pendingData, DefaultSurveyTemplate)
    ) {
      startTask();

      const pendingDataSurvey = _.get(
        pendingData,
        inEditor ? `${type}` : `surveys.${type}`
      );

      let updatedSurvey = {};

      if (pendingDataSurvey?.id) {
        // if trying to save a survey in draft event to a template
        updatedSurvey = _.cloneDeep(pendingDataSurvey);

        const updatedOptions = updatedSurvey.survey_options;

        _.forEach(updatedOptions, (option) => {
          if (option.id) {
            const optionQuestion = updatedSurvey.survey_questions.find(
              (question) => question.id === option.survey_question_id
            );
            option.survey_question_id = optionQuestion.position;
            option.id = '';
          }
        });

        const updatedQuestions = updatedSurvey.survey_questions;

        _.forEach(updatedQuestions, (question) => {
          if (question['questionable_id']) {
            _.unset(question, 'questionable_type');
            _.unset(question, 'questionable_id');
          }
          question.id = '';
        });

        updatedSurvey.id = '';
      }

      const surveyForTemplate = pendingDataSurvey?.id
        ? updatedSurvey
        : pendingDataSurvey;

      const surveyTemplateData = inEditor
        ? { ...savedSurveyTemplate, ...surveyForTemplate }
        : {
            ...savedSurveyTemplate,
            ...pendingDataSurvey,
            style: pendingData?.style || savedSurveyTemplate?.style,
          };

      const surveyTemplateDataToSave = _.pick(surveyTemplateData, [
        'name',
        'privacy',
        'style',
        'survey_options',
        'survey_questions',
      ]);

      const requestData = transformSurveyTemplateToFormData(
        surveyTemplateDataToSave
      );

      const method = inEditor ? 'post' : 'put';

      const url = inEditor
        ? `/api/survey_templates`
        : `/api/survey_templates/${savedSurveyTemplate.id}`;

      axios(
        {
          data: requestData,
          method,
          url,
        },
        (res) => {
          if (res.data) {
            onSuccess && onSuccess(res);
            endTask();
          }
        },
        (err) => {
          onFailure && onFailure(err);
          endTask();
        }
      );
      return true;
    }
    return false;
  }, [
    savedSurveyTemplate,
    pendingData,
    startTask,
    endTask,
    onSuccess,
    onFailure,
    type,
    inEditor,
  ]);

export const usePendingSurveyTemplate = (savedTemplate, pendingData) =>
  useMemo(
    () => ({
      post_event_survey: _.assign(
        {
          survey_options: [],
          survey_options_responses: [],
          survey_questions: [],
          survey_responses: [],
        },
        savedTemplate,
        pendingData?.surveys?.post_event_survey
      ),
      pre_event_survey: _.assign(
        {
          survey_options: [],
          survey_options_responses: [],
          survey_questions: [],
          survey_responses: [],
        },
        savedTemplate,
        pendingData?.surveys?.pre_event_survey
      ),
    }),
    [savedTemplate, pendingData]
  );

export const useCloneSurveyTemplates = (
  startTask,
  endTask,
  onSuccess,
  onFailure
) =>
  useCallback(
    (id) => {
      startTask();
      axios(
        {
          method: 'post',
          params: {
            source_id: id,
          },
          url: `/api/survey_templates/`,
        },
        (res) => {
          if (res.data && res.data.error) {
            onFailure && onFailure(res.data.error);
            endTask();
          } else {
            onSuccess && onSuccess(res.data);
            endTask();
          }
        },
        (err) => {
          onFailure && onFailure(err);
          endTask();
        }
      );
    },
    [onFailure, onSuccess, startTask, endTask]
  );
