import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { createCloudinaryLegacyURL } from '@cloudinary/url-gen';
import {
  Box,
  Collapse,
  Drawer,
  IconButton,
  List,
  Stack,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  CalendarBlank,
  CaretRight,
  ChartBar,
  ClipboardText,
  Gear,
  Lifebuoy,
  SignOut,
  User,
  UsersThree,
} from '@phosphor-icons/react';

import Avatar from 'components/Core/Avatar';
import ListItemLink from 'components/shared/ListItemLink';
import { AuthContext } from 'contexts/AuthContext';
import { LayoutContext } from 'contexts/LayoutContext';
import { OrganizationContext } from 'contexts/OrganizationContext';
import { NewPrimaryColor } from 'utils/color';

const sidenavWidthExpanded = 240;
export const sidenavWidthCollapsed = 48;
export const sidenavIconSize = 32;

const FtnDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ open, theme }) => ({
  '& .MuiDrawer-paper': {
    boxShadow: '0px 4px 12px 4px #0000000D',
    boxSizing: 'border-box',
    height: '100vh',
    position: 'relative',
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.enteringScreen,
      easing: theme.transitions.easing.sharp,
    }),
    whiteSpace: 'nowrap',
    width: sidenavWidthExpanded,
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        duration: theme.transitions.duration.leavingScreen,
        easing: theme.transitions.easing.sharp,
      }),
      width: sidenavWidthCollapsed,
    }),
  },
  height: '100vh',
}));

const AppSidenav = () => {
  const {
    userProvider: { isOrganizerOrAdmin, user },
  } = useContext(AuthContext);
  const {
    isMobile,
    mobileSidenavIsOpen,
    setMobileSidenavIsOpen,
    setSidenavIsHidden,
    setSidenavIsOpen,
    sidenavIsHidden,
    sidenavIsOpen,
    sidenavIsVisible,
    toggleSidenav,
  } = useContext(LayoutContext);
  const [org] = useContext(OrganizationContext);
  const location = useLocation();
  const [iconUrl, setIconUrl] = useState(null);
  const [logoUrl, setLogoUrl] = useState(null);

  useEffect(() => {
    if (org?.logo?.key) {
      setLogoUrl(
        createCloudinaryLegacyURL(org?.logo?.key, {
          cloud_name: 'five-to-nine',
          public_id: org?.logo?.key,
          secure: process.env.NODE_ENV !== 'development',
        })
      );
    } else {
      setLogoUrl(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [org?.logo?.key]);

  useEffect(() => {
    if (org?.favicon?.key) {
      setIconUrl(
        createCloudinaryLegacyURL(org?.favicon?.key, {
          cloud_name: 'five-to-nine',
          public_id: org?.favicon?.key,
          secure: process.env.NODE_ENV !== 'development',
        })
      );
    } else {
      setIconUrl(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [org?.favicon?.key]);

  useEffect(() => {
    if (sidenavIsVisible) {
      if (isMobile) {
        setMobileSidenavIsOpen(false);
      }
    }
    if (location.pathname.includes('/event/')) {
      setSidenavIsOpen(false);
      setSidenavIsHidden(true);
    } else {
      setSidenavIsHidden(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const drawerContents = (
    <>
      <Collapse
        collapsedSize={sidenavWidthCollapsed}
        in={sidenavIsVisible}
        orientation='horizontal'
        sx={!sidenavIsVisible && { maxWidth: sidenavWidthCollapsed }}
        width='100%'
      >
        <Stack
          alignItems='center'
          data-testid='AppSidenav-header'
          direction='row'
          minHeight={80}
          p={1}
          spacing={logoUrl ? 0 : 1.5}
          width='100%'
        >
          <Collapse
            in={logoUrl ? !sidenavIsVisible : true}
            orientation='horizontal'
          >
            <Avatar
              data-testid='sidenav-avatar-company'
              src={iconUrl}
              sx={{
                bgcolor: iconUrl ? 'transparent' : NewPrimaryColor,
                fontSize: 15,
                fontWeight: 500,
                height: sidenavIconSize,
                mr: 1,
                width: sidenavIconSize,
              }}
              variant='square'
            >
              {org?.display_name[0]}
            </Avatar>
          </Collapse>
          {logoUrl ? (
            <img alt='logo' data-testid='App-logo' src={logoUrl} width={224} />
          ) : (
            <Typography
              lineHeight={1.25}
              variant='subtitle1'
              whiteSpace='normal'
              width={141}
            >
              {org?.display_name}
            </Typography>
          )}
        </Stack>
      </Collapse>

      <Stack
        display='flex'
        flex='1 0 auto'
        flexDirection='column'
        justifyContent='space-between'
      >
        <List component='nav' data-testid='sidenav-links' disablePadding>
          <Box
            display='flex'
            justifyContent={sidenavIsVisible ? 'flex-end' : 'center'}
            pb={1}
            width='100%'
          >
            <IconButton
              color='primary'
              data-testid='nav-list-toggle'
              disableRipple
              onClick={toggleSidenav}
              sx={{
                transform: sidenavIsVisible ? 'rotate(-180deg)' : 'none',
                transition: 'all 300ms ease-in-out',
              }}
            >
              <CaretRight size={24} />
            </IconButton>
          </Box>
          <ListItemLink Icon={CalendarBlank} primary='Events' to='/events' />
          <ListItemLink Icon={UsersThree} primary='Groups' to='/groups' />
          {isOrganizerOrAdmin && (
            <>
              <ListItemLink Icon={User} primary='Contacts' to='/contacts' />
              <ListItemLink
                Icon={ClipboardText}
                primary='Surveys'
                to='/surveys'
              />
              <ListItemLink
                Icon={ChartBar}
                primary='Analytics'
                to='/analytics'
              />
              {user?.is_admin && (
                <ListItemLink Icon={Gear} primary='Settings' to='/settings' />
              )}
            </>
          )}
        </List>
        <List component='nav' data-testid='sidenav-resources'>
          <ListItemLink
            Icon={Lifebuoy}
            primary='Resources'
            to='https://helpcenter.fivetonine.network/en/'
          />
          <ListItemLink
            avatar={
              <Avatar
                data-testid='sidenav-avatar-user'
                employeeId={+user?.employee_id}
                height={32}
                sx={{ fontSize: 16, height: 32, ml: '-4px', width: 32 }}
                width={32}
              >
                {`${user.initials}`}
              </Avatar>
            }
            primary='Profile'
            to='/profile'
          />
          {process.env.NODE_ENV === 'development' && (
            <ListItemLink Icon={SignOut} primary='Log out' to='/login' />
          )}
        </List>
      </Stack>
    </>
  );

  return isMobile ? (
    <Drawer
      data-testid='mobile-sidenav'
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      open={mobileSidenavIsOpen}
      sx={{
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          width: sidenavWidthExpanded,
        },
      }}
      variant='temporary'
    >
      {drawerContents}
    </Drawer>
  ) : sidenavIsHidden ? null : (
    <FtnDrawer
      open={sidenavIsOpen}
      PaperProps={{
        elevation: 0,
        variant: 'elevation',
      }}
      variant='permanent'
    >
      {drawerContents}
    </FtnDrawer>
  );
};

export default AppSidenav;
