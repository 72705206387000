import React, { useMemo } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { Autocomplete, TextField } from '@mui/material';
import { useFlag } from '@unleash/proxy-client-react';

import AutocompleteCheckboxOption from 'components/shared/AutocompleteCheckboxOption';
import { AutocompletePaperComponent } from 'components/shared/AutocompletePaperComponent';
import { useGroups } from 'hooks/useGroups';
import { Group } from 'types/Group';

interface FormInputProps {
  currency?: {
    id?: number;
    name?: string;
    symbol?: string;
  };
  disableFields: boolean;
  form: UseFormReturn<any>;
  groupIdsValue?: number[];
  label?: string;
  onGroupsChange?: (value: any) => void;
}

const EventGroupsAutocomplete = ({
  disableFields,
  form: { control },
  label = 'Linked groups',
  onGroupsChange,
}: FormInputProps) => {
  const {
    retrieve: { data: groups },
  } = useGroups();
  const eventBudgetFlagEnabled = useFlag('event-budget');

  const options = useMemo(
    () =>
      groups && groups?.length > 0
        ? groups
            ?.sort((a: Group, b: Group) => a?.name?.localeCompare(b?.name))
            ?.map((opt: Group) => ({
              ...opt,
              label: eventBudgetFlagEnabled ? opt?.nameWithCurrency : opt?.name,
            }))
        : [],
    [eventBudgetFlagEnabled, groups]
  );

  return (
    <Controller
      control={control}
      name='groups'
      render={({ field }) => (
        <Autocomplete
          disableCloseOnSelect
          fullWidth
          getOptionDisabled={(option: Group) => {
            if (!eventBudgetFlagEnabled || field.value.length === 0)
              return false;
            const firstSelectedGroup = field.value[0];
            return (
              option.currency_details?.name !==
              firstSelectedGroup.currency_details?.name
            );
          }}
          getOptionLabel={(option) => option?.label || ''}
          id='event-linked-groups'
          isOptionEqualToValue={(opt: Group, val: Group) => opt?.id === val?.id}
          multiple
          onChange={(_event, value) => {
            field.onChange(value);
            onGroupsChange && onGroupsChange(value);
          }}
          options={options as Group[]}
          PaperComponent={AutocompletePaperComponent}
          renderInput={(params) => (
            <TextField
              {...params}
              error={
                field.value.length > 0 &&
                (eventBudgetFlagEnabled
                  ? new Set(
                      field.value.map((g: Group) => g?.currency_details?.name)
                    ).size > 1
                  : false)
              }
              helperText={
                eventBudgetFlagEnabled
                  ? 'Please ensure all groups selected share the same currency.'
                  : null
              }
              InputProps={{
                ...params.InputProps,
                readOnly: disableFields,
              }}
              inputRef={field.ref}
              label={label}
              placeholder='Select groups'
              sx={{ m: 0 }}
            />
          )}
          renderOption={(props, option: any, state) => (
            <React.Fragment key={String(option.id)}>
              <AutocompleteCheckboxOption
                option={option}
                props={props}
                state={state}
                textKey='label'
              />
            </React.Fragment>
          )}
          value={field.value.map((group: Group) =>
            options?.find((option: Group) => option.id === group.id)
          )}
        />
      )}
    />
  );
};

export default EventGroupsAutocomplete;
