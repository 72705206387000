import React, { createContext, useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import * as _ from 'lodash';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

import { FullscreenLoader, InlineLoader } from 'components/Core/Loader';
import {
  getRsvpStatusDetails,
  rsvpOptions,
  rsvpSnackbarMessageGeneric,
} from 'components/Events/Controls/Rsvp/Rsvp.constants';
import { useHandleRSVP } from 'components/Events/Hooks/Actions';
import {
  useDisplayedHostSelections,
  useEventLocationType,
  usePendingSurveys,
  useSurveyResponses,
} from 'components/Events/Hooks/Event';
import { usePendingData } from 'components/Events/Hooks/Generic';
import { DefaultQuestion } from 'utils/survey';
import { transformEvent } from 'utils/transform';
import { useDelayedLoading } from 'utils/utils';

export const PublicEventContext = createContext();

const DefaultPublicEventSurvey = {
  default_survey: {
    survey_questions: DefaultQuestion('public_pre_survey'),
  },
  pre_event_survey: {},
};

export const PublicEventProvider = ({ children }) => {
  const { endTask, isWorking, loadingMessage, longWorking, startTask } =
    useDelayedLoading();
  const history = useHistory();
  const { id } = useParams();
  const { applyMulti, applySingle, pendingData } = usePendingData({
    surveys: DefaultPublicEventSurvey,
  });
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const [rsvpStatus, setRsvpStatus] = useState(null);
  const [rsvpStep, setRsvpStep] = useState();
  const [savedEvent, setSavedEvent] = useState({});
  const [surveyJustSubmitted, setSurveyJustSubmitted] = useState(false);
  const [showPreEventSurvey, setShowPreEventSurvey] = useState(false);

  useEffect(() => {
    if (!isWorking && _.isEqual(savedEvent, {})) {
      startTask();
      if (window.localStorage.getItem('token')) {
        history.replace(`/events/${id}/view`);
        endTask();
      }
      axios
        .get(`${import.meta.env.VITE_BE_URL}/api/events/${id}/public`)
        .then((res) => {
          endTask();
          setSavedEvent(transformEvent(res.data, true));
        })
        .catch(() => {
          endTask();
          history.replace('/login');
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, startTask, endTask, isWorking, savedEvent]);

  const pendingSurveys = usePendingSurveys(savedEvent, pendingData);

  const surveyResponses = useSurveyResponses(pendingData, pendingSurveys);

  const _afterRsvpSuccess = useCallback(
    (res, action, surveySubmitted) => {
      queryClient.invalidateQueries({
        queryKey: ['event', res?.data?.id, 'invitee'],
      });
      if (action === undefined) {
        enqueueSnackbar(rsvpSnackbarMessageGeneric, { variant: 'success' });
      } else {
        const message = rsvpOptions.find(
          (opt) => opt.id === action
        )?.snackbarMessage;
        enqueueSnackbar(message, { variant: 'success' });
        setSavedEvent(
          action === 'not_attending'
            ? transformEvent(res.data, true)
            : transformEvent(res.data)
        );
        setRsvpStatus(action);
        if (
          savedEvent?.pre_event_survey?.survey_questions?.filter(
            (q) => q.id !== 'email'
          )?.length > 0 &&
          !surveySubmitted &&
          action === 'rsvp'
        ) {
          setShowPreEventSurvey(true);
        }
        setSurveyJustSubmitted(surveySubmitted);
      }
      setRsvpStep('post-rsvp');
    },
    [
      enqueueSnackbar,
      queryClient,
      savedEvent?.pre_event_survey?.survey_questions,
    ]
  );

  const _afterSaveFail = useCallback(
    (err) => {
      // TODO: Story for better error handling
      queryClient.invalidateQueries({ queryKey: ['event'] });
      if (err?.response?.status === 401) {
        enqueueSnackbar(
          `You are not allowed to RSVP this event based on the email provided.`,
          {
            variant: 'error',
          }
        );
      } else {
        enqueueSnackbar(
          _.chain(err?.response?.data?.errors)
            .map((m, k) => `${k}: ${m}`)
            .join(', ')
            .value(),
          { variant: 'error' }
        );
      }
      setRsvpStatus('');
    },
    [enqueueSnackbar, queryClient]
  );

  const rsvpMessage = `Weʼre working on your RSVP!`;

  const handleRSVP = useHandleRSVP(
    startTask,
    endTask,
    savedEvent?.id,
    'public',
    _afterRsvpSuccess,
    _afterSaveFail,
    surveyResponses
  );

  const handleRsvpClick = (type) => {
    const typeDetails = getRsvpStatusDetails(type);
    handleRSVP(typeDetails.id, false);
  };

  const { allDisplayedHostSelections } = useDisplayedHostSelections(
    savedEvent,
    true
  );

  return (
    <PublicEventContext.Provider
      value={{
        actions: {
          applyMulti,
          applySingle,
          handleRSVP,
          handleRsvpClick,
          setRsvpStatus,
          setRsvpStep,
          setShowPreEventSurvey,
        },
        state: {
          event: savedEvent,
          hosts: allDisplayedHostSelections,
          inPublicEventContext: true,
          isPublic: true,
          locationType: useEventLocationType(savedEvent)?.id,
          rsvpStatus,
          rsvpStep,
          showPreEventSurvey,
          surveyJustSubmitted,
          surveyResponses,
          surveys: pendingSurveys,
        },
      }}
    >
      {!savedEvent ? (
        <div className='loader-wrapper'>
          <InlineLoader inline />
        </div>
      ) : (
        <>
          <FullscreenLoader
            message={loadingMessage || rsvpMessage}
            show={longWorking}
          />
          {!longWorking && savedEvent && children}
        </>
      )}
    </PublicEventContext.Provider>
  );
};

PublicEventProvider.propTypes = {
  children: PropTypes.node,
};
