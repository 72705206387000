import React, { useContext, useState } from 'react';
import { ClipboardText } from '@phosphor-icons/react';

import Search from 'components/Core/Search';
import CreateSurveyTemplateButton from 'components/Events/Controls/Surveys/CreateSurveyTemplateButton';
import Filters from 'components/shared/Filters';
import TabsLayout from 'components/shared/layout/TabsLayout';
import SurveyTemplateCards from 'components/Survey/SurveyTemplates/SurveyTemplateCards';
import { AuthContext } from 'contexts/AuthContext';
import { SurveyTypes } from 'utils/survey';
import { ApplyFilterToChunk, useChunking } from 'utils/utils';

const Surveys = () => {
  const surveysChunk = useChunking('/api/survey_templates', 'templates');
  const {
    userProvider: { user },
  } = useContext(AuthContext);
  const [currTab, setCurrTab] = useState(
    location?.hash.length > 0 ? location.hash.replace('#', '') : 'all'
  );
  const [searchTerm, setSearchTerm] = useState('');
  const [surveyType, setSurveyType] = useState('');

  const mySurveysChunk = ApplyFilterToChunk(
    (survey) => survey.creator_id === user.id,
    surveysChunk
  );

  const tabs = [
    {
      component: (
        <SurveyTemplateCards
          chunk={surveysChunk}
          searchTerm={searchTerm}
          surveyType={surveyType}
          tab={currTab}
        />
      ),
      label: 'All survey templates',
      summaryStat: {
        headline: {
          count: surveysChunk?.values?.length,
          label: 'survey templates',
        },
        Icon: ClipboardText,
        src: '/images/editor_edit_selected.svg',
      },
      value: 'all',
    },
    {
      component: (
        <SurveyTemplateCards
          chunk={mySurveysChunk}
          searchTerm={searchTerm}
          surveyType={surveyType}
          tab={currTab}
        />
      ),
      label: 'My survey templates',
      summaryStat: {
        headline: {
          count: mySurveysChunk?.values?.length,
          label: 'survey templates',
        },
        Icon: ClipboardText,
        src: '/images/editor_edit_selected.svg',
      },
      value: 'my',
    },
  ];

  const handleTabChange = (activeTabIndex) => {
    setCurrTab([...tabs][activeTabIndex]?.value);
  };

  return (
    <TabsLayout
      button={<CreateSurveyTemplateButton />}
      fixedContent={
        <Filters
          filters={[
            {
              handleChange: setSurveyType,
              label: 'Survey type',
              options: SurveyTypes,
            },
          ]}
        >
          <Search
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder={`Search ${currTab} templates`}
            sx={{ minWidth: 220 }}
            value={searchTerm}
          />
        </Filters>
      }
      fixedLocation='below-tabs'
      onChange={handleTabChange}
      tabs={tabs}
      title='Survey templates'
    />
  );
};

export default Surveys;
