import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Tab, Tabs } from '@mui/material';
import PropTypes from 'prop-types';

import UnsavedChangesModal from 'components/Core/UnsavedChangesModal';
import ContentWidthContainer from 'components/shared/layout/ContentWidthContainer';
import DefaultAppBar from 'components/shared/layout/DefaultAppBar';
import TabPanel from 'components/shared/TabPanel';

const a11yProps = (index) => ({
  'aria-controls': `tabpanel-${index}`,
  id: `tab-${index}`,
});

const SettingsLayout = ({
  hasUnsavedChanges = false,
  onChange,
  onDiscardChanges,
  tabs,
  withUnsavedModal = false,
  ...props
}) => {
  const location = useLocation();

  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [isTryingToClose, setIsTryingToClose] = useState(false);
  const [nextTabIndex, setNextTabIndex] = useState(null);

  const handleChange = useCallback(
    (event, newIndex) => {
      if (withUnsavedModal && hasUnsavedChanges) {
        event.preventDefault();
        setNextTabIndex(newIndex);
        setIsTryingToClose(true);
      } else {
        setActiveTabIndex(newIndex);
        setIsTryingToClose(false);
        setNextTabIndex(null);
        onChange(newIndex);
      }
    },
    [hasUnsavedChanges, onChange, withUnsavedModal]
  );

  const activeTab = useMemo(
    () =>
      tabs?.length > 0 && activeTabIndex > -1 ? tabs[activeTabIndex] : null,
    [activeTabIndex, tabs]
  );

  useEffect(() => {
    if (location?.hash) {
      const tabIndex = tabs.findIndex((t) => `#${t.value}` === location.hash);
      if (tabIndex > -1 && tabIndex !== activeTabIndex) {
        setActiveTabIndex(tabIndex);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, tabs]);

  return (
    <>
      <DefaultAppBar
        {...props}
        fixedLocation={props?.fixedLocation || 'below-tabs'}
        TabBar={
          <Tabs
            aria-label={`${props.title} tabs`}
            onChange={handleChange}
            scrollButtons='auto'
            value={activeTabIndex}
            variant='scrollable'
          >
            {tabs.map(({ label, labelCount, value }, index) => (
              <Tab
                {...a11yProps(index)}
                component={NavLink}
                key={index}
                label={`${label}${
                  labelCount && labelCount > 0 ? ` (${labelCount})` : ''
                }`}
                to={{
                  hash: hasUnsavedChanges ? activeTab?.value : value,
                }}
              />
            ))}
          </Tabs>
        }
      />
      <ContentWidthContainer>
        {tabs.map((tab, index) => (
          <TabPanel index={index} key={index} value={activeTabIndex}>
            {tab.component}
          </TabPanel>
        ))}
      </ContentWidthContainer>
      {withUnsavedModal ? (
        <UnsavedChangesModal
          hasUnsavedChanges={hasUnsavedChanges}
          onCancel={() => {
            setIsTryingToClose(false);
            setNextTabIndex(null);
          }}
          onConfirm={() => {
            setIsTryingToClose(false);
            if (nextTabIndex) {
              setActiveTabIndex(nextTabIndex);
              setNextTabIndex(null);
            }
            if (onDiscardChanges) {
              onDiscardChanges();
            }
          }}
          tryClose={isTryingToClose}
        />
      ) : null}
    </>
  );
};

SettingsLayout.propTypes = {
  hasUnsavedChanges: PropTypes.bool,
  onChange: PropTypes.func,
  onDiscardChanges: PropTypes.func,
  summaryStat: PropTypes.object,
  tabs: PropTypes.array,
  withUnsavedModal: PropTypes.bool,
  ...DefaultAppBar.propTypes,
};

export default SettingsLayout;
