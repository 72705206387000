import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { Stack } from '@mui/system';
import { ArrowLeft, ArrowRight } from '@phosphor-icons/react';

import GroupCreateChannel, {
  GroupCreateSlackChannel,
} from 'components/Groups/GroupCreateChannel';
import GroupCreateType from 'components/Groups/GroupCreateType';

interface Props {
  close: () => void;
  isOpen: boolean;
}

const GroupCreateDialog = ({ close, isOpen }: Props) => {
  const history = useHistory();
  const [type, setType] = useState<'manual' | 'slack'>('manual');
  const [step, setStep] = useState<'type' | 'channel'>('type');
  const [channel, setChannel] = useState<GroupCreateSlackChannel | null>(null);

  const handleContinue = useCallback(() => {
    if (type === 'manual') {
      history.push('/groups/new');
    } else if (type === 'slack') {
      if (step === 'type') setStep('channel');
      else if (step === 'channel') {
        history.push(`/groups/new?channel=${channel?.id}`);
      }
    }
  }, [type, history, step, channel?.id]);

  const resetState = useCallback(() => {
    setType('manual');
    setStep('type');
    setChannel(null);
  }, []);

  useEffect(() => {
    if (!isOpen) {
      // Don't do it right away or it will be visible for a split second
      setTimeout(() => {
        resetState();
      }, 500);
    }
  }, [isOpen, resetState]);

  return (
    <Dialog
      onClose={close}
      open={isOpen}
      PaperProps={{ sx: { minHeight: '422px' } }}
    >
      <DialogTitle>New group</DialogTitle>
      <DialogContent sx={{ pb: 2, pt: 0, px: 3 }}>
        {step === 'type' && <GroupCreateType setType={setType} type={type} />}
        {step === 'channel' && (
          <GroupCreateChannel channel={channel} setChannel={setChannel} />
        )}
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: step === 'channel' ? 'space-between' : 'flex-end',
        }}
      >
        {step === 'channel' && (
          <Button
            onClick={() => {
              setStep('type');
              setChannel(null);
            }}
            startIcon={<ArrowLeft />}
          >
            Back
          </Button>
        )}
        <Stack direction='row' spacing={2}>
          <Button onClick={close}>Cancel</Button>
          <Button
            color='primary'
            disabled={step === 'channel' && channel === null}
            endIcon={<ArrowRight />}
            onClick={handleContinue}
            variant='contained'
          >
            Continue
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default GroupCreateDialog;
