import { useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { GroupContext } from 'contexts/GroupContext';
import { axiosAuthenticated as axios } from 'utils/axios';

const useSaveGroup = () => {
  const history = useHistory();
  const { dispatch } = useContext(GroupContext);

  return useCallback(
    (
      group: {
        [x: string]: string | Blob;
        id?: any;
        synced_slack_channel?: any;
      },
      coverImage: string | Blob,
      succCallback: (arg0: any) => void,
      failCallback: (arg0: any) => void
    ) => {
      // Ensure that synced slack channel is carried forward, we don't want to remove it when saving a group
      if (group?.synced_slack_channel?.slack_channel_id) {
        group.slack_channel_id = `${group.synced_slack_channel.slack_channel_id}`;
      }

      const fd = new FormData();
      Object.keys(group).forEach((key) => {
        if (key === 'group_admin_ids' || key === 'office_ids') {
          fd.append(`group[${key}]`, JSON.stringify(group[key]));
        } else if (key === 'cover_image') {
          if (coverImage && group.coverImageChanged) {
            fd.append('group[cover_image]', coverImage);
          }
        } else if (key === 'cover_image_key') {
          if (!coverImage && group[key] && group.coverImageChanged) {
            fd.append(`group[cover_image_key]`, group[key]);
          }
        } else if (group[key] == null) {
          fd.append(`group[${key}]`, '');
        } else {
          fd.append(`group[${key}]`, group[key]);
        }
      });

      const method = group.id ? 'put' : 'post';
      const url = group.id ? `/api/groups/${group.id}` : '/api/groups';

      dispatch({ type: 'POST_GROUP_BEGIN' });

      axios(
        {
          data: fd,
          headers: {
            'Content-Type': `multipart/form-data;`,
          },
          method,
          url,
        },
        (res: { data: any }) => {
          if (res.data && res.data.error) {
            failCallback(res.data.error);
            dispatch({
              payload: { error: res.data.error },
              type: 'POST_GROUP_FAILURE',
            });
          } else {
            const groupData = res.data;
            const groupWithCurrency = {
              ...groupData,
              currency_symbol: groupData.currency_details.symbol || '$',
              nameWithCurrency: `${groupData.name} (${
                groupData.currency_details.code
              } ${groupData.currency_details.symbol || '$'})`,
            };
            succCallback(groupWithCurrency);
            dispatch({
              payload: { group: groupWithCurrency },
              type: 'POST_GROUP_SUCCESS',
            });
            history.push(`/groups/${res.data.id}#view`);
          }
        },
        (error: any) => {
          failCallback(error);
          dispatch({ payload: { error }, type: 'POST_GROUP_FAILURE' });
        }
      );
      return group;
    },
    [dispatch, history]
  );
};

export default useSaveGroup;
