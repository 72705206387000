/* eslint-disable react/prop-types */
import React from 'react';
import { Box, Stack, Typography } from '@mui/material';

import { communicationTypes } from 'components/Events/Controls/Communications/communication.constants';
import { CommunicationType } from 'components/Events/Controls/Communications/communication.types';
import CommunicationManager from 'components/Events/Controls/Communications/CommunicationManager';
import CommunicationsTable from 'components/Events/Controls/Communications/CommunicationsTable';
import CommunicationTypeCard from 'components/Events/Controls/Communications/CommunicationTypeCard';
import EventTimeChangedCheckCommsAlert from 'components/Events/Controls/Shared/EventTimeChangedCheckCommsAlert';

const Communications = () => (
  <Box>
    <EventTimeChangedCheckCommsAlert hasReviewButton={false} />
    <Stack direction='column' gap={3}>
      <Stack alignItems='stretch' direction='row' gap={3}>
        {communicationTypes
          ?.filter((t) => t.id !== 'survey')
          ?.map((ct: CommunicationType) => (
            <CommunicationTypeCard key={ct?.id} type={ct} />
          ))}
      </Stack>
      <Typography mt={2} variant='h3'>
        Communications
      </Typography>
      <CommunicationsTable />
      <CommunicationManager />
    </Stack>
  </Box>
);

export default Communications;
