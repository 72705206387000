import React, { useMemo } from 'react';
import { createCloudinaryLegacyURL } from '@cloudinary/url-gen';
import { Avatar as MuiAvatar, useTheme } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { merge } from 'lodash';
import PropTypes from 'prop-types';

import { reactQueryAxios } from 'utils/axios';

/**
 * Renders <Avatar /> component
 * @param {object} props
 * @param {string} props.employeeId employee Id
 * @param {Number} props.width width of generated image
 * @param {Number} props.height height of generated image
 * @param {string} props.className css class list to apply instead of default (optional)
 * @param {string} props.rootClassName css class list to apply instead of "avatar" default (optional)
 * @param {HTMLElement | HTMLElement[]} props.children elements to display if publicId is null
 */
const Avatar = ({
  children,
  className,
  employeeId,
  height = 24,
  width = 24,
  ...props
}) => {
  // const [migrateUrl, setMigrateUrl] = useState(null);
  const themePalette = useTheme().palette;

  const getAvatar = async (employeeId) => {
    if (employeeId) {
      return reactQueryAxios({
        method: 'get',
        url: `/api/employees/${employeeId}/avatar`,
      });
    } else {
      return null;
    }
  };

  const { data } = useQuery({
    gcTime: Infinity,
    queryFn: () => getAvatar(employeeId, reactQueryAxios),
    queryKey: ['avatar', employeeId],
    staleTime: 86400000, // 24 hours
  });

  const migrateUrl = useMemo(
    () =>
      data?.avatar?.key !== null
        ? createCloudinaryLegacyURL(data?.avatar?.key, {
            aspect_ratio: '1.0',
            cloud_name: 'five-to-nine',
            crop: 'fit',
            height: height * 3,
            public_id: data?.avatar?.key,
            secure: process.env.NODE_ENV !== 'development',
          })
        : '',
    [data?.avatar?.key, height]
  );

  const fontSize = useMemo(() => 0.5 * height, [height]);

  const avatarProps = merge(
    {
      src: migrateUrl,
      sx: {
        bgcolor: themePalette.secondary.main,
        fontSize,
        height,
        width,
      },
    },
    props
  );

  return <MuiAvatar {...avatarProps}>{children}</MuiAvatar>;
};

Avatar.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  employeeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.number,
  rootClassName: PropTypes.string,
  width: PropTypes.number,
};

export default Avatar;
